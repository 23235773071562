<template>
  <div class="form-group row mb-5">
    <label v-if="showLabel" :for="id" class="col-sm-2 col-form-label text-right">{{ name }}</label>
    <div class="mb-5" :class="{'col-sm-12': !showLabel, 'col-sm-10': showLabel}" :tabindex="tabIndex">
      <editor
          :id="id"
          style="height: 400px"
          v-model="validate[form][id].$model"
          :options="options"
          :disabled="isLoading"
      />
    </div>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

export default {
  name: "RichTextInput",
  props: {
    form: {
      type: String,
      default: 'form'
    },
    id: {
      type: String
    },
    name: {},
    showLabel: {
      type: Boolean,
      default: true
    },
    validate: {
      type: Object
    },
    isLoading: {
      type: Boolean
    },
    tabIndex: {
      default: null
    }
  },
  data() {
    return {
      options: {
        placeholder: this.$t(this.name),
        readOnly: false,
        theme: 'snow'
      }
    }
  },
  components: {'editor': quillEditor}
}
</script>
