<template>
  <div>
    <v-content>
      <b-button
          variant="outline-primary"
          class="float-right"
          @click.prevent="$router.push({ name: 'products.color.create', params: {id: $route.params.id} })"
      >
        <i class="fas fa-plus-circle"></i> {{ $t('create') }}
      </b-button>
      <table class="table table-striped">
        <thead>
        <tr>
          <th scope="col">{{ $t('sl') }}</th>
          <th scope="col">{{ $t('name') }}</th>
          <th scope="col">{{ $t('hexCode') }}</th>
          <th scope="col">{{ $t('extraPrice') }}</th>
          <th scope="col">{{ $t('controls') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(color, index) in data" :key="color.id">
          <td>{{ ++index }}</td>
          <td>{{ color.name }}</td>
          <td>{{ color.hexCode }}</td>
          <td>{{ color.extraPrice }}</td>
          <td>
            <b-button variant="danger" @click="deleteColor(color.id)"><i class="fas fa-trash"></i></b-button>

            <b-button v-b-modal="'add-color-attachment-modal' + color.id" class="ml-2 no-print" variant="info"><i class="fas fa-plus"></i></b-button>
            <b-modal :id="'add-color-attachment-modal' + color.id" :title="$t('addAttachments')" size="xl">
              <div class="d-block text-center">
                <file-input
                    id="attachment"
                    :required="true"
                    :name="$t('attachment')"
                    @fileAdded="fileAdded"
                />
              </div>
              <template #modal-footer="{ cancel }">
                <b-button variant="secondary" @click="cancel()">{{ $t('close') }}</b-button>
                <b-button variant="primary" @click="addAttachmentsToProductColor(color.id)">{{ $t('create') }}</b-button>
              </template>
            </b-modal>

            <b-button @click="showAttachmentsToProductColor(color.id)" class="ml-2 no-print text-white" variant="warning"><i class="fas fa-info-square"></i></b-button>
            <b-modal :id="'show-color-attachment-modal' + color.id" :title="$t('showAttachments')" size="xl">
              <div class="d-block text-center">
                <div v-for="(productColorAttachment, index) in productColorAttachments" :key="index">
                  <div class="preview m-2">
                    <span class="close" @click="deleteProductColorAttachment(productColorAttachment.id, color.id)"><i class="fas fa-times"></i></span>
                    <img :src="getImageUrl(productColorAttachment.attachment)" :alt="productColorAttachment.name" width="100%" height="100%" style="object-fit: contain"/>
                  </div>
                </div>
              </div>
              <template #modal-footer="{ cancel }">
                <b-button variant="secondary" @click="cancel()">{{ $t('close') }}</b-button>
              </template>
            </b-modal>
          </td>
        </tr>
        </tbody>
      </table>
    </v-content>
    <is-loading v-if="isLoading"/>
  </div>
</template>

<script>
import Content from "@/components/Content";
import isLoading from "@/components/IsLoading";
import {actions, swalMixin} from "@/plugins/constants";
import FileInput from "@/components/Inputs/FileInput";

export default {
  name: "ColorToProductList",
  components: {FileInput, 'v-content': Content, isLoading},
  data() {
    return {
      isLoading: false,
      data: [],
      colorAttachment: {},
      productColorAttachments: [],
    };
  },
  props: {
    colorsToProduct: {
      type: Array
    }
  },
  watch: {
    colorsToProduct: function (value) {
      this.data = value
    }
  },
  methods: {
    async deleteColor(colorId) {
      if (await actions('colorsToProduct/DELETE', colorId, 'delete')) {
        swalMixin('success', this.$t('deletedSuccessfully'))
        this.$emit('loadAll')
      }
    },
    available(colorId) {
      this.isLoading = true;
      this.$store.dispatch('colorsToProduct/DELETE', colorId).then(() => {
        swalMixin('success', this.$t('updatedSuccessfully'))
        this.isLoading = false;
        this.$emit('loadAll');
      }).catch(() => this.isLoading = false);
    },
    fileAdded(base64File) {
      this.colorAttachment.attachment = base64File;
      this.colorAttachment.shareable  = true;
    },
    addAttachmentsToProductColor(colorId) {
      this.isLoading = true;
      this.colorAttachment.targetId = colorId;
      this.$store.dispatch('colorsToProduct/ADD_ATTACHMENT', this.colorAttachment).then(() => {
        swalMixin('success', this.$t('addedSuccessfully'))
        this.isLoading = false;
        this.$emit('loadAll');
        this.$bvModal.hide('add-color-attachment-modal' + colorId)
      }).catch(() => this.isLoading = false);
    },
    async loadProductColorAttachment(colorId) {
      await this.$store.dispatch('colorsToProduct/GET_ALL_ATTACHMENT', colorId).then((response) => {
        this.productColorAttachments = response.data;
      }).catch(() => this.isLoading = false);
    },
    async showAttachmentsToProductColor(colorId) {
      this.$bvModal.show('show-color-attachment-modal' + colorId);
      await this.loadProductColorAttachment(colorId)
    },
    async deleteProductColorAttachment(colorAttachmentId, colorId) {
      if (await actions('colorsToProduct/DELETE_ATTACHMENT', colorAttachmentId, 'delete')) {
        swalMixin('success', this.$t('deletedSuccessfully'))
        await this.loadProductColorAttachment(colorId);
      }
    }
  },
}
</script>

<style scoped>
.preview {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview img {
  max-width: 100%;
  max-height: 500px;
}

.preview span {
  position: absolute;
  top: 0;
  right: 4px;
  z-index: 100;
  color: red;
  cursor: pointer;
}
</style>