import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import mixin from "@/mixins";
import GroupTypes from "@/enums/GroupTypes";
import ProductAttributes from "@/enums/ProductAttributes";

const mostSales = {
    namespaced: true,
    state: {
        mostSales: {},
        createdMostSale: {},
        getOneMostSale: {},
        updatedMostSale: {},
    },
    mutations: {
        SET_MOST_SALES (state, value) { state.mostSales = value },
        SET_CREATED_MOST_SALE (state, value) {state.createdMostSale = value},
        SET_GET_ONE_MOST_SALE (state, value) {state.getOneMostSale = value},
        SET_UPDATED_MOST_SALE (state, value) {state.updatedMostSale = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/Main` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}` +
                        `&objectName=${ProductAttributes.Group}&groupType=${GroupTypes.MostSale}`)
                    .then((response) => {
                        commit('SET_MOST_SALES', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_MOST_SALES', {})
                        reject(error)
                    });
            })
        },
        GET_ONE({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/Main/${id}?objectName=${ProductAttributes.Group}`)
                    .then((response) => {
                        commit('SET_GET_ONE_MOST_SALE', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GET_ONE_MOST_SALE', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.post(`/Main?objectName=${ProductAttributes.Group}`, form)
                    .then((response) => {
                        commit('SET_CREATED_MOST_SALE', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_MOST_SALE', {})
                        reject(error)
                    });
            })
        },
        UPDATE({commit}, {id, form}) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.put(`/Main/${id}?objectName=${ProductAttributes.Group}`, form)
                    .then((response) => {
                        commit('SET_UPDATED_MOST_SALE', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_UPDATED_MOST_SALE', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/Main/${ids}?objectName=${ProductAttributes.Group}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        mostSales: state => state.mostSales,
        createdMostSale: state => state.createdMostSale,
        getOneMostSale: state => state.getOneMostSale,
        updatedMostSale: state => state.updatedMostSale,
    }
}

export default mostSales;
