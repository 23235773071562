import {i18n} from "@/plugins/i18n";

export class Contract {
    columns = [];
    id      = 'id';

    fillAbles = [
        [
            'contractNumber',
            i18n.t('contractNumber'),
            true
        ],
        [
            'price',
            i18n.t('price'),
            true
        ],
        [
            'discount',
            i18n.t('discount'),
            true
        ],
        [
            'extraPrice',
            i18n.t('extraPrice'),
            true
        ],
        [
            'isAllPayed',
            i18n.t('isAllPayed'),
            true
        ],
        [
            'customerName',
            i18n.t('customerName'),
            true
        ],
        [
            'productName',
            i18n.t('productName'),
            true
        ],
        [
            'colorHex',
            i18n.t('colorHex'),
            true
        ],
        [
            'diskSizeName',
            i18n.t('diskSizeName'),
            true
        ],
        [
            'ramSizeName',
            i18n.t('ramSizeName'),
            true
        ],
        [
            'controls',
            i18n.t('controls'),
            false
        ]
    ];

    getColumns() {
        this.columns = [
            {
                label: 'Id',
                name: 'id',
                sort: false,
                visibility: false,
                uniqueId: true
            },
        ];
        this.fillAbles.map(e => {
            this.columns.push({
                name: e[0],
                label: e[1],
                sort: e[2],
                visibility: e[3] ?? true
            })
        })
        return this.columns;
    }
}
