<template>
  <div>
    <div class="row">
      <div class="col-4 text-center">
        <h2>کۆمپانیای تێک ئای</h2>
      </div>
      <div class="col-4 text-center">
        <b-img :src="systemImage" fluid alt="Responsive image" style="width: 50%"></b-img>
      </div>
      <div class="col-4 text-center">
        <h3>رقم السجل</h3>
        <h3 style="padding: 5px; border: 1px solid #000000">{{ buyContract.id }}</h3>
      </div>
    </div>
    <b-row class="m-2">
      <b-col cols="4">
        <b-input-group :prepend="$t('contractNumber')">
          <b-form-input :value="buyContract.contractNumber" disabled></b-form-input>
        </b-input-group>
      </b-col>
      <b-col cols="4">
        <b-input-group :prepend="$t('customerName')">
          <b-form-input :value="`${customer.firstName} ${customer.middleName} ${customer.lastName}`" disabled></b-form-input>
        </b-input-group>
      </b-col>
      <b-col cols="4">
        <b-input-group :prepend="$t('customerPhone')">
          <b-form-input :value="customer.phone" disabled></b-form-input>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row class="m-2">
      <b-col cols="4">
        <b-input-group :prepend="$t('productName')">
          <b-form-input :value="product.name" disabled></b-form-input>
        </b-input-group>
      </b-col>
      <b-col cols="4">
        <b-input-group :prepend="$t('productColor')">
          <b-form-input :value="color.name" disabled></b-form-input>
        </b-input-group>
      </b-col>
      <b-col cols="4">
        <b-input-group :prepend="$t('productDiskSize')">
          <b-form-input :value="`${diskSize.name} (${diskSize.sizeInGB})`" disabled></b-form-input>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row class="m-2">
      <b-col cols="4">
        <b-input-group :prepend="$t('productRamSize')">
          <b-form-input :value="`${ramSize.name} (${ramSize.sizeInGB})`" disabled></b-form-input>
        </b-input-group>
      </b-col>
      <b-col cols="4">
        <b-input-group :prepend="$t('screenSize')">
          <b-form-input :value="`${product.screenSize ? product.screenSize.name : '-'}`" disabled></b-form-input>
        </b-input-group>
      </b-col>
      <b-col cols="4">
        <b-input-group :prepend="$t('price')">
          <b-form-input :value="product.price.toLocaleString()" disabled></b-form-input>
        </b-input-group>
      </b-col>
    </b-row>
    <br />
    <table class="table table-bordered">
      <caption class="caption-top">التاریخ</caption>
      <thead>
      <tr>
        <th>#</th>
        <th>المبلغ</th>
        <th>تاريخ الدفع</th>
        <th>الموعد الأخير</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(prePayment, index) in contractPrePayments" :key="index">
        <td>{{ $t('prePayment') }}</td>
        <td>${{ parseFloat(prePayment.mcPrice).toLocaleString() }} ({{ number2Text(parseFloat(prePayment.mcPrice), getLanguage()) }})</td>
        <td>{{ moment(prePayment.date).format('YYYY-MM-DD') }}</td>
        <td>{{ moment(prePayment.deadline).format('YYYY-MM-DD') }}</td>
      </tr>
      <tr v-for="(payment, index) in contractPayments" :key="index">
        <td>{{ ++index }}</td>
        <td>${{ parseFloat(payment.mcPrice).toLocaleString() }} ({{ number2Text(parseFloat(payment.mcPrice), getLanguage()) }})</td>
        <td>{{ moment(payment.date).format('YYYY-MM-DD') }}</td>
        <td>{{ moment(payment.deadline).format('YYYY-MM-DD') }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "ContractDesign",
  props: {
    customer: {},
    buyContract: {},
    product: {},
    diskSize: {},
    ramSize: {},
    color: {},
    contractPayments: {},
    contractPrePayments: {}
  },
  data() {
    return {
      systemImage: this.$helpers.projectTenantLogo(),
    };
  },
}
</script>

<style scoped>

</style>