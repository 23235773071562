<template>
  <div>
    <content-header>{{ $t("createProductType") }}</content-header>
    <v-content>
      <b-card>
        <b-card-body>
          <form class="forms-sample" @submit.prevent="onSubmit">
            <text-input
              id="name"
              :name="$t('name')"
              :validate="$v"
              :required="true"
              :max-length="true"
              :is-loading="isLoading"
            />
            <text-input
              id="kuName"
              :name="$t('kuName')"
              :validate="$v"
              :required="true"
              :max-length="true"
              :is-loading="isLoading"
            />
            <text-input
              id="arName"
              :name="$t('arName')"
              :validate="$v"
              :required="true"
              :max-length="true"
              :is-loading="isLoading"
            />
            <select-input
              id="productType"
              :name="$t('departments')"
              :placeholder="$t('departments')"
              :validate="$v"
              :required="true"
              api-link="productTypes/GET_ALL"
              label="name"
              @itemSelected="parentSelected"
            />
            <select-input
              id="productType"
              :name="$t('sections')"
              :placeholder="$t('sections')"
              :validate="$v"
              :required="true"
              api-link="productTypes/GET_ALL"
              :api-params="{ id: form.parentId }"
              label="name"
              :disabled="subParentDisable"
              @itemSelected="childSelected"
            />
            <select-input
              id="productType"
              :name="$t('categories')"
              :placeholder="$t('categories')"
              :validate="$v"
              :required="true"
              api-link="productTypes/GET_ALL"
              :api-params="{ id: form.subParentId }"
              label="name"
              :disabled="childDisable"
              @itemSelected="(e) => (form.childId = e)"
            />
            <file-input
              id="attachment"
              :name="$t('attachment')"
              @fileAdded="(base64File) => (form.attachment = base64File)"
            />
            <submit-input :name="$t('create')" :disabled="isLoading" />
            <is-loading v-if="isLoading" />
          </form>
        </b-card-body>
      </b-card>
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import { required, maxLength } from "vuelidate/lib/validators";
import IsLoading from "@/components/IsLoading";
import SubmitInput from "@/components/Inputs/SubmitInput";
import TextInput from "@/components/Inputs/TextInput";
import { ProductType } from "@/models/ProductType";
import FileInput from "@/components/Inputs/FileInput";
import SelectInput from "@/components/Inputs/SelectInput";

export default {
  name: "ProductTypesCreate",
  components: {
    SelectInput,
    FileInput,
    TextInput,
    SubmitInput,
    ContentHeader,
    "v-content": Content,
    IsLoading,
  },
  data() {
    return {
      form: this.$helpers.convertArrayToObject(new ProductType().fillAbles),
      isLoading: false,
      selectedItem: {},
      subParentDisable: true,
      subParentId: 0,
      childDisable: true,
      childId: 0,
    };
  },
  validations: {
    form: {
      name: { required, maxLength: maxLength(255) },
      kuName: { required, maxLength: maxLength(255) },
      arName: { required, maxLength: maxLength(255) },
      attachment: {},
    },
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return false;
      this.isLoading = true;
      this.form.parentId =
        this.form.childId !== 0
          ? this.form.childId
          : this.form.subParentId !== 0
          ? this.form.subParentId
          : this.form.parentId;

      delete this.form.subParentId;
      this.$store
        .dispatch("productTypes/CREATE", this.form)
        .then(() => {
          this.$v.$reset();
          this.$router.push({ name: "productTypes" });
        })
        .catch(() => (this.isLoading = false));
    },
    async parentSelected(e) {
      this.subParentDisable = true;
      this.form.subParentId = 0;
      this.form.parentId = await e;
      this.subParentDisable = false;
    },
    async childSelected(e) {
      this.childDisable = true;
      this.form.childId = 0;
      this.form.subParentId = await e;
      this.childDisable = false;
    },
  },
};
</script>
