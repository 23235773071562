import {i18n} from "@/plugins/i18n";

export class PriceToProduct {
    columns = [];
    id      = 'id';

    fillAbles = [
        [
            'productId',
            i18n.t('productId'),
            true
        ],
        [
            'branchId',
            i18n.t('branchId'),
            true
        ],
        [
            'branchName',
            i18n.t('branchName'),
            true
        ],
        [
            'price',
            i18n.t('price'),
            true
        ],
        [
            'discount',
            i18n.t('discount'),
            true
        ],
        [
            'controls',
            i18n.t('controls'),
            false
        ]
    ];

    getColumns() {
        this.columns = [
            {
                label: 'Id',
                name: 'id',
                sort: false,
                visibility: false,
                uniqueId: true
            },
        ];
        this.fillAbles.map(e => {
            this.columns.push({
                name: e[0],
                label: e[1],
                sort: e[2],
                visibility: e[3] ?? true
            })
        })
        return this.columns;
    }
}
