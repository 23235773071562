<template>
  <div>
    <content-header>{{ $t('createCustomer') }}</content-header>
    <v-content>
      <b-card>
        <b-card-body>
          <form class="forms-sample" @submit.prevent="onSubmit">
            <text-input id="firstName" :name="$t('firstName')" :validate="$v" :required="true" :max-length="true" :is-loading="isLoading" />
            <text-input id="middleName" :name="$t('middleName')" :validate="$v" :required="true" :max-length="true" :is-loading="isLoading" />
            <text-input id="lastName" :name="$t('lastName')" :validate="$v" :required="true" :max-length="true" :is-loading="isLoading" />
            <date-time-input id="dob" :name="$t('dob')" :validate="$v" :is-loading="isLoading" />
            <text-input id="phone" :name="$t('phone')" :validate="$v" :required="true" :is-loading="isLoading" />
            <text-input id="phone2" :name="$t('phone2')" :validate="$v" :is-loading="isLoading" />
            <text-input id="address" :name="$t('address')" :validate="$v" :is-loading="isLoading" />
            <text-input id="email" :name="$t('email')" :validate="$v" :is-loading="isLoading" />
            <text-area-input id="note" :name="$t('note')" :validate="$v" :is-loading="isLoading" />
            <file-input
                id="attachment"
                :required="true"
                :name="$t('attachment')"
                @fileAdded="base64File => form.attachment = base64File"
            />
            <submit-input :name="$t('create')" :disabled="isLoading"/>
            <is-loading v-if="isLoading" />
          </form>
        </b-card-body>
      </b-card>
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import { required, maxLength } from 'vuelidate/lib/validators'
import IsLoading from "@/components/IsLoading";
import SubmitInput from "@/components/Inputs/SubmitInput";
import TextInput from "@/components/Inputs/TextInput";
import {Customer} from "@/models/Customer";
import DateTimeInput from "@/components/Inputs/DateTimeInput";
import FileInput from "@/components/Inputs/FileInput";
import TextAreaInput from "@/components/Inputs/TextAreaInput";

export default {
  name: "CustomersCreate",
  components: {TextAreaInput, FileInput, DateTimeInput, TextInput, SubmitInput, ContentHeader, 'v-content': Content, IsLoading},
  data() {
    return {
      form: this.$helpers.convertArrayToObject(new Customer().fillAbles),
      isLoading: false,
      selectedItem: {},
    }
  },
  validations: {
    form: {
      firstName: {required, maxLength: maxLength(255)},
      middleName: {required, maxLength: maxLength(255)},
      lastName: {required, maxLength: maxLength(255)},
      dob: {},
      phone: {required},
      phone2: {},
      address: {},
      attachment: {},
      email: {},
      note: {},
    }
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return false;
      this.isLoading = true;
      this.$store.dispatch('customers/CREATE', this.form).then(() => {
        this.$v.$reset();
        this.$router.push({name: "customers"})
      }).catch(() => this.isLoading = false);
    },
  }
}
</script>
