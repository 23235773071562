import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";

const branches = {
    namespaced: true,
    state: {
        branches: {},
    },
    mutations: {
        SET_BRANCHES (state, value) { state.branches = value },
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/Branches` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}`)
                    .then((response) => {
                        commit('SET_BRANCHES', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_BRANCHES', {})
                        reject(error)
                    });
            })
        },
    },
    getters : {
        branches: state => state.branches,
    }
}

export default branches;
