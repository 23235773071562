<template>
  <div>
    <v-content>
      <b-button
        variant="outline-primary"
        class="float-right"
        @click.prevent="
          $router.push({
            name: 'products.installment.methods.create',
            params: { id: $route.params.id },
          })
        "
      >
        <i class="fas fa-plus-circle"></i> {{ $t("create") }}
      </b-button>
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">{{ $t("sl") }}</th>
            <th scope="col">{{ $t("name") }}</th>
            <th scope="col">{{ $t("prePaymentRate") }}</th>
            <th scope="col">{{ $t("paymentCount") }}</th>
            <th scope="col">{{ $t("paymentPeriodDays") }}</th>
            <th scope="col">{{ $t("delete") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(installmentMethod, index) in data" :key="index">
            <td>{{ ++index }}</td>
            <td>{{ installmentMethod.name }}</td>
            <td>{{ installmentMethod.prePaymentRate }}</td>
            <td>{{ installmentMethod.paymentCount }}</td>
            <td>{{ installmentMethod.paymentPeriodDays }}</td>
            <td>
              <b-button
                variant="danger"
                @click="deleteInstallmentMethod(installmentMethod.id)"
              >
                <i class="fas fa-trash"></i>
              </b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </v-content>
    <is-loading v-if="isLoading" />
  </div>
</template>

<script>
import Content from "@/components/Content";
import isLoading from "@/components/IsLoading";
import { actions, swalMixin } from "@/plugins/constants";

export default {
  name: "InstallmentMethodsToProductList",
  components: { "v-content": Content, isLoading },
  data() {
    return {
      isLoading: false,
      data: [],
    };
  },
  props: {
    installmentsToProduct: {
      type: Array,
    },
  },
  watch: {
    installmentsToProduct: function(value) {
      this.data = value;
    },
  },
  methods: {
    async deleteInstallmentMethod(installmentMethodId) {
      if (
        await actions(
          "installmentMethodsToProduct/DELETE",
          installmentMethodId,
          "delete"
        )
      ) {
        swalMixin("success", this.$t("deletedSuccessfully"));
        this.$emit("loadAll");
      }
    },
  },
};
</script>
