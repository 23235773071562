<template>
  <div class="row">
    <div class="col-2" v-for="family in families" :key="family.id">
      <b-form-checkbox :id="'family_' + family.id" name="familyCheckbox" :data-family-id="family.id">
        {{ family.name }}
      </b-form-checkbox>
    </div>
  </div>
</template>

<script>
export default {
  props: ['category'],
  name: "FamilyPart",
  mounted() {
    this.getFamilies();
  },
  data() {
    return {
      families: []
    }
  },
  methods: {
    getFamilies() {
      let serverParameters = {
        _start: 0,
        _end: 50000,
        _sort: 'id',
        _order: 'ASC',
        _search: '',
        custom_params: { id: this.category }
      }
      this.$store
          .dispatch("productTypes/GET_ALL", serverParameters)
          .then((response) => {
            this.families = response.data;
          })
          .catch(() => (this.isLoading = false));
    },
  }
}
</script>