import { i18n } from "@/plugins/i18n";

export class ProductStock {
  columns = [];
  id = "id";

  fillAbles = [
    ["productName", i18n.t("productName"), true],
    ["colorName", i18n.t("colorName"), true],
    ["diskSizeName", i18n.t("diskSizeName"), true],
    ["ramSizeName", i18n.t("ramSizeName"), true],
    ["screenSizeName", i18n.t("screenSizeName"), true],
    ["qty", i18n.t("qty"), true],
    ["price", i18n.t("price"), true],
  ];

  getColumns() {
    this.columns = [
      {
        label: "Id",
        name: "id",
        sort: false,
        visibility: false,
        uniqueId: true,
      },
    ];
    this.fillAbles.map((e) => {
      this.columns.push({
        name: e[0],
        label: e[1],
        sort: e[2],
        visibility: e[3] ?? true,
      });
    });
    return this.columns;
  }
}
