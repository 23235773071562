import { HTTP } from "@/plugins/axios";
import { resolveServerParams } from "@/plugins/constants";
import ProductAttributes from "@/enums/ProductAttributes";
import mixin from "@/mixins";

const productTypes = {
  namespaced: true,
  state: {
    productTypes: {},
    createdProductType: {},
    getOneProductType: {},
    updatedProductType: {},
  },
  mutations: {
    SET_PRODUCT_TYPES(state, value) {
      state.productTypes = value;
    },
    SET_CREATED_PRODUCT_TYPE(state, value) {
      state.createdProductType = value;
    },
    SET_GET_ONE_PRODUCT_TYPE(state, value) {
      state.getOneProductType = value;
    },
    SET_UPDATED_PRODUCT_TYPE(state, value) {
      state.updatedProductType = value;
    },
  },
  actions: {
    GET_ALL({ commit }, serverParams) {
      return new Promise((resolve, reject) => {
        serverParams = resolveServerParams(serverParams);
        HTTP.get(
          `/Main` +
            `${serverParams._start}` +
            `${serverParams._end}` +
            `${serverParams._sort ? serverParams._sort : '&sort=sort'}` +
            `${serverParams._order ? serverParams._order : '&order=asc'}` +
            `${serverParams._search}` +
            `${serverParams.custom_params}` +
            `&objectName=` +
            ProductAttributes.ProductType
        )
          .then((response) => {
            commit("SET_PRODUCT_TYPES", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_PRODUCT_TYPES", {});
            reject(error);
          });
      });
    },
    GET_ONE({ commit }, id) {
      return new Promise((resolve, reject) => {
        HTTP.get(`/Main/${id}?objectName=` + ProductAttributes.ProductType)
          .then((response) => {
            commit("SET_GET_ONE_PRODUCT_TYPE", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_GET_ONE_PRODUCT_TYPE", {});
            reject(error);
          });
      });
    },
    CREATE({ commit }, form) {
      mixin.removeEmptyAndNull(form);
      return new Promise((resolve, reject) => {
        HTTP.post(`/Main?objectName=` + ProductAttributes.ProductType, form)
          .then((response) => {
            commit("SET_CREATED_PRODUCT_TYPE", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_CREATED_PRODUCT_TYPE", {});
            reject(error);
          });
      });
    },
    CREATE_BATCH(commit, form) {
      return new Promise((resolve, reject) => {
        HTTP.post(`/Utilities/BatchPostProductType`, form)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
      });
    },
    UPDATE({ commit }, { id, form }) {
      mixin.removeEmptyAndNull(form);
      return new Promise((resolve, reject) => {
        HTTP.put(
          `/Main/${id}?objectName=` + ProductAttributes.ProductType,
          form
        )
          .then((response) => {
            commit("SET_UPDATED_PRODUCT_TYPE", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_UPDATED_PRODUCT_TYPE", {});
            reject(error);
          });
      });
    },
    DELETE(commit, ids) {
      return new Promise((resolve, reject) => {
        HTTP.delete(`/Main/${ids}?objectName=` + ProductAttributes.ProductType)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    UPDATE_IMAGE(commit, form) {
      return new Promise((resolve, reject) => {
        HTTP.put(
          `/Main/${form.id}?OnlyAttachment=true&objectName=` +
            ProductAttributes.ProductType,
          form
        )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    RE_SORT(commit, { id, sort }) {
      return new Promise((resolve, reject) => {
        HTTP.post(`/Utilities/ReSortProductTypes?id=${id}&sort=${sort}`)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
      });
    },
    RE_ARRANGE(commit, { id }) {
      return new Promise((resolve, reject) => {
        let params = '';
        if (id) {
          params = `?level=${id}`;
        }
        HTTP.post(`/Utilities/ReArrangeAllProductTypes${params}`)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
      });
    },
  },
  getters: {
    productTypes: (state) => state.productTypes,
    createdProductType: (state) => state.createdProductType,
    getOneProductType: (state) => state.getOneProductType,
    updatedProductType: (state) => state.updatedProductType,
  },
};

export default productTypes;
