<template>
  <div>
    <content-header>{{ $t('tags') }}</content-header>
    <v-content>
      <vue-bootstrap4-table
          :rows="rows"
          :columns="columns"
          :config="config"
          :total-rows="totalRecords"
          :actions="actions"
          @on-change-query="onChangeQuery"
          @on-select-row="(rowSelect) => this.selectedRows = rowSelect.selected_items"
          @on-all-select-rows="(rowSelect) => this.selectedRows = rowSelect.selected_items"
          @on-unselect-row="(rowSelect) => this.selectedRows = rowSelect.selected_items"
          @on-all-unselect-rows="(rowSelect) => this.selectedRows = rowSelect.selected_items"
          @on-create="$router.push({name: 'tags.create'})"
          @on-multiple-delete="onMultipleDelete"
      >
        <template slot="empty-results">{{ $t('noResultsFound') }}</template>
        <template slot="pagination-info" slot-scope="props">
          <template v-if="props.currentPageRowsLength !== 0">{{totalRecords}} {{ $t('totalRecords') }}</template>
          <template v-else>{{ $t('noResultsFound') }}</template>
        </template>
        <template slot="attachment" slot-scope="props">
          <show-attachment :attachment="props.row.attachment" :title="props.row.name" :width="150" :height="150" />
        </template>
        <template slot="controls" slot-scope="props">
          <control-buttons
              :props="props"
              edit-link="tags.edit"
              delete-link="tags/DELETE"
              v-on:successAction="loadData"
          />
        </template>
      </vue-bootstrap4-table>
      <is-loading v-if="isLoading" />
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import {Tag} from "@/models/Tag";
import IsLoading from "@/components/IsLoading";
import ControlButtons from "@/components/ControlButtons";
import {actions, serverParams, swalMixin, tableActions, tableConfigure} from "@/plugins/constants";
import VueBootstrap4Table from 'vue-bootstrap4-table'
import ShowAttachment from "@/components/ShowAttachment";

export default {
  name: "TagList",
  components: {ShowAttachment, ControlButtons, IsLoading, 'v-content': Content, ContentHeader, VueBootstrap4Table},
  data() {
    return {
      isLoading: false,
      model: new Tag(),
      columns: [],
      rows: [],
      actions: tableActions(true),
      totalRecords: 0,
      config: tableConfigure(this.$t('tagList')),
      serverParams: serverParams,
      selectedRows: []
    };
  },
  beforeMount() {
    this.columns  = this.model.getColumns();
  },
  methods: {
    loadData() {
      this.isLoading = true;
      this.$store.dispatch('tags/GET_ALL', this.serverParams)
          .then((response) => {
            this.rows         = response.data
            this.totalRecords = parseInt(response.headers['x-total-count'])
            this.isLoading    = false
          })
          .catch(() => this.isLoading = false);
    },
    onChangeQuery(queryParams) {
      serverParams._start   = (queryParams.page - 1) * queryParams.per_page
      serverParams._end     = queryParams.page * queryParams.per_page
      serverParams._sort    = queryParams.sort[0] ? queryParams.sort[0].name : ''
      serverParams._order   = queryParams.sort[0] ? queryParams.sort[0].order : ''
      serverParams._search  = queryParams.global_search ?? ''
      this.loadData()
    },
    async onMultipleDelete() {
      if (this.selectedRows.length === 0) {
        swalMixin('error', 'noRows')
        return false;
      }
      if (await actions('tags/DELETE', this.selectedRows, 'delete')) {
        this.loadData();
      }
    },
  }
}
</script>
