<template>
  <div>
    <content-header>{{ $t('editProductColor') }}</content-header>
    <v-content>
      <b-card>
        <b-card-body>
          <form class="forms-sample" @submit.prevent="onSubmit">
            <text-input id="name" :name="$t('name')" :validate="$v" :required="true" :max-length="true" :is-loading="isLoading" />
            <color-input id="hexCode" :name="$t('hexCode')" :validate="$v" :required="true" :is-loading="isLoading" />
            <submit-input icon="fa-save" :name="$t('save')" :disabled="isLoading"/>
            <is-loading v-if="isLoading" />
          </form>
        </b-card-body>
      </b-card>
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import {required, maxLength} from 'vuelidate/lib/validators'
import IsLoading from "@/components/IsLoading";
import {ProductColor} from "@/models/ProductColor";
import SubmitInput from "@/components/Inputs/SubmitInput";
import TextInput from "@/components/Inputs/TextInput";
import ColorInput from "@/components/Inputs/ColorInput";

export default {
  name: "ProductColorEdit",
  components: {ColorInput, SubmitInput, ContentHeader, 'v-content': Content, IsLoading, TextInput},
  data() {
    return {
      form: this.$helpers.convertArrayToObject(new ProductColor().fillAbles),
      isLoading: false,
      selectedItem: {},
    };
  },
  mounted() {
    this.isLoading = true;
    this.$store.dispatch('productColors/GET_ONE', this.$route.params.id).then((response) => {
      this.form       = response.data;
      this.isLoading  = false;
    }).catch(() => this.isLoading = false)
  },
  validations: {
    form: {
      name: {required, maxLength: maxLength(255)},
      hexCode: {required}
    }
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return false;
      this.isLoading = true;
      this.$store.dispatch('productColors/UPDATE', {id: this.$route.params.id, form: this.form}).then(() => {
        this.$v.$reset();
        this.$router.push({name: "productColors"})
      }).catch(() => this.isLoading = false);
    }
  }
}
</script>
