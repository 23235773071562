<template>
  <div>
    <content-header>{{ $t("createProduct") }}</content-header>
    <v-content>
      <b-card>
        <b-card-body>
          <form class="forms-sample" @submit.prevent="onSubmit">
            <text-input
              id="code"
              :name="$t('code')"
              :validate="$v"
              :required="true"
              :is-loading="isLoading"
            />
            <text-input
              id="barcode"
              :name="$t('barcode')"
              :validate="$v"
              :required="true"
              :is-loading="isLoading"
            />
            <text-input
              id="name"
              :name="$t('name')"
              :validate="$v"
              :required="true"
              :max-length="true"
              :is-loading="isLoading"
            />
            <text-input
              id="kuName"
              :name="$t('kuName')"
              :validate="$v"
              :required="true"
              :max-length="true"
              :is-loading="isLoading"
            />
            <text-input
              id="arName"
              :name="$t('arName')"
              :validate="$v"
              :required="true"
              :max-length="true"
              :is-loading="isLoading"
            />
            <rich-text-input
              id="description"
              :name="$t('description')"
              :validate="$v"
              :is-loading="isLoading"
            />
            <rich-text-input
              id="kuDescription"
              :name="$t('kuDescription')"
              :validate="$v"
              :is-loading="isLoading"
            />
            <rich-text-input
              id="arDescription"
              :name="$t('arDescription')"
              :validate="$v"
              :is-loading="isLoading"
            />
            <number-input
              id="cost"
              :name="$t('cost')"
              :validate="$v"
              :required="true"
              :is-loading="isLoading"
            />
            <number-input
              id="price"
              :name="$t('price')"
              :validate="$v"
              :required="true"
              :is-loading="isLoading"
            />
            <number-input
              id="discount"
              :name="$t('discountPer')"
              :validate="$v"
              :required="true"
              :is-loading="isLoading"
            />
            <select-input
              :is-loaded="isLoading"
              id="brandName"
              :name="$t('brandName')"
              :validate="$v"
              :required="true"
              api-link="brands/GET_ALL"
              label="name"
              @itemSelected="(e) => (form.brandId = e)"
            />
            <select-input
              :is-loaded="isLoading"
              id="productType"
              :name="$t('productType')"
              :validate="$v"
              :required="true"
              api-link="productTypes/GET_ALL"
              label="name"
              @itemSelected="(e) => (form.productTypeId = e)"
            />
            <select-input
              :is-loaded="isLoading"
              id="tags"
              :name="$t('tags')"
              :validate="$v"
              :required="true"
              :multiple="true"
              api-link="tags/GET_ALL"
              label="name"
              @itemSelected="(e) => (form.tags = e)"
            />
            <text-area-input
              id="note"
              :name="$t('note')"
              :validate="$v"
              :is-loading="isLoading"
            />
            <file-input
              id="thumbnail"
              :name="$t('thumbnail')"
              @fileAdded="(base64File) => (form.thumbnail = base64File)"
            />
            <file-input
              id="attachment"
              :name="$t('attachment')"
              @fileAdded="(base64File) => (form.attachment = base64File)"
            />
            <submit-input :name="$t('create')" :disabled="isLoading" />
            <is-loading v-if="isLoading" />
          </form>
        </b-card-body>
      </b-card>
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import { required, maxLength } from "vuelidate/lib/validators";
import IsLoading from "@/components/IsLoading";
import SubmitInput from "@/components/Inputs/SubmitInput";
import TextInput from "@/components/Inputs/TextInput";
import { Product } from "@/models/Product";
import FileInput from "@/components/Inputs/FileInput";
import NumberInput from "@/components/Inputs/NumberInput";
import SelectInput from "@/components/Inputs/SelectInput";
import TextAreaInput from "@/components/Inputs/TextAreaInput";
import RichTextInput from "@/components/Inputs/RichTextInput";
import mixins from "@/mixins";

export default {
  name: "ProductsCreate",
  components: {
    RichTextInput,
    TextAreaInput,
    SelectInput,
    NumberInput,
    FileInput,
    TextInput,
    SubmitInput,
    ContentHeader,
    "v-content": Content,
    IsLoading,
  },
  data() {
    return {
      form: this.$helpers.convertArrayToObject(new Product().fillAbles),
      isLoading: false,
      selectedItem: {},
    };
  },
  validations: {
    form: {
      code: { required },
      barcode: {},
      name: { required, maxLength: maxLength(255) },
      kuName: { required, maxLength: maxLength(255) },
      arName: { required, maxLength: maxLength(255) },
      description: {},
      kuDescription: {},
      arDescription: {},
      price: {},
      cost: {},
      discount: {},
      brandId: {},
      productTypeId: {},
      note: {},
      attachment: {},
    },
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return false;
      this.isLoading = true;
      this.form.discount = mixins.calculateDiscount(
        this.form.discount,
        this.form.price
      );
      this.$store
        .dispatch("products/CREATE", this.form)
        .then(() => {
          this.$v.$reset();
          this.$router.push({ name: "products" });
        })
        .catch(() => (this.isLoading = false));
    },
  },
};
</script>
