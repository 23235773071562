<template>
  <div>
    <content-header>{{ $t('createSupplier') }}</content-header>
    <v-content>
      <b-card>
        <b-card-body>
          <form class="forms-sample" @submit.prevent="onSubmit">
            <text-input id="name" :name="$t('name')" :validate="$v" :required="true" :max-length="true" :is-loading="isLoading" />
            <text-input id="kuName" :name="$t('kuName')" :validate="$v" :required="true" :max-length="true" :is-loading="isLoading" />
            <text-input id="arName" :name="$t('arName')" :validate="$v" :required="true" :max-length="true" :is-loading="isLoading" />
            <text-input id="phone" :name="$t('phone')" :validate="$v" :required="true" :is-loading="isLoading" />
            <text-input id="phone2" :name="$t('phone2')" :validate="$v" :is-loading="isLoading" />
            <text-input id="address" :name="$t('address')" :validate="$v" :is-loading="isLoading" />
            <text-input id="salesManager" :name="$t('salesManager')" :validate="$v" :is-loading="isLoading" />
            <div class="form-group row">
              <label for="payType" class="col-sm-2 col-form-label text-right">{{ $t('payType') }}</label>
              <div class="col-sm-10">
                <b-form-select id="payType" :disabled="isLoading" v-model="form.payType" :options="payTypeOptions"></b-form-select>
              </div>
            </div>
            <number-input id="innerShippingTime" :name="$t('innerShippingTime')" :validate="$v" :is-loading="isLoading" />
            <number-input id="shippingTime" :name="$t('shippingTime')" :validate="$v" :is-loading="isLoading" />
            <number-input id="profit" :name="$t('profit')" :validate="$v" :is-loading="isLoading" />
            <number-input id="discount" :name="$t('discount')" :validate="$v" :is-loading="isLoading" />
            <text-input id="email" :name="$t('email')" :validate="$v" :is-loading="isLoading" />
            <text-input id="country" :name="$t('country')" :validate="$v" :is-loading="isLoading" />
            <text-input id="city" :name="$t('city')" :validate="$v" :is-loading="isLoading" />
            <number-input id="currentLoan" :name="$t('currentLoan')" :validate="$v" :is-loading="isLoading" show-locale-string />
            <number-input id="roofLoan" :name="$t('roofLoan')" :validate="$v" :is-loading="isLoading" show-locale-string />
            <text-area-input id="note" :name="$t('note')" :validate="$v" :is-loading="isLoading" />
            <file-input
                id="attachment"
                :required="true"
                :name="$t('attachment')"
                @fileAdded="base64File => form.attachment = base64File"
            />
            <submit-input :name="$t('create')" :disabled="isLoading"/>
            <is-loading v-if="isLoading" />
          </form>
        </b-card-body>
      </b-card>
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import { required, maxLength } from 'vuelidate/lib/validators'
import IsLoading from "@/components/IsLoading";
import SubmitInput from "@/components/Inputs/SubmitInput";
import TextInput from "@/components/Inputs/TextInput";
import {Supplier} from "@/models/Supplier";
import FileInput from "@/components/Inputs/FileInput";
import TextAreaInput from "@/components/Inputs/TextAreaInput";
import NumberInput from "@/components/Inputs/NumberInput";

export default {
  name: "SuppliersCreate",
  components: {NumberInput, TextAreaInput, FileInput, TextInput, SubmitInput, ContentHeader, 'v-content': Content, IsLoading},
  data() {
    return {
      form: this.$helpers.convertArrayToObject(new Supplier().fillAbles),
      isLoading: false,
      selectedItem: {},
      payTypeOptions: [
        { value: 'Cash', text: this.$t('cash') },
        { value: 'Installment', text: this.$t('installment') },
      ]
    }
  },
  mounted() {
    this.form.payType = 'Cash';
  },
  validations: {
    form: {
      name: {required, maxLength: maxLength(255)},
      kuName: {required, maxLength: maxLength(255)},
      arName: {required, maxLength: maxLength(255)},
      phone: {required},
      phone2: {},
      address: {},
      salesManager: {},
      payType: {},
      innerShippingTime: {},
      shippingTime: {},
      profit: {},
      discount: {},
      email: {},
      country: {},
      city: {},
      currentLoan: {},
      roofLoan: {},
      attachment: {},
      note: {},
    }
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return false;
      this.isLoading = true;
      this.$store.dispatch('suppliers/CREATE', this.form).then(() => {
        this.$v.$reset();
        this.$router.push({name: "suppliers"})
      }).catch(() => this.isLoading = false);
    },
  }
}
</script>
