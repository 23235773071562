<template>
  <section class="content">
    <div class="container-fluid">
      <slot />
    </div>
  </section>
</template>

<script>
export default {
  name: "Content"
}
</script>

<style scoped>

</style>
