import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import mixin from "@/mixins";

const ramSizesToProduct = {
    namespaced: true,
    state: {
        ramSizesToProduct: {},
        createdRamSizesToProduct: {},
    },
    mutations: {
        SET_RAM_SIZES_TO_PRODUCT (state, value) { state.ramSizesToProduct = value },
        SET_CREATED_RAM_SIZES_TO_PRODUCT (state, value) {state.createdRamSizesToProduct = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/Products/ProductRamSize` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}`)
                    .then((response) => {
                        commit('SET_RAM_SIZES_TO_PRODUCT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_RAM_SIZES_TO_PRODUCT', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.post(`/Products/ProductRamSize`, form)
                    .then((response) => {
                        commit('SET_CREATED_RAM_SIZES_TO_PRODUCT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_RAM_SIZES_TO_PRODUCT', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/Products/ProductRamSize/${ids}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        ramSizesToProduct: state => state.ramSizesToProduct,
        createdRamSizesToProduct: state => state.createdRamSizesToProduct,
    }
}

export default ramSizesToProduct;
