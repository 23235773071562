<template>
  <div>
    <content-header>{{ $t('settings') }}</content-header>
    <v-content>
      <h3>Settings</h3>
    </v-content>
  </div>
</template>

<script>
import Content from "../components/Content";
import ContentHeader from "../components/ContentHeader";

export default {
  name: "Settings",
  components: {'v-content': Content, ContentHeader},
}
</script>
