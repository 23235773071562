import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import mixin from "@/mixins";

const pricesToProduct = {
    namespaced: true,
    state: {
        pricesToProduct: {},
        getOnePriceToProduct: {},
        createdPriceToProduct: {},
        updatedPriceToProduct: {},
    },
    mutations: {
        SET_PRICES_TO_PRODUCT (state, value) { state.pricesToProduct = value },
        SET_GET_ONE_PRICE_TO_PRODUCT (state, value) {state.getOnePriceToProduct = value},
        SET_CREATED_PRICE_TO_PRODUCT (state, value) {state.createdPriceToProduct = value},
        SET_UPDATED_PRICE_TO_PRODUCT (state, value) {state.updatedPriceToProduct = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/Products/ProductPriceBranch` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}`)
                    .then((response) => {
                        commit('SET_PRICES_TO_PRODUCT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_PRICES_TO_PRODUCT', {})
                        reject(error)
                    });
            })
        },
        GET_ONE({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/Products/ProductPriceBranch/${id}`)
                    .then((response) => {
                        commit('SET_GET_ONE_PRICE_TO_PRODUCT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GET_ONE_PRICE_TO_PRODUCT', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.post(`/Products/ProductPriceBranch`, form)
                    .then((response) => {
                        commit('SET_CREATED_PRICE_TO_PRODUCT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_PRICE_TO_PRODUCT', {})
                        reject(error)
                    });
            })
        },
        UPDATE({commit}, {id, form}) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.put(`/Products/ProductPriceBranch/${id}`, form)
                    .then((response) => {
                        commit('SET_UPDATED_PRICE_TO_PRODUCT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_UPDATED_PRICE_TO_PRODUCT', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/Products/ProductPriceBranch/${ids}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        pricesToProduct: state => state.pricesToProduct,
        getOnePriceToProduct: state => state.getOnePriceToProduct,
        createdPriceToProduct: state => state.createdPriceToProduct,
        updatedPriceToProduct: state => state.updatedPriceToProduct,
    }
}

export default pricesToProduct;
