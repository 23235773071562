import moment from "moment";
import Techeye from "@/assets/Techeye.png";
import ChinaStars from "@/assets/china-stars.jpeg";
import DefaultProfileImage from "@/assets/images/default.svg";
import { convertCurrency } from "kurdish-number-towords";
import queryString from "query-string";
import {HTTP} from "@/plugins/axios";
import axios from "axios";

export default {
  projectTenant() {
    return process.env.VUE_APP_PROJECT_NAME;
  },
  projectTenantName() {
    return this.getLanguage() === "en"
      ? this.projectTenantNameEnglish()
      : this.getLanguage() === "ar"
        ? this.projectTenantNameArabic()
        : this.projectTenantNameKurdish();
  },
  projectTenantNameKurdish() {
    let name;
    switch (this.projectTenant()) {
      case "Techeye":
        name = "تێک ئای";
        break;
      default:
        name = "تێک ئای";
        break;
    }
    return name;
  },
  projectTenantNameArabic() {
    let name;
    switch (this.projectTenant()) {
      case "Techeye":
        name = "تیک ئای";
        break;
      default:
        name = "تیک ئای";
        break;
    }
    return name;
  },
  projectTenantNameEnglish() {
    let name;
    switch (this.projectTenant()) {
      case "Techeye":
        name = "Techeye";
        break;
      default:
        name = "Techeye";
        break;
    }
    return name;
  },
  projectTenantLogo() {
    let logo;
    switch (this.projectTenant()) {
      case "Techeye":
        logo = Techeye;
        break;
      case "ChinaStars":
        logo = ChinaStars;
        break;
      default:
        logo = Techeye;
        break;
    }
    return logo;
  },
  getAuthUser() {
    return localStorage.getItem("firstName") + ' ' + localStorage.getItem("middleName") + ' ' + localStorage.getItem("lastName");
  },
  getAuthUserProfileImage() {
    return (JSON.parse(localStorage.getItem("userInfo")).image ?? DefaultProfileImage);
  },
  getCurrentDate(add = 0, type = "M", time = false) {
    if (time) {
      return moment()
        .add(add, type)
        .format("YYYY-MM-DD hh:mm:ss A");
    }
    return moment()
      .add(add, type)
      .format("YYYY-MM-DD");
  },
  differanceBetweenDates(fDate, sDate, type = "months") {
    fDate = new Date(fDate);
    sDate = new Date(sDate);
    if (type === "days") {
      let t1 = fDate.getTime();
      let t2 = sDate.getTime();
      return parseInt((t2 - t1) / (24 * 3600 * 1000));
    } else if (type === "weeks") {
      let t1 = fDate.getTime();
      let t2 = sDate.getTime();
      return parseInt((t2 - t1) / (24 * 3600 * 1000 * 7));
    } else if (type === "months") {
      let d1Y = fDate.getFullYear();
      let d2Y = sDate.getFullYear();
      let d1M = fDate.getMonth();
      let d2M = sDate.getMonth();
      return d2M + 12 * d2Y - (d1M + 12 * d1Y);
    } else {
      /// Years
      return sDate.getFullYear() - fDate.getFullYear();
    }
  },
  formatDate(date, format = "YYYY-MM-DD") {
    return moment(date).format(format);
  },
  getImageUrl(url) {
    if (url) {
      if (url.startsWith("http://") || url.startsWith("https://")) {
        return url;
      } else {
        return process.env.VUE_APP_BASE_URL + url;
      }
    }
    return Techeye;
  },
  async changeFileToBase64(file) {
    return new Promise((resolve) => {
      let reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.readAsDataURL(file);
    });
  },
  isNumeric(evt) {
    evt = evt ? evt : window.event;
    let charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      evt.preventDefault();
    } else {
      return true;
    }
  },
  convertArrayToObject(array, index = 0) {
    let returnObj = {};
    array.map((x) => {
      returnObj[x[index]] = "";
    });
    return returnObj;
  },
  dateBetween(
    date,
    fDate,
    sDate,
    fFormat = "YYYY-MM-DDT00:00:00",
    sFormat = "YYYY-MM-DDT23:59:59"
  ) {
    if (!fDate) {
      fDate = new Date().toISOString().slice(0, 10);
    }
    if (!sDate) {
      sDate = new Date().toISOString().slice(0, 10);
    }
    date = moment(date).format(fFormat);
    return moment(date).isBetween(
      moment(fDate).format(fFormat),
      moment(sDate).format(sFormat)
    );
  },
  getLanguage() {
    return localStorage.getItem("language") ?? "en";
  },
  number2Text(value, lang) {
    if (lang === "en") {
      return "Only " + this.ucFirst(this.writtenNumber(value)) + " Dollars";
    } else if (lang === "ar") {
      return (
        this.writtenNumber(parseFloat(value), { lang: "ar" }) +
        " دولارا فقط لا غیر "
      );
    } else {
      return "تەنها " + convertCurrency(value, "دۆلار", "سەنت");
    }
  },
  ucFirst(string, separator = " ") {
    const arr = string.replaceAll("-", separator).split(separator);
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    return arr.join(" ");
  },
  replacePhone(phoneNumber, oldString = "+964", newString = "0") {
    if (phoneNumber) {
      return phoneNumber.replace(oldString, newString);
    }
    return "";
  },
  serializeToQueryString(params) {
    return queryString.stringify(params, { arrayFormat: 'bracket' });
  },
  removeEmptyAndNull(obj) {
    for (let propName in obj) {
      if (
        obj[propName] === null ||
        obj[propName] === undefined ||
        obj[propName] === "" ||
        obj[propName].length === 0
      ) {
        delete obj[propName];
      }
    }
    return obj;
  },
  calculateDiscount(discount, price) {
    return price * (discount / 100);
  },
  async getCurrentDateTimeServer() {
    let datetime = '';
    await axios.get(`https://worldtimeapi.org/api/timezone/Asia/Baghdad`)
        .then((response) => {
          datetime = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    return datetime;
  },
  async getAmountInText(number, lang = 'ku') {
    let language = lang === 'ku'
        ? `NumberToKurdishText`
        : lang === 'ar'
            ? 'NumberToTextAr'
            : 'NumberToTextEn';
    let amountInText = '';
    await HTTP.get(`/Utilities/${language}?number=${number}`)
        .then((response) => {
          amountInText = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    return amountInText;
  },
  numberFormat(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  getCheckedBoxes(chkboxName) {
    let checkboxes = document.getElementsByName(chkboxName);
    let checkboxesChecked = [];
    for (let i=0; i<checkboxes.length; i++) {
      if (checkboxes[i].checked) {
        checkboxesChecked.push(checkboxes[i]);
      }
    }
    return checkboxesChecked.length > 0 ? checkboxesChecked : null;
  }
};
