<template>
  <div>
    <div>
      <content-header>{{ $t('createContractInsurance') }}</content-header>
      <v-content>
        <b-card>
          <b-card-body>
            <form class="forms-sample" @submit.prevent="onSubmit">
              <text-input id="firstName" :name="$t('firstName')" :validate="$v" :is-loading="isLoading" />
              <text-input id="middleName" :name="$t('middleName')" :validate="$v" :is-loading="isLoading" />
              <text-input id="lastName" :name="$t('lastName')" :validate="$v" :is-loading="isLoading" />
              <text-input id="address" :name="$t('address')" :validate="$v" :is-loading="isLoading" />
              <text-input id="phone" :name="$t('phone')" :validate="$v" :is-loading="isLoading" />
              <text-input id="phone2" :name="$t('phone2')" :validate="$v" :is-loading="isLoading" />
              <text-input id="from" :name="$t('from')" :validate="$v" :is-loading="isLoading" />
              <date-time-input id="dob" :name="$t('dob')" :validate="$v" :is-loading="isLoading" />
              <text-input id="note" :name="$t('note')" :validate="$v" :is-loading="isLoading" />
              <file-input
                  id="attachment"
                  :name="$t('attachment')"
                  @fileAdded="base64File => form.attachment = base64File"
              />
              <submit-input :name="$t('create')" :disabled="isLoading" />
              <is-loading v-if="isLoading" />
            </form>
          </b-card-body>
        </b-card>
      </v-content>
    </div>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import IsLoading from "@/components/IsLoading";
import SubmitInput from "@/components/Inputs/SubmitInput";
import TextInput from "@/components/Inputs/TextInput";
import DateTimeInput from "@/components/Inputs/DateTimeInput";
import FileInput from "@/components/Inputs/FileInput";

export default {
  name: "ContractInsurancesCreate",
  components: {FileInput, DateTimeInput, TextInput, SubmitInput, IsLoading, ContentHeader, 'v-content': Content},
  data() {
    return {
      form: {},
      isLoading: false
    }
  },
  validations: {
    form: {
      firstName: {},
      middleName: {},
      lastName: {},
      address: {},
      phone: {},
      phone2: {},
      from: {},
      dob: {},
      isMale: {},
      attachment: {},
      note: {}
    }
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return false;
      this.isLoading = true;
      this.form.id = this.$route.params.id;
      this.$store.dispatch('contracts/CREATE_CONTRACT_INSURANCES', {id: this.$route.params.id, form: this.form}).then(() => {
        this.$v.$reset();
        this.$router.push({name: 'contracts.show', params: {id: this.$route.params.id}})
      }).catch(() => this.isLoading = false);
    },
  }
}
</script>
