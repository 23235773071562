import {i18n} from "@/plugins/i18n";

export class DiskSizeToProduct {
    columns = [];
    id      = 'id';

    fillAbles = [
        [
            'extraPrice',
            i18n.t('extraPrice'),
            true
        ],
        [
            'feature1',
            i18n.t('chest'),
            true
        ],
        [
            'feature2',
            i18n.t('waist'),
            true
        ],
        [
            'feature3',
            i18n.t('hips'),
            true
        ],
        [
            'feature4',
            i18n.t('thigh'),
            true
        ],
        [
            'controls',
            i18n.t('controls'),
            false
        ]
    ];

    getColumns() {
        this.columns = [
            {
                label: 'Id',
                name: 'id',
                sort: false,
                visibility: false,
                uniqueId: true
            },
        ];
        this.fillAbles.map(e => {
            this.columns.push({
                name: e[0],
                label: e[1],
                sort: e[2],
                visibility: e[3] ?? true
            })
        })
        return this.columns;
    }
}
