<template>
  <div class="form-group row">
    <label v-if="showLabel" :for="id" class="col-sm-2 col-form-label text-right" :class="{'required-field': required}">{{ name }}</label>
    <div :class="{'col-sm-12': !showLabel, 'col-sm-10': showLabel}">
      <textarea
          :id="id"
          v-model.trim="validate[form][id].$model"
          :class="{'is-invalid': validationStatus(validate[form][id])}"
          :disabled="isLoading || readonly"
          :placeholder="name"
          :rows="5"
          class="form-control"
          @focus="$event.target.select()"
      >
      </textarea>
      <div v-if="!validate[form][id].required && required" class="invalid-feedback">{{ name }} {{ $t('isRequired') }}</div>
      <div v-if="!validate[form][id].minLength && minLength" class="invalid-feedback">{{ name }} {{ $t('mustHaveAtLeast') }} {{ validate[form][id].$params.minLength.min }} {{ $t('letters') }}</div>
      <div v-if="!validate[form][id].maxLength && maxLength" class="invalid-feedback">{{ name }} {{ $t('mustNotHaveGreaterThen') }} {{ validate[form][id].$params.maxLength.max }} {{ $t('letters') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TextAreaInput",
  props: {
    form: {
      type: String,
      default: 'form'
    },
    id: {
      type: String
    },
    name: {},
    showLabel: {
      type: Boolean,
      default: true
    },
    defaultValue: {
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean
    },
    validate: {
      type: Object
    },
    required: {
      type: Boolean
    },
    minLength: {
      type: Boolean
    },
    maxLength: {
      type: Boolean
    },
    tabIndex: {
      default: null
    }
  },
  mounted() {
    if (this.defaultValue !== '') {
      this.validate[this.form][this.id].$model = this.defaultValue
    }
  },
  methods: {
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    }
  }
}
</script>
