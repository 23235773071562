<template>
  <div>
    <content-header>{{ $t('editSupplier') }}</content-header>
    <v-content>
      <b-card no-body>
        <b-tabs card>
          <b-tab :title="$t('edit')" active>
            <form class="forms-sample" @submit.prevent="onSubmit">
              <text-input id="name" :name="$t('name')" :validate="$v" :required="true" :max-length="true" :is-loading="isLoading" />
              <text-input id="kuName" :name="$t('kuName')" :validate="$v" :required="true" :max-length="true" :is-loading="isLoading" />
              <text-input id="arName" :name="$t('arName')" :validate="$v" :required="true" :max-length="true" :is-loading="isLoading" />
              <text-input id="phone" :name="$t('phone')" :validate="$v" :required="true" :is-loading="isLoading" />
              <text-input id="phone2" :name="$t('phone2')" :validate="$v" :is-loading="isLoading" />
              <text-input id="address" :name="$t('address')" :validate="$v" :is-loading="isLoading" />
              <text-input id="salesManager" :name="$t('salesManager')" :validate="$v" :is-loading="isLoading" />
              <div class="form-group row">
                <label for="payType" class="col-sm-2 col-form-label text-right">{{ $t('payType') }}</label>
                <div class="col-sm-10">
                  <b-form-select id="payType" :disabled="isLoading" v-model="form.payType" :options="payTypeOptions"></b-form-select>
                </div>
              </div>
              <number-input id="innerShippingTime" :name="$t('innerShippingTime')" :validate="$v" :is-loading="isLoading" />
              <number-input id="shippingTime" :name="$t('shippingTime')" :validate="$v" :is-loading="isLoading" />
              <number-input id="profit" :name="$t('profit')" :validate="$v" :is-loading="isLoading" />
              <number-input id="discount" :name="$t('discount')" :validate="$v" :is-loading="isLoading" />
              <text-input id="email" :name="$t('email')" :validate="$v" :is-loading="isLoading" />
              <text-input id="country" :name="$t('country')" :validate="$v" :is-loading="isLoading" />
              <text-input id="city" :name="$t('city')" :validate="$v" :is-loading="isLoading" />
              <number-input id="currentLoan" :name="$t('currentLoan')" :validate="$v" :is-loading="isLoading" show-locale-string />
              <number-input id="roofLoan" :name="$t('roofLoan')" :validate="$v" :is-loading="isLoading" show-locale-string />
              <text-area-input id="note" :name="$t('note')" :validate="$v" :is-loading="isLoading" />
              <submit-input icon="fa-save" :name="$t('save')" :disabled="isLoading"/>
              <is-loading v-if="isLoading" />
            </form>
          </b-tab>
          <b-tab :title="$t('attachment')">
            <b-button
                variant="outline-primary"
                class="float-right"
                @click.prevent="$router.push({ name: 'suppliers.attachment.create', params: {id: $route.params.id} })"
            ><i class="fas fa-plus-circle"></i> {{ $t('create') }}</b-button>
            <supplier-attachments-list :attachments="supplierAttachments" @loadAll="loadOneData" />
          </b-tab>
        </b-tabs>
      </b-card>
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import {required, maxLength} from 'vuelidate/lib/validators'
import IsLoading from "@/components/IsLoading";
import {Supplier} from "@/models/Supplier";
import SubmitInput from "@/components/Inputs/SubmitInput";
import TextInput from "@/components/Inputs/TextInput";
import TextAreaInput from "@/components/Inputs/TextAreaInput";
import SupplierAttachmentsList from "@/views/suppliers/attachments/List";
import NumberInput from "@/components/Inputs/NumberInput";

export default {
  name: "SupplierEdit",
  components: {NumberInput, SupplierAttachmentsList, TextAreaInput, SubmitInput, ContentHeader, 'v-content': Content, IsLoading, TextInput},
  data() {
    return {
      form: this.$helpers.convertArrayToObject(new Supplier().fillAbles),
      isLoading: false,
      selectedItem: {},
      supplierAttachments: [],
      payTypeOptions: [
        { value: 'Cash', text: this.$t('cash') },
        { value: 'Installment', text: this.$t('installment') },
      ]
    };
  },
  mounted() {
    this.isLoading = true;
    this.$store.dispatch('suppliers/GET_ONE', this.$route.params.id).then((response) => {
      this.form                 = response.data;
      this.supplierAttachments  = response.data.attachments;
      this.isLoading            = false;
    }).catch(() => this.isLoading = false)
  },
  validations: {
    form: {
      name: {required, maxLength: maxLength(255)},
      kuName: {required, maxLength: maxLength(255)},
      arName: {required, maxLength: maxLength(255)},
      phone: {required},
      phone2: {},
      address: {},
      salesManager: {},
      payType: {},
      innerShippingTime: {},
      shippingTime: {},
      profit: {},
      discount: {},
      email: {},
      country: {},
      city: {},
      currentLoan: {},
      roofLoan: {},
      attachment: {},
      note: {},
    }
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return false;
      this.isLoading = true;
      this.$store.dispatch('suppliers/UPDATE', {id: this.$route.params.id, form: this.form}).then(() => {
        this.$v.$reset();
        this.$router.push({name: "suppliers"})
      }).catch(() => this.isLoading = false);
    },
    loadOneData() {
      this.isLoading = true;
      this.$store.dispatch('suppliers/GET_ONE', this.$route.params.id).then((response) => {
        this.form                 = response.data;
        this.supplierAttachments  = response.data.attachments;
        this.isLoading            = false;
      }).catch(() => this.isLoading = false)
    }
  }
}
</script>
