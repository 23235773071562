import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import mixin from "@/mixins";

const invoices = {
    namespaced: true,
    state: {
        invoices: {},
        createdInvoice: {},
        getOneInvoice: {},
        updatedInvoice: {},
    },
    mutations: {
        SET_INVOICES (state, value) { state.invoices = value },
        SET_CREATED_INVOICE (state, value) {state.createdInvoice = value},
        SET_GET_ONE_INVOICE (state, value) {state.getOneInvoice = value},
        SET_UPDATED_INVOICE (state, value) {state.updatedInvoice = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/Invoices` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}&invoiceType=5`)
                    .then((response) => {
                        commit('SET_INVOICES', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_INVOICES', {})
                        reject(error)
                    });
            })
        },
        GET_ONE({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/Invoices/${id}?invoiceType=5`)
                    .then((response) => {
                        commit('SET_GET_ONE_INVOICE', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GET_ONE_INVOICE', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.post(`/Invoices?invoiceType=5`, form)
                    .then((response) => {
                        commit('SET_CREATED_INVOICE', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_INVOICE', {})
                        reject(error)
                    });
            })
        },
        UPDATE({commit}, {id, form}) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.put(`/Invoices/${id}?invoiceType=5`, form)
                    .then((response) => {
                        commit('SET_UPDATED_INVOICE', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_UPDATED_INVOICE', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/Invoices/${ids}?invoiceType=5`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
        CREATE_ONE_ROW(commit, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.post(`/Products/AddProductStock`, form)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        invoices: state => state.invoices,
        createdInvoice: state => state.createdInvoice,
        getOneInvoice: state => state.getOneInvoice,
        updatedInvoice: state => state.updatedInvoice,
    }
}

export default invoices;
