<template>
  <div>
    <content-header>{{ $t('createInvoice') }}</content-header>
    <v-content>
      <b-card>
        <b-card-body>
          <form class="forms-sample" @submit.prevent="onSubmit">
            <select-input
                :is-loaded="isLoading"
                id="products"
                :name="$t('products')"
                :placeholder="$t('products')"
                validate="$v"
                :required="true"
                api-link="products/GET_ALL"
                label="name"
                @itemSelected="productSelected"
            />
            <div class="form-group row">
              <label for="invoiceTypes" class="col-sm-2 col-form-label text-right">{{ $t('invoiceTypes') }}</label>
              <div class="col-sm-10">
                <b-form-select id="invoiceTypes" v-model="invoiceType" :options="invoiceTypes"></b-form-select>
              </div>
            </div>
            <div class="form-group row">
              <label for="id" class="col-sm-2 col-form-label text-right">{{ $t('date') }}</label>
              <div class="col-sm-10">
                <div class="input-group">
                  <b-form-datepicker id="example-datepicker" v-model="invoiceDate" class="mb-2"></b-form-datepicker>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-2">{{ $t('colors') }}</div>
              <div class="col-2">{{ $t('diskSize') }}</div>
              <div class="col-2">{{ $t('ramSize') }}</div>
              <div class="col-2">{{ $t('screenSize') }}</div>
              <div class="col-2">{{ $t('qty') }}</div>
              <div class="col-2">{{ $t('price') }}</div>
            </div>
            <div class="colors" v-for="color in colors" :key="color.id">
              <div class="diskSizes" v-for="diskSize in diskSizes" :key="diskSize.id">
                <div class="ramSizes" v-for="ramSize in ramSizes" :key="ramSize.id">
                  <div v-if="screenSizeLength >= 1">
                    <div class="screenSizes" v-for="screenSize in screenSizes" :key="screenSize.id">
                      <div class="row">
                        <div class="col-2">{{ color.name }}</div>
                        <div class="col-2">{{ diskSize.name }}</div>
                        <div class="col-2">{{ ramSize.name }}</div>
                        <div class="col-2">{{ screenSize.name }}</div>
                        <div class="col-2">
                          <div class="form-group row">
                            <div class="col-sm-12">
                              <input
                                  :id="'qty_' + color.colorId + '_' + diskSize.diskSizeId + '_' + ramSize.ramSizeId + '_' + screenSize.screenSizeId"
                                  :disabled="isLoading"
                                  :placeholder="$t('qty')"
                                  type="number"
                                  autocomplete="off"
                                  class="form-control"
                                  @focus="$event.target.select()"
                                  v-on:keypress="e => $helpers.isNumeric(e)"
                                  step=".001"
                                  :value="findInStock(productId, color.colorId, diskSize.diskSizeId, ramSize.ramSizeId, screenSize.screenSizeId)"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-2">
                          <div class="form-group row">
                            <div class="col-sm-12">
                              <input
                                  :id="'price_' + color.colorId + '_' + diskSize.diskSizeId + '_' + ramSize.ramSizeId + '_' + screenSize.screenSizeId"
                                  :disabled="isLoading"
                                  :placeholder="$t('price')"
                                  type="number"
                                  autocomplete="off"
                                  class="form-control"
                                  @focus="$event.target.select()"
                                  v-on:keypress="e => $helpers.isNumeric(e)"
                                  step=".001"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="row">
                      <div class="col-2">{{ color.name }}</div>
                      <div class="col-2">{{ diskSize.name }}</div>
                      <div class="col-2">{{ ramSize.name }}</div>
                      <div class="col-2">-</div>
                      <div class="col-2">
                        <div class="form-group row">
                          <div class="col-sm-12">
                            <input
                                :id="'qty_' + color.colorId + '_' + diskSize.diskSizeId + '_' + ramSize.ramSizeId + '_' + 'undefined'"
                                :disabled="isLoading"
                                :placeholder="$t('qty')"
                                type="number"
                                autocomplete="off"
                                class="form-control"
                                @focus="$event.target.select()"
                                v-on:keypress="e => $helpers.isNumeric(e)"
                                step=".001"
                                :value="findInStock(productId, color.colorId, diskSize.diskSizeId, ramSize.ramSizeId, 'undefined')"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-2">
                        <div class="form-group row">
                          <div class="col-sm-12">
                            <input
                                :id="'price_' + color.colorId + '_' + diskSize.diskSizeId + '_' + ramSize.ramSizeId + '_' + 'undefined'"
                                :disabled="isLoading"
                                :placeholder="$t('price')"
                                type="number"
                                autocomplete="off"
                                class="form-control"
                                @focus="$event.target.select()"
                                v-on:keypress="e => $helpers.isNumeric(e)"
                                step=".001"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <submit-input :name="$t('create')" :disabled="isLoading"/>
            <is-loading v-if="isLoading" />
          </form>
        </b-card-body>
      </b-card>
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import IsLoading from "@/components/IsLoading";
import SubmitInput from "@/components/Inputs/SubmitInput";
import {Invoice} from "@/models/Invoice";
import SelectInput from "@/components/Inputs/SelectInput";
import mixins from "@/mixins";

export default {
  name: "InvoiceCreate",
  components: {SelectInput, SubmitInput, ContentHeader, 'v-content': Content, IsLoading},
  data() {
    return {
      form: new Invoice(),
      isLoading: false,
      invoiceDate: mixins.getCurrentDate(),
      invoiceType: 'sudden',
      invoiceTypes: [
        {
          value: "sudden",
          text: this.$t("sudden"),
        },
        {
          value: "periodic",
          text: this.$t("periodic"),
        },
        {
          value: "continuous",
          text: this.$t("continuous"),
        },
      ],
      productId: 0,
      diskSizes: [],
      ramSizes: [],
      colors: [],
      screenSizes: [],
      stockProducts: []
    }
  },
  methods: {
    onSubmit() {
      let form = {
        invoiceType: this.invoiceType,
        date: this.invoiceDate,
        screenSizeLength: 0
      };

      let orders = [];
      for (let i = 0; i < this.colors.length; i++) {
        for (let j = 0; j < this.diskSizes.length; j++) {
          for (let k = 0; k < this.ramSizes.length; k++) {
            for (let l = 0; l < this.screenSizeLength; l++) {
              orders.push({
                productId: this.productId,
                colorId: this.colors[i].colorId,
                diskSizeId: this.diskSizes[j].diskSizeId,
                ramSizeId: this.ramSizes[k].ramSizeId,
                screenSizeId: this.screenSizes.length === 0 ? 0 : this.screenSizes[l].screenSizeId,
                qty: document.getElementById('qty_' + this.colors[i].colorId + '_' + this.diskSizes[j].diskSizeId + '_' + this.ramSizes[k].ramSizeId + '_' + (this.screenSizes.length === 0 ? 'undefined' : this.screenSizes[l].screenSizeId)).value,
                newQty: document.getElementById('qty_' + this.colors[i].colorId + '_' + this.diskSizes[j].diskSizeId + '_' + this.ramSizes[k].ramSizeId + '_' + (this.screenSizes.length === 0 ? 'undefined' : this.screenSizes[l].screenSizeId)).value,
                price: document.getElementById('price_' + this.colors[i].colorId + '_' + this.diskSizes[j].diskSizeId + '_' + this.ramSizes[k].ramSizeId + '_' + (this.screenSizes.length === 0 ? 'undefined' : this.screenSizes[l].screenSizeId)).value
              })
            }
          }
        }
      }

      form.orders = orders;
      this.isLoading = true;
      this.$store.dispatch('invoices/CREATE', form).then(() => {
        this.$router.push({name: "invoice"})
      }).catch(() => this.isLoading = false);
    },
    productSelected(e) {
      this.productId = e;
      this.isLoading = true;
      this.$store.dispatch('products/GET_ONE', e).then((response) => {
        this.diskSizes    = response.data.availableDiskSizes;
        this.ramSizes     = response.data.availableRamSizes;
        this.colors       = response.data.availableColors;
        this.screenSizes  = response.data.availableScreenSizes;
        this.screenSizeLength = response.data.availableScreenSizes.length === 0 ? 1 : response.data.availableScreenSizes.length;
        this.isLoading    = false;
      }).catch(() => this.isLoading = false);
      this.$store.dispatch('products/GET_PRODUCT_BY_ID_STOCK', e).then((response) => {
        this.stockProducts  = response.data;
        this.isLoading    = false;
      }).catch(() => this.isLoading = false);
    },
    findInStock(product, colorId, diskSizeId, ramSizeId, screenSizeId) {
      let filter = {product, colorId, diskSizeId, ramSizeId, screenSizeId: screenSizeId === 'undefined' ? 1 : screenSizeId}
      this.$store.dispatch('products/GET_PRODUCT_PRICE_ID_STOCK', filter).then((response) => {
        document.getElementById('qty_' + colorId + '_' + diskSizeId + '_' + ramSizeId + '_' + screenSizeId).value = response.data.qty;
        document.getElementById('price_' + colorId + '_' + diskSizeId + '_' + ramSizeId + '_' + screenSizeId).value = response.data.price;
        this.isLoading    = false;
      }).catch(() => this.isLoading = false);
    }
  }
}
</script>
