export default {
  // Start Generals
  kurdish: "کوردی",
  arabic: "عربی",
  english: "English",
  language: "اللغە",
  controls: "التحکم",
  dashboard: "لوحة القيادة",
  kurdishName: "الاسم الکردی",
  arabicName: "الاسم العربی",
  englishName: "الاسم الانجليزي",
  isoCode: "كود ISO",
  postalCode: "الرمز البريدي",
  city: "مدينة",
  noResultsFound: "لم يتم العثور على نتائج",
  totalRecords: "إجمالي السجلات",
  save: "حفظ",
  yes: "نعم",
  no: "لا",
  delete: "مسح",
  someThingWentWrong: "حدث خلل",
  settings: "الإعدادات",
  listIsEmpty: "القائمة فارغة",
  // End Generals

  // Start Custom Components
  isRequired: "مطلوب",
  remove: "إزالة",
  fileIsTooBig: "الملف كبير جدا",
  typeNotSupported: "النوع غير مدعوم",
  maxFileUploadReach: "تم الوصول إلى أقصى تحميل للملف",
  pleaseSelect: "الرجاء التحديد",
  mustHaveAtLeast: "يجب أن يكون على الأقل",
  letters: "حروفا",
  mustNotHaveGreaterThen: "يجب ألا يكون أكبر من",
  attachments: "المرفقات",
  close: "غلق",
  search: "بحث",
  multipleDelete: "حذف متعدد",
  multipleRestore: "استعادة متعددة",
  multipleForceDelete: "حذف نهائی المتعددة",
  export: "تصدیر",
  noRowsSelected: "لم يتم تحديد صف",
  youWantToDeletePermanentlySelectedRows: "تريد حذف الصفوف المحددة بشكل دائم",
  youWantToRestorePermanentlySelectedRows:
    "تريد استعادة الصفوف المحددة بشكل دائم",
  yesDelete: "نعم ، احذف",
  yesRestore: "نعم استعادة",
  yourDataHasBeenDeletedPermanently: "تم حذف بياناتك نهائيًا",
  yourDataHasBeenRestoredPermanently: "تمت استعادة بياناتك بشكل دائم",
  numberOfTypes: "عدد الأنواع",
  pleaseEnterAtLeastOneType: "الرجاء إدخال على الأقل نوع واحد",
  // End Custom Components
};
