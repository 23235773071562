import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import mixin from "@/mixins";

const products = {
    namespaced: true,
    state: {
        products: {},
        stockProducts: {},
        createdProduct: {},
        getOneProduct: {},
        updatedProduct: {},
    },
    mutations: {
        SET_PRODUCTS (state, value) { state.products = value },
        SET_STOCK_PRODUCTS (state, value) { state.stockProducts = value },
        SET_CREATED_PRODUCT (state, value) {state.createdProduct = value},
        SET_GET_ONE_PRODUCT (state, value) {state.getOneProduct = value},
        SET_UPDATED_PRODUCT (state, value) {state.updatedProduct = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/Products` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}`)
                    .then((response) => {
                        commit('SET_PRODUCTS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_PRODUCTS', {})
                        reject(error)
                    });
            })
        },
        GET_ONE({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/Products/${id}`)
                    .then((response) => {
                        commit('SET_GET_ONE_PRODUCT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GET_ONE_PRODUCT', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.post(`/Products`, form)
                    .then((response) => {
                        commit('SET_CREATED_PRODUCT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_PRODUCT', {})
                        reject(error)
                    });
            })
        },
        UPDATE({commit}, {id, form}) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.put(`/Products/${id}`, form)
                    .then((response) => {
                        commit('SET_UPDATED_PRODUCT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_UPDATED_PRODUCT', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/Products/${ids}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
        GET_BARCODE() {
            return new Promise((resolve, reject) => {
                HTTP.get(`/Utilities/GenerateNewBarcode?series=true`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
        GET_ALL_PRODUCT_STOCK({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/Products/GetProductStock` +
                    `${serverParams._start}` +
                    `${serverParams._end}` +
                    `${serverParams._sort}` +
                    `${serverParams._order}` +
                    `${serverParams._search}` +
                    `${serverParams.custom_params}`)
                    .then((response) => {
                        commit('SET_STOCK_PRODUCTS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_STOCK_PRODUCTS', {})
                        reject(error)
                    });
            })
        },
        GET_PRODUCT_BY_ID_STOCK(commit, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/ClientSide/GetProductByIdFromStock?id=${id}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
        GET_PRODUCT_PRICE_ID_STOCK(commit, {product, colorId, diskSizeId, ramSizeId, screenSizeId}) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/ClientSide/GetProductByIdAndFeatures?product=${product}&colorId=${colorId}&diskSizeId=${diskSizeId}&ramSizeId=${ramSizeId}&screenSizeId=${screenSizeId}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
        EDIT_PRODUCT_STOCK(commit, {id, price}) {
            return new Promise((resolve, reject) => {
                HTTP.put(`/Products/EditProductStock?id=${id}`, {price})
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
        DELETE_PRODUCT_STOCK(commit, id) {
            return new Promise((resolve, reject) => {
                let url = `/Products/DeleteProductStock?id=${id}`;
                if (mixin.projectTenant() === 'Techeye') {
                    url += `&forceDelete=true`;
                }
                HTTP.delete(url)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        products: state => state.products,
        stockProducts: state => state.stockProducts,
        createdProduct: state => state.createdProduct,
        getOneProduct: state => state.getOneProduct,
        updatedProduct: state => state.updatedProduct,
    }
}

export default products;
