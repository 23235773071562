<template>
  <div>
    <content-header>{{ $t('stockProducts') }}</content-header>
    <v-content>
      <vue-bootstrap4-table
          :rows="rows"
          :columns="columns"
          :config="config"
          :total-rows="totalRecords"
          :actions="actions"
          @on-change-query="onChangeQuery"
          @on-create="$router.push({name: 'products.stock.add'})"
          @on-edit="$router.push({name: 'products.stock.edit'})"
      >
        <template slot="empty-results">{{ $t('noResultsFound') }}</template>
        <template slot="pagination-info" slot-scope="props">
          <template v-if="props.currentPageRowsLength !== 0">{{totalRecords}} {{ $t('totalRecords') }}</template>
          <template v-else>{{ $t('noResultsFound') }}</template>
        </template>
      </vue-bootstrap4-table>
      <is-loading v-if="isLoading" />
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import IsLoading from "@/components/IsLoading";
import {serverParams, tableConfigure} from "@/plugins/constants";
import VueBootstrap4Table from 'vue-bootstrap4-table'
import {ProductStock} from "@/models/ProductStock";
import {i18n} from "@/plugins/i18n";

export default {
  name: "ProductStockList",
  components: {IsLoading, 'v-content': Content, ContentHeader, VueBootstrap4Table},
  data() {
    return {
      isLoading: false,
      model: new ProductStock(),
      columns: [],
      rows: [],
      actions: [
        {
          btn_text: "<i class='fal fa-plus'></i> " + i18n.t('add'),
          event_name: "on-create",
          class: "btn btn-outline-primary"
        },
        {
          btn_text: "<i class='fal fa-save'></i> " + i18n.t('edit'),
          event_name: "on-edit",
          class: "btn btn-outline-success"
        },
      ],
      totalRecords: 0,
      config: tableConfigure(this.$t('productStockList')),
      serverParams: serverParams,
      selectedRows: []
    };
  },
  beforeMount() {
    this.columns  = this.model.getColumns();
  },
  methods: {
    loadData() {
      this.isLoading = true;
      this.$store.dispatch('products/GET_ALL_PRODUCT_STOCK', this.serverParams)
          .then((response) => {
            this.rows         = response.data
            this.totalRecords = parseInt(response.headers['x-total-count'])
            this.isLoading    = false
          })
          .catch(() => this.isLoading = false);
    },
    onChangeQuery(queryParams) {
      serverParams._start   = (queryParams.page - 1) * queryParams.per_page
      serverParams._end     = queryParams.page * queryParams.per_page
      serverParams._sort    = queryParams.sort[0] ? queryParams.sort[0].name : ''
      serverParams._order   = queryParams.sort[0] ? queryParams.sort[0].order : ''
      serverParams._search  = queryParams.global_search ?? ''
      this.loadData()
    },
  }
}
</script>
