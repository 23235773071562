import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import mixin from "@/mixins";
import GroupTypes from "@/enums/GroupTypes";
import ProductAttributes from "@/enums/ProductAttributes";

const featuredCollections = {
    namespaced: true,
    state: {
        featuredCollections: {},
        createdFeaturedCollection: {},
        getOneFeaturedCollection: {},
        updatedFeaturedCollection: {},
    },
    mutations: {
        SET_FEATURED_COLLECTIONS (state, value) { state.featuredCollections = value },
        SET_CREATED_FEATURED_COLLECTIONS (state, value) {state.createdFeaturedCollection = value},
        SET_GET_ONE_FEATURED_COLLECTIONS (state, value) {state.getOneFeaturedCollection = value},
        SET_UPDATED_FEATURED_COLLECTIONS (state, value) {state.updatedFeaturedCollection = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/Main` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}` +
                        `&objectName=${ProductAttributes.Group}&groupType=${GroupTypes.FeaturedCollection}`)
                    .then((response) => {
                        commit('SET_FEATURED_COLLECTIONS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_FEATURED_COLLECTIONS', {})
                        reject(error)
                    });
            })
        },
        GET_ONE({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/Main/${id}?objectName=${ProductAttributes.Group}`)
                    .then((response) => {
                        commit('SET_GET_ONE_FEATURED_COLLECTIONS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GET_ONE_FEATURED_COLLECTIONS', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.post(`/Main?objectName=${ProductAttributes.Group}`, form)
                    .then((response) => {
                        commit('SET_CREATED_FEATURED_COLLECTIONS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_FEATURED_COLLECTIONS', {})
                        reject(error)
                    });
            })
        },
        UPDATE({commit}, {id, form}) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.put(`/Main/${id}?objectName=${ProductAttributes.Group}`, form)
                    .then((response) => {
                        commit('SET_UPDATED_FEATURED_COLLECTIONS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_UPDATED_FEATURED_COLLECTIONS', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/Main/${ids}?objectName=${ProductAttributes.Group}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        featuredCollections: state => state.featuredCollections,
        createdFeaturedCollection: state => state.createdFeaturedCollection,
        getOneFeaturedCollection: state => state.getOneFeaturedCollection,
        updatedFeaturedCollection: state => state.updatedFeaturedCollection,
    }
}

export default featuredCollections;
