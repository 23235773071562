<template>
  <div>
    <content-header>{{ $t('productTypes') }}</content-header>
    <v-content>
      <div class="row">
        <div class="col-4">
          <select-input
              :is-loaded="isLoading"
              id="departments"
              :name="$t('departments')"
              :placeholder="$t('departments')"
              :show-label="false"
              :required="true"
              api-link="productTypes/GET_ALL"
              label="name"
              @itemSelected="parentSelected"
          />
        </div>
        <div class="col-4">
          <select-input
              :is-loaded="isLoading"
              id="sections"
              :name="$t('sections')"
              :placeholder="$t('sections')"
              :show-label="false"
              :required="true"
              api-link="productTypes/GET_ALL"
              :api-params="{ id: parentId }"
              label="name"
              @itemSelected="childSelected"
              :disabled="subParentDisable"
          />
        </div>
        <div class="col-4">
          <select-input
              :is-loaded="isLoading"
              id="categories"
              :name="$t('categories')"
              :placeholder="$t('categories')"
              :show-label="false"
              :required="true"
              api-link="productTypes/GET_ALL"
              :api-params="{ id: subParentId }"
              label="name"
              @itemSelected="subChildSelected"
              :disabled="childDisable"
          />
        </div>
      </div>
      <vue-bootstrap4-table
          :rows="rows"
          :columns="columns"
          :config="config"
          :total-rows="totalRecords"
          :actions="actions"
          @on-change-query="onChangeQuery"
          @on-select-row="(rowSelect) => this.selectedRows = rowSelect.selected_items"
          @on-all-select-rows="(rowSelect) => this.selectedRows = rowSelect.selected_items"
          @on-unselect-row="(rowSelect) => this.selectedRows = rowSelect.selected_items"
          @on-all-unselect-rows="(rowSelect) => this.selectedRows = rowSelect.selected_items"
          @on-create="$router.push({name: 'productTypes.create'})"
          @on-multiple-delete="onMultipleDelete"
      >
        <template slot="empty-results">{{ $t('noResultsFound') }}</template>
        <template slot="pagination-info" slot-scope="props">
          <template v-if="props.currentPageRowsLength !== 0">{{totalRecords}} {{ $t('totalRecords') }}</template>
          <template v-else>{{ $t('noResultsFound') }}</template>
        </template>
        <template slot="attachment" slot-scope="props">
          <show-attachment :attachment="props.row.attachment" :title="props.row.name" :width="150" :height="150" />
        </template>
        <template slot="controls" slot-scope="props">
          <control-buttons
              :props="props"
              edit-link="productTypes.edit"
              delete-link="productTypes/DELETE"
              v-on:successAction="loadData"
          />
        </template>
      </vue-bootstrap4-table>
      <is-loading v-if="isLoading" />
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import {ProductType} from "@/models/ProductType";
import IsLoading from "@/components/IsLoading";
import ControlButtons from "@/components/ControlButtons";
import {actions, serverParams, swalMixin, tableActions, tableConfigure} from "@/plugins/constants";
import VueBootstrap4Table from 'vue-bootstrap4-table'
import ShowAttachment from "@/components/ShowAttachment";
import SelectInput from "@/components/Inputs/SelectInput";

export default {
  name: "ProductTypeList",
  components: {
    SelectInput,
    ShowAttachment, ControlButtons, IsLoading, 'v-content': Content, ContentHeader, VueBootstrap4Table},
  data() {
    return {
      isLoading: false,
      model: new ProductType(),
      columns: [],
      rows: [],
      actions: tableActions(true),
      totalRecords: 0,
      config: tableConfigure(this.$t('productTypeList')),
      serverParams: serverParams,
      selectedRows: [],
      parentId: 0,
      subParentId: 0,
      subParentDisable: true,
      childId: 0,
      childDisable: true,
    };
  },
  beforeMount() {
    this.columns  = this.model.getColumns();
  },
  methods: {
    loadData() {
      this.isLoading = true;
      let parentId =
        this.childId !== 0
          ? this.childId
          : this.subParentId !== 0
            ? this.subParentId
            : this.parentId;

      if (parentId !== 0) {
        this.serverParams.custom_params = {
          id: parentId,
        };
      }

      this.$store.dispatch('productTypes/GET_ALL', this.serverParams)
          .then((response) => {
            this.rows         = response.data
            this.totalRecords = parseInt(response.headers['x-total-count'])
            this.isLoading    = false
          })
          .catch(() => this.isLoading = false);
    },
    onChangeQuery(queryParams) {
      serverParams._start   = (queryParams.page - 1) * queryParams.per_page
      serverParams._end     = queryParams.page * queryParams.per_page
      serverParams._sort    = queryParams.sort[0] ? queryParams.sort[0].name : ''
      serverParams._order   = queryParams.sort[0] ? queryParams.sort[0].order : ''
      serverParams._search  = queryParams.global_search ?? ''
      this.loadData()
    },
    async onMultipleDelete() {
      if (this.selectedRows.length === 0) {
        swalMixin('error', 'noRows')
        return false;
      }
      if (await actions('productTypes/DELETE', this.selectedRows, 'delete')) {
        this.loadData();
      }
    },
    async parentSelected(e) {
      this.childId = 0;
      this.subParentDisable = true;
      this.subParentId = 0;
      this.parentId = await e;
      this.subParentDisable = false;
      this.loadData();
    },
    async childSelected(e) {
      this.childDisable = true;
      this.childId = 0;
      this.subParentId = await e;
      this.childDisable = false;
      this.loadData();
    },
    async subChildSelected(e) {
      this.childId = await e;
      this.loadData();
    },
  }
}
</script>
