<template>
  <div>
    <content-header>{{ $t('dashboard') }}</content-header>
    <v-content>
      <b-row>
        <b-col sm="6" md="6" lg="3">
          <div class="small-box bg-gradient-primary">
            <div class="inner">
              <h3>{{ products }}</h3>
              <p>{{ $t('numberOfProducts') }}</p>
            </div>
            <div class="icon"><i class="fal fa-inventory"></i></div>
          </div>
        </b-col>
        <b-col sm="6" md="6" lg="3">
          <div class="small-box bg-gradient-danger">
            <div class="inner">
              <h3>{{ brands }}</h3>
              <p>{{ $t('numberOfBrands') }}</p>
            </div>
            <div class="icon"><i class="fal fa-store"></i></div>
          </div>
        </b-col>
        <b-col sm="6" md="6" lg="3">
          <div class="small-box bg-gradient-info">
            <div class="inner">
              <h3>{{ collections }}</h3>
              <p>{{ $t('numberOfCollections') }}</p>
            </div>
            <div class="icon"><i class="fal fa-album-collection"></i></div>
          </div>
        </b-col>
        <b-col sm="6" md="6" lg="3">
          <div class="small-box bg-gradient-success">
            <div class="inner">
              <h3>{{ tags }}</h3>
              <p>{{ $t('numberOfTags') }}</p>
            </div>
            <div class="icon"><i class="fal fa-tags"></i></div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table bordered striped hover caption-top :items="latestProductsRow" :fields="latestProductsHeader">
            <template #table-caption>{{ $t('latestProducts') }}</template>
          </b-table>
        </b-col>
      </b-row>
    </v-content>
  </div>
</template>

<script>
import ContentHeader from "../components/ContentHeader";
import Content from "../components/Content";
import {serverParams} from "@/plugins/constants";

export default {
  name: "Dashboard",
  components: {'v-content': Content, ContentHeader},
  data() {
    return {
      products: 0,
      brands: 0,
      collections: 0,
      tags: 0,
      latestProductsHeader: [
        { key: 'code', label: this.$i18n.t('code') },
        { key: 'barcode', label: this.$i18n.t('barcode') },
        { key: 'name', label: this.$i18n.t('name') },
        { key: 'kuName', label: this.$i18n.t('kurdishName') },
        { key: 'arName', label: this.$i18n.t('arabicName') },
        { key: 'cost', label: this.$i18n.t('cost') },
        { key: 'price', label: this.$i18n.t('price') },
        { key: 'productTypeName', label: this.$i18n.t('productType') },
      ],
      latestProductsRow: [],
      latestCollectionsHeader: [
        { key: 'name', label: this.$i18n.t('name') },
        { key: 'kuName', label: this.$i18n.t('kurdishName') },
        { key: 'arName', label: this.$i18n.t('arabicName') },
      ],
      latestCollectionsRow: [],
      productsInFrontSliderHeader: [
        { key: 'code', label: this.$i18n.t('code') },
        { key: 'barcode', label: this.$i18n.t('barcode') },
        { key: 'name', label: this.$i18n.t('name') },
        { key: 'kuName', label: this.$i18n.t('kurdishName') },
        { key: 'arName', label: this.$i18n.t('arabicName') }
      ],
      productsInFrontSliderRow: [],
      productsInAllPagesTopSliderHeader: [
        { key: 'title', label: this.$i18n.t('title') },
        { key: 'kuTitle', label: this.$i18n.t('kuTitle') },
        { key: 'arTitle', label: this.$i18n.t('arTitle') },
        { key: 'name', label: this.$i18n.t('name') },
      ],
      productsInAllPagesTopSliderRow: [],
    }
  },
  mounted() {
    this.getAllWidgetNumbers();
    this.latestProducts();
    this.latestCollections();
    this.productsInFrontSlider();
    this.productsAllPagesTopSlider();
  },
  methods: {
    getAllWidgetNumbers() {
      this.$store.dispatch('dashboard/GET_ALL_WIDGET_NUMBERS', serverParams)
          .then((response) => {
            this.products     = response.data.products;
            this.brands       = response.data.brands;
            this.collections  = response.data.collections;
            this.tags         = response.data.tags;
          })
          .catch(() => this.isLoading = false);
    },
    latestProducts() {
      this.$store.dispatch('products/GET_ALL', serverParams)
          .then((response) => {
            this.latestProductsRow  = response.data;
          })
          .catch(() => this.isLoading = false);
    },
    latestCollections() {
      // this.$store.dispatch('collections/GET_ALL', serverParams)
      //     .then((response) => {
      //       this.latestCollectionsRow = response.data;
      //     })
      //     .catch(() => this.isLoading = false);
    },
    productsInFrontSlider() {
      this.$store.dispatch('frontPageSliders/GET_ALL', serverParams)
          .then((response) => {
            this.productsInFrontSliderRow = response.data;
          })
          .catch(() => this.isLoading = false);
    },
    productsAllPagesTopSlider() {
      // this.$store.dispatch('allPageTopSliders/GET_ALL', serverParams)
      //     .then((response) => {
      //       this.productsInAllPagesTopSliderRow = response.data;
      //     })
      //     .catch(() => this.isLoading = false);
    }
  }
}
</script>
