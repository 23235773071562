import {i18n} from "@/plugins/i18n";

export class Supplier {
    columns = [];
    id      = 'id';

    fillAbles = [
        [
            'name',
            i18n.t('name'),
            true
        ],
        [
            'kuName',
            i18n.t('kuName'),
            true
        ],
        [
            'arName',
            i18n.t('arName'),
            true
        ],
        [
            'phone',
            i18n.t('phone'),
            true
        ],
        [
            'phone2',
            i18n.t('phone2'),
            true,
            false
        ],
        [
            'address',
            i18n.t('address'),
            true
        ],
        [
            'email',
            i18n.t('email'),
            true
        ],
        [
            'country',
            i18n.t('country'),
            true
        ],
        [
            'city',
            i18n.t('city'),
            true
        ],
        [
            'currentLoan',
            i18n.t('currentLoan'),
            true
        ],
        [
            'salesManager',
            i18n.t('salesManager'),
            true
        ],
        [
            'payType',
            i18n.t('payType'),
            true
        ],
        [
            'innerShippingTime',
            i18n.t('innerShippingTime'),
            true
        ],
        [
            'shippingTime',
            i18n.t('shippingTime'),
            true
        ],
        [
            'profit',
            i18n.t('profit'),
            true
        ],
        [
            'discount',
            i18n.t('discount'),
            true
        ],
        [
            'hasAccount',
            i18n.t('hasAccount'),
            true
        ],
        [
            'note',
            i18n.t('note'),
            true
        ],
        [
            'controls',
            i18n.t('controls'),
            false
        ]
    ];

    getColumns() {
        this.columns = [
            {
                label: 'Id',
                name: 'id',
                sort: false,
                visibility: false,
                uniqueId: true
            },
        ];
        this.fillAbles.map(e => {
            this.columns.push({
                name: e[0],
                label: e[1],
                sort: e[2],
                visibility: e[3] ?? true
            })
        })
        return this.columns;
    }
}
