<template>
  <div>
    <content-header>{{ $t("productTypesListView") }}</content-header>
    <v-content>
      <b-card>
        <b-card-body>
          <div class="row">
            <div class="col-6">
              <h2>{{ $t("departments") }}</h2>
              <hr />
              <div class="items">
                <div
                  class="item"
                  v-for="department in departments"
                  :key="department.id"
                  @click="selectDepartment(department.id)"
                >
                  <div class="row">
                    <div class="col-8">
                      <div class="input-group mb-3">
                        <div
                          class="input-group-prepend"
                          :class="
                            department.id === selectedDepartment
                              ? 'selectedItem'
                              : ''
                          "
                        >
                          <span class="input-group-text">{{
                            department.name
                          }}</span>
                        </div>
                        <input
                          type="file"
                          class="form-control"
                          :id="'department_' + department.id"
                        />
                      </div>
                    </div>
                    <div class="col-4">
                      <img
                        :id="'department_' + department.id + '_image'"
                        :src="getImageUrl(department.attachment)"
                        alt=""
                        width="200"
                        height="200"
                      />
                      <button
                        :disabled="isLoading"
                        class="btn btn-success m-2"
                        @click="updateImage('department_', department.id)"
                      >
                        {{ $t("update") }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <h2>{{ $t("sections") }}</h2>
              <hr />
              <div class="items">
                <div
                  class="item"
                  v-for="section in sections"
                  :key="section.id"
                  @click="selectSection(section.id)"
                >
                  <div class="row">
                    <div class="col-8">
                      <div class="input-group mb-3">
                        <div
                          class="input-group-prepend"
                          :class="
                            section.id === selectedSection ? 'selectedItem' : ''
                          "
                        >
                          <span class="input-group-text">{{
                            section.name
                          }}</span>
                        </div>
                        <input
                          type="file"
                          class="form-control"
                          :id="'section_' + section.id"
                        />
                      </div>
                    </div>
                    <div class="col-4">
                      <img
                        :id="'section_' + section.id + '_image'"
                        :src="getImageUrl(section.attachment)"
                        alt=""
                        width="200"
                        height="200"
                      />
                      <button
                        :disabled="isLoading"
                        class="btn btn-success m-2"
                        @click="updateImage('section_', section.id)"
                      >
                        {{ $t("update") }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr class="m-3" />
          <div class="row">
            <div class="col-6">
              <h2>{{ $t("categories") }}</h2>
              <hr />
              <div class="items">
                <div
                  class="item"
                  v-for="category in categories"
                  :key="category.id"
                  @click="selectCategory(category.id)"
                >
                  <div class="row">
                    <div class="col-8">
                      <div class="input-group mb-3">
                        <div
                          class="input-group-prepend"
                          :class="
                            category.id === selectedCategory
                              ? 'selectedItem'
                              : ''
                          "
                        >
                          <span class="input-group-text">{{
                            category.name
                          }}</span>
                        </div>
                        <input
                          type="file"
                          class="form-control"
                          :id="'category_' + category.id"
                        />
                      </div>
                    </div>
                    <div class="col-4">
                      <img
                        :id="'category_' + category.id + '_image'"
                        :src="getImageUrl(category.attachment)"
                        alt=""
                        width="200"
                        height="200"
                      />
                      <button
                        :disabled="isLoading"
                        class="btn btn-success m-2"
                        @click="updateImage('category_', category.id)"
                      >
                        {{ $t("update") }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <h2>{{ $t("families") }}</h2>
              <hr />
              <div class="items">
                <div class="item" v-for="family in families" :key="family.id">
                  <div class="row">
                    <div class="col-8">
                      <div class="input-group mb-3">
                        <div
                          class="input-group-prepend"
                          :class="family.id === selectedSection ? 'selectedItem' : ''"
                        >
                          <span class="input-group-text">{{ family.name }}</span>
                        </div>
                        <input
                          type="file"
                          class="form-control"
                          :id="'family_' + family.id"
                        />
                      </div>
                    </div>
                    <div class="col-4">
                      <img
                        :id="'family_' + family.id + '_image'"
                        :src="getImageUrl(family.attachment)"
                        alt=""
                        width="200"
                        height="200"
                      />
                      <button
                        :disabled="isLoading"
                        class="btn btn-success m-2"
                        @click="updateImage('family_', family.id)"
                      >
                        {{ $t("update") }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </v-content>
    <is-loading v-if="isLoading" />
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import Swal from "sweetalert2";
import IsLoading from "@/components/IsLoading";

export default {
  name: "ProductTypesListView",
  components: {
    ContentHeader,
    "v-content": Content,
    IsLoading,
  },
  data() {
    return {
      isLoading: false,
      departments: [],
      selectedDepartment: 0,
      sections: [],
      selectedSection: 0,
      categories: [],
      selectedCategory: 0,
      families: [],
      selectedFamily: 0,
    };
  },
  mounted() {
    this.getDepartments();
  },
  methods: {
    getDepartments() {
      let serverParams = {
        _start: 0,
        _end: 50000,
        _sort: "",
        _order: "",
        _search: "",
      };
      this.$store
        .dispatch("productTypes/GET_ALL", serverParams)
        .then((response) => {
          this.departments = response.data;
          this.selectedDepartment = this.departments[0].id;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.getSections();
        });
    },
    selectDepartment(id) {
      this.selectedDepartment = id;
      this.getSections();
    },
    getSections() {
      let serverParams = {
        _start: 0,
        _end: 50000,
        _sort: "",
        _order: "",
        _search: "",
        custom_params: {
          id: this.selectedDepartment,
        },
      };
      this.$store
        .dispatch("productTypes/GET_ALL", serverParams)
        .then((response) => {
          this.sections = response.data;
          this.selectedSection = this.sections[0].id;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          if (this.sections.length > 0) {
            this.getCategories();
          }
        });
    },
    selectSection(id) {
      this.selectedSection = id;
      this.getCategories();
    },
    getCategories() {
      let serverParams = {
        _start: 0,
        _end: 50000,
        _sort: "",
        _order: "",
        _search: "",
        custom_params: {
          id: this.selectedSection,
        },
      };
      this.$store
        .dispatch("productTypes/GET_ALL", serverParams)
        .then((response) => {
          this.categories = response.data;
          this.selectedCategory = this.categories[0].id;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          if (this.categories.length > 0) {
            this.getFamilies();
          }
        });
    },
    selectCategory(id) {
      this.selectedCategory = id;
      this.getFamilies();
    },
    getFamilies() {
      let serverParams = {
        _start: 0,
        _end: 50000,
        _sort: "",
        _order: "",
        _search: "",
        custom_params: {
          id: this.selectedCategory,
        },
      };
      this.$store
        .dispatch("productTypes/GET_ALL", serverParams)
        .then((response) => {
          this.families = response.data;
          this.selectedFamily = this.families[0].id;
        });
    },
    updateImage(type, id) {
      let fileInput = document.getElementById(type + id);
      if (fileInput.files.length > 0) {
        this.isLoading = true;
        let file = fileInput.files[0];
        this.$helpers.changeFileToBase64(file).then((response) => {
          this.$store
            .dispatch("productTypes/UPDATE_IMAGE", {
              attachment: response,
              id: id,
            })
            .then((response) => {
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: this.$t("attachmentChanged"),
                showConfirmButton: false,
                timer: 1500,
              });
              document.getElementById(
                type + id + "_image"
              ).src = this.$helpers.getImageUrl(response.data);
            })
            .finally(() => {
              this.isLoading = false;
            });
        });
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: this.$t("pleaseSelectAttachment"),
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
  },
};
</script>

<style scoped>
.items {
  height: 850px;
  overflow: auto;
}

.item {
  margin-bottom: 20px;
  cursor: pointer;
}

.selectedItem > span {
  color: red;
}
</style>
