<template>
  <div>
    <content-header>{{ $t("createProductType") }}</content-header>
    <v-content>
      <b-card>
        <b-card-body>
          <b-form-group>
            <b-input-group :prepend="$t('csvFile')" class="mb-2 mr-sm-2 mb-sm-0">
              <b-form-textarea id="csvFile" :placeholder="$t('csvFile')"></b-form-textarea>
              <button class="btn btn-outline-primary col-md-3" type="submit" :disabled="isLoading" @click="checkCsv"><i class="fas fa-plus"></i>
                {{ $t('generate') }}
              </button>
            </b-input-group>
          </b-form-group>
          <div class="form-group row">
            <button class="btn btn-outline-primary col-md-3" type="submit" :disabled="isLoading" @click="onSubmit"><i class="fas fa-plus"></i>
              {{ $t('create') }}
            </button>
            <div class="col-md-2">
              <b-form-checkbox
                  id="removeDuplicates"
                  v-model="removeDuplicates"
                  name="removeDuplicates"
              >
                {{ $t('removeDuplicates') }}
              </b-form-checkbox>
            </div>
            <div class="col-md-2">
              <b-form-checkbox
                  id="optionalComma"
                  v-model="optionalComma"
                  name="optionalComma"
              >
                {{ $t('optionalComma') }}
              </b-form-checkbox>
            </div>
          </div>
          <b-form-group>
            <b-input-group :prepend="$t('numberOfTypes')" class="mb-2 mr-sm-2 mb-sm-0">
              <b-form-input :placeholder="$t('numberOfTypes')" v-model="numberOfTypes"></b-form-input>
            </b-input-group>
          </b-form-group>
          <div class="row">
            <div class="col-3">
              <b-form-checkbox
                  id="requiredDepartment"
                  v-model="requiredDepartment"
                  name="requiredDepartment"
              >
                {{ $t('required') }}
              </b-form-checkbox>
<!--              <select-input-->
<!--                  :is-loaded="isLoading"-->
<!--                  id="departments"-->
<!--                  :name="$t('departments')"-->
<!--                  :placeholder="$t('departments')"-->
<!--                  :show-label="false"-->
<!--                  :required="true"-->
<!--                  api-link="productTypes/GET_ALL"-->
<!--                  label="name"-->
<!--                  @itemSelected="parentSelected"-->
<!--              />-->
              <b-form-select v-model="parentId" :options="departments"></b-form-select>
            </div>
            <div class="col-3">
              <b-form-checkbox
                  id="requiredSection"
                  v-model="requiredSection"
                  name="requiredSection"
              >
                {{ $t('required') }}
              </b-form-checkbox>
<!--              <select-input-->
<!--                  :is-loaded="isLoading"-->
<!--                  id="sections"-->
<!--                  :name="$t('sections')"-->
<!--                  :placeholder="$t('sections')"-->
<!--                  :show-label="false"-->
<!--                  :required="true"-->
<!--                  api-link="productTypes/GET_ALL"-->
<!--                  :api-params="{ id: form.parentId }"-->
<!--                  label="name"-->
<!--                  @itemSelected="childSelected"-->
<!--                  :disabled="subParentDisable"-->
<!--              />-->
              <b-form-select v-model="subParentId" :options="sections" :disabled="subParentDisable"></b-form-select>
            </div>
            <div class="col-3">
              <b-form-checkbox
                  id="requiredCategory"
                  v-model="requiredCategory"
                  name="requiredCategory"
              >
                {{ $t('required') }}
              </b-form-checkbox>
<!--              <select-input-->
<!--                  :is-loaded="isLoading"-->
<!--                  id="categories"-->
<!--                  :name="$t('categories')"-->
<!--                  :placeholder="$t('categories')"-->
<!--                  :show-label="false"-->
<!--                  :required="true"-->
<!--                  api-link="productTypes/GET_ALL"-->
<!--                  :api-params="{ id: form.subParentId }"-->
<!--                  label="name"-->
<!--                  @itemSelected="(e) => (form.childId = e)"-->
<!--                  :disabled="childDisable"-->
<!--              />-->
              <b-form-select v-model="childId" :options="categories" :disabled="childDisable"></b-form-select>
            </div>
            <div class="col-3">
              <b-form-checkbox
                  disabled
                  id="requiredFamily"
                  v-model="requiredFamily"
                  name="requiredFamily"
              >
                {{ $t('required') }}
              </b-form-checkbox>
              <b-form-select :options="families" :disabled="subChildDisable"></b-form-select>
            </div>
          </div>
          <div class="row my-3" v-for="type in parseInt(numberOfTypes)" :key="type">
            <div class="col-4">
              <b-input-group :prepend="$t('kuName')">
                <b-form-input type="text" :id="'name_ku_' + type" :placeholder="$t('kuName')" @focus="$event.target.select()"></b-form-input>
              </b-input-group>
            </div>
            <div class="col-4">
              <b-input-group :prepend="$t('arName')">
                <b-form-input type="text" :id="'name_ar_' + type" :placeholder="$t('arName')" @focus="$event.target.select()"></b-form-input>
              </b-input-group>
            </div>
            <div class="col-4">
              <b-input-group :prepend="$t('name')">
                <b-form-input type="text" :id="'name_en_' + type" :placeholder="$t('name')" @focus="$event.target.select()"></b-form-input>
              </b-input-group>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-2 col-form-label"> </label>
            <button class="btn btn-outline-primary col-md-3" type="submit" :disabled="isLoading" @click="onSubmit"><i class="fas fa-plus"></i>
              {{ $t('create') }}
            </button>
          </div>
          <is-loading v-if="isLoading" />
        </b-card-body>
      </b-card>
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import IsLoading from "@/components/IsLoading";
import { ProductType } from "@/models/ProductType";
import {swalMixin} from "@/plugins/constants";
import Swal from "sweetalert2";

export default {
  name: "ProductTypesCreate",
  components: {
    ContentHeader,
    "v-content": Content,
    IsLoading,
  },
  data() {
    return {
      form: this.$helpers.convertArrayToObject(new ProductType().fillAbles),
      isLoading: false,
      parentId: 0,
      subParentDisable: true,
      subParentId: 0,
      childDisable: true,
      childId: 0,
      subChildDisable: true,
      numberOfTypes: 50,
      removeDuplicates: false,
      optionalComma: false,
      departments: [],
      sections: [],
      categories: [],
      families: [],
      requiredDepartment: true,
      requiredSection: false,
      requiredCategory: false,
    };
  },
  watch: {
    parentId: {
      handler: function() {
        this.subParentDisable = false;
        this.getSections();
      },
      deep: true
    },
    subParentId: {
      handler: function() {
        this.childDisable = false;
        this.getCategories();
      },
      deep: true
    },
    childId: {
      handler: function() {
        this.subChildDisable = false;
        this.getFamilies();
      },
      deep: true
    }
  },
  mounted() {
    this.getDepartments();
  },
  methods: {
    onSubmit() {
      if (this.requiredDepartment && (this.parentId === 0 || this.parentId === null || this.parentId === "" || this.parentId === [])) {
        swalMixin('error', this.$t('departmentRequired'));
        return;
      }
      if (this.requiredSection && (this.subParentId === 0 || this.subParentId === null || this.subParentId === "" || this.subParentId === [])) {
        swalMixin('error', this.$t('sectionRequired'));
        return;
      }
      if (this.requiredCategory && (this.childId === 0 || this.childId === null || this.childId === "" || this.childId === [])) {
        swalMixin('error', this.$t('categoryRequired'));
        return;
      }
      this.isLoading = true;
      let parentId =
        this.childId !== 0
          ? this.childId
          : this.subParentId !== 0
            ? this.subParentId
            : this.parentId;

      let customData = [];
      for (let i = 1; i < parseInt(this.numberOfTypes) + 1; i++) {
        if (
            document.getElementById("name_ku_" + i).value !== "" &&
            document.getElementById("name_ar_" + i).value !== "" &&
            document.getElementById("name_en_" + i).value !== ""
        ) {
          customData.push({
            kuName: document.getElementById("name_ku_" + i).value,
            arName: document.getElementById("name_ar_" + i).value,
            name: document.getElementById("name_en_" + i).value,
            parentId: parentId
          });
        }
      }

      this.$store
        .dispatch("productTypes/CREATE_BATCH", customData)
        .then(() => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: this.$t("addedSuccessfully"),
            showConfirmButton: false,
            timer: 1500,
          });
          document.getElementById("csvFile").value = ""
          this.clearInputs();
        })
        .catch((error) => {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: error.response?.data?.message ?? error.response?.data,
            showConfirmButton: false,
            timer: 1500,
          });
        })
          .finally(() => {
            this.isLoading = false;
            console.log(this.subParentId)
          });
    },
    getDepartments() {
      let serverParams = {
        _start: 0,
        _end: 50000,
        _sort: "sort",
        _order: "ASC",
        _search: "",
      };
      this.$store
          .dispatch("productTypes/GET_ALL", serverParams)
          .then((response) => {
            this.departments = response.data.map((item) => {
              return {
                text: item['name'],
                value: item['id']
              }
            });
          })
          .catch((error) => {
            console.log(error);
          })
    },
    getSections() {
      let serverParams = {
        _start: 0,
        _end: 50000,
        _sort: "sort",
        _order: "ASC",
        _search: "",
        custom_params: {
          id: this.parentId
        }
      };
      this.$store
          .dispatch("productTypes/GET_ALL", serverParams)
          .then((response) => {
            this.sections  = response.data.map((item) => {
              return {
                text: item['name'],
                value: item['id']
              }
            });
          })
          .catch((error) => {
            console.log(error);
          })
    },
    getCategories() {
      let serverParams = {
        _start: 0,
        _end: 50000,
        _sort: "sort",
        _order: "ASC",
        _search: "",
        custom_params: {
          id: this.subParentId
        }
      };
      this.$store
          .dispatch("productTypes/GET_ALL", serverParams)
          .then((response) => {
            this.categories  = response.data.map((item) => {
              return {
                text: item['name'],
                value: item['id']
              }
            });
          })
          .catch((error) => {
            console.log(error);
          })
    },
    getFamilies() {
      let serverParams = {
        _start: 0,
        _end: 50000,
        _sort: "sort",
        _order: "ASC",
        _search: "",
        custom_params: {
          id: this.childId
        }
      };
      this.$store
          .dispatch("productTypes/GET_ALL", serverParams)
          .then((response) => {
            this.families = response.data.map((item) => {
              return {
                text: item['name'],
                value: item['id']
              }
            });
          })
          .catch((error) => {
            console.log(error);
          })
    },
    async parentSelected(e) {
      this.childId = 0;
      this.subParentDisable = true;
      this.subParentId = 0;
      this.parentId = await e;
      this.subParentDisable = false;
    },
    async childSelected(e) {
      this.childDisable = true;
      this.childId = 0;
      this.subParentId = await e;
      this.childDisable = false;
    },
    async subChildSelected(e) {
      this.subChildDisable = true;
      this.childId = await e;
      this.subChildDisable = false;
    },
    checkCsv() {
      if (document.getElementById("csvFile").value !== "") {
        this.clearInputs();
        let regex = !this.optionalComma ? /[\t\n,]+/ : /[\t\n]+/;
        let csvText = document.getElementById("csvFile").value.split(regex);
        let arr = 0;
        for (let i = 1; i < parseInt(this.numberOfTypes) + 1; i++) {
          if(this.removeDuplicates) {
            if (csvText[arr - 3] === csvText[arr]) {
              arr += 3;
              continue;
            }
          }
          document.getElementById("name_ku_" + i).value = csvText[arr++] ?? '';
          document.getElementById("name_ar_" + i).value = csvText[arr++] ?? '';
          document.getElementById("name_en_" + i).value = csvText[arr++] ?? '';
        }
      }
    },
    clearInputs() {
      for (let i = 1; i < parseInt(this.numberOfTypes) + 1; i++) {
        document.getElementById("name_ku_" + i).value = "";
        document.getElementById("name_ar_" + i).value = "";
        document.getElementById("name_en_" + i).value = "";
      }
    }
  },
};
</script>
