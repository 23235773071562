import {HTTP} from "@/plugins/axios";
import axios from "axios";
import router from "@/router";

const auth = {
    namespaced: true,
    state: {
        isLoggedIn: false,
        token: localStorage.getItem(process.env.VUE_APP_TOKEN_NAME) || '',
        userInfo : {}
    },
    mutations: {
        SET_TOKEN (state, value) {
            state.token = value
        },
        SET_IS_LOGGED_IN (state, value) {
            state.isLoggedIn = value
        },
        SET_USER_INFO (state, value) {
            state.userInfo = value
        }
    },
    actions: {
        LOGIN({commit}, auth) {
            return new Promise((resolve, reject) => {
                axios.post(`${process.env.VUE_APP_BASE_API_URL}/Accounts/Login`, auth)
                    .then((response) => {
                        let token = response.data.token;
                        let userInfo = {
                            userName: response.data.userName,
                            userFirstName: response.data.email
                        }
                        localStorage.setItem(process.env.VUE_APP_TOKEN_NAME, token)
                        localStorage.setItem('userInfo', JSON.stringify(userInfo))
                        HTTP.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token
                        commit('SET_TOKEN', token)
                        commit('SET_IS_LOGGED_IN', true)
                        commit('SET_USER_INFO', userInfo)
                        localStorage.setItem('code', response.data.code)
                        localStorage.setItem('secondCode', response.data.secondCode)
                        resolve(response)
                    })
                    .catch((error) => {
                        localStorage.removeItem(process.env.VUE_APP_TOKEN_NAME)
                        delete HTTP.defaults.headers.common['Authorization']
                        localStorage.removeItem('userInfo')
                        commit('SET_TOKEN', '')
                        commit('SET_IS_LOGGED_IN', false)
                        commit('SET_USER_INFO', {})
                        localStorage.removeItem('code')
                        localStorage.removeItem('secondCode')
                        reject(error)
                    });
            })
        },  
        LOGOUT({commit}) {
            return new Promise((resolve, reject) => {
                axios.post(`${process.env.VUE_APP_BASE_API_URL}/Accounts/LogOut`, {}, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem(process.env.VUE_APP_TOKEN_NAME)}`,
                    }
                })
                    .then((response) => {
                        localStorage.removeItem(process.env.VUE_APP_TOKEN_NAME)
                        delete HTTP.defaults.headers.common['Authorization']
                        localStorage.removeItem('userInfo')
                        localStorage.removeItem('code')
                        localStorage.removeItem('secondCode')
                        commit('SET_TOKEN', '')
                        commit('SET_IS_LOGGED_IN', false)
                        commit('SET_USER_INFO', {})
                        resolve(response)
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            localStorage.removeItem(process.env.VUE_APP_TOKEN_NAME)
                            delete HTTP.defaults.headers.common['Authorization']
                            localStorage.removeItem('userInfo')
                            localStorage.removeItem('code')
                            localStorage.removeItem('secondCode')
                            commit('SET_TOKEN', '')
                            commit('SET_IS_LOGGED_IN', false)
                            commit('SET_USER_INFO', {})
                            router.push({ name: 'Login' })
                        }
                        reject(error)
                    });
            })
        }
    },
    getters : {
        token: state => state.token,
        isLoggedIn: state => state.isLoggedIn,
        userInfo: state => state.userInfo
    }
}

export default auth;
