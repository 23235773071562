import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import mixin from "@/mixins";

const featuresToProduct = {
    namespaced: true,
    state: {
        featuresToProduct: {},
        getOneFeatureToProduct: {},
        createdFeaturesToProduct: {},
        updatedFeatureToProduct: {},
    },
    mutations: {
        SET_FEATURES_TO_PRODUCT (state, value) { state.featuresToProduct = value },
        SET_GET_ONE_FEATURES_TO_PRODUCT (state, value) {state.getOneFeatureToProduct = value},
        SET_CREATED_FEATURES_TO_PRODUCT (state, value) {state.createdFeaturesToProduct = value},
        SET_UPDATED_FEATURES_TO_PRODUCT (state, value) {state.updatedFeatureToProduct = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/Products/ProductFeature` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}`)
                    .then((response) => {
                        commit('SET_FEATURES_TO_PRODUCT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_FEATURES_TO_PRODUCT', {})
                        reject(error)
                    });
            })
        },
        GET_ONE({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/Products/ProductFeature/${id}`)
                    .then((response) => {
                        commit('SET_GET_ONE_FEATURES_TO_PRODUCT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GET_ONE_FEATURES_TO_PRODUCT', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.post(`/Products/ProductFeature`, form)
                    .then((response) => {
                        commit('SET_CREATED_FEATURES_TO_PRODUCT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_FEATURES_TO_PRODUCT', {})
                        reject(error)
                    });
            })
        },
        UPDATE({commit}, {id, form}) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.put(`/Products/ProductFeature/${id}`, form)
                    .then((response) => {
                        commit('SET_UPDATED_FEATURES_TO_PRODUCT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_UPDATED_FEATURES_TO_PRODUCT', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/Products/ProductFeature/${ids}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        featuresToProduct: state => state.featuresToProduct,
        getOneFeatureToProduct: state => state.getOneFeatureToProduct,
        createdFeaturesToProduct: state => state.createdFeaturesToProduct,
        updatedFeatureToProduct: state => state.updatedFeatureToProduct,
    }
}

export default featuresToProduct;
