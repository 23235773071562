<template>
  <div class="form-group row">
    <label v-if="showLabel" :for="id" class="col-sm-2 col-form-label text-right">{{ name }}</label>
    <div class="col-sm-10">
      <VueToggles
          :value="defaultSwitch"
          :checkedText="checkedLabel"
          :uncheckedText="unCheckedLabel"
          :checkedBg="checkedBg"
          :uncheckedBg="uncheckedBg"
          @click="switchChange"
          :width="87"
          :height="30"
          :font-size="17"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "BooleanInput",
  props: {
    id: {
      type: String
    },
    name: {},
    showLabel: {
      type: Boolean,
      default: true
    },
    defaultValue: {
      type: Boolean
    },
    checkedBg: {
      type: String,
      default: "#007BFF"
    },
    uncheckedBg: {
      type: String,
      default: "#8a8a8a"
    },
    checkedLabel: {},
    unCheckedLabel: {},
    loadOnMount: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    if (this.loadOnMount) {
      this.$emit('switched', this.defaultValue);
    }
  },
  watch: {
    defaultValue: function(value) {
      this.defaultSwitch = value
    }
  },
  data() {
    return {
      defaultSwitch: this.defaultValue
    }
  },
  methods: {
    switchChange() {
      this.$emit('switched', !this.defaultSwitch);
      this.defaultSwitch = !this.defaultSwitch;
    }
  }
}
</script>
