import {HTTP} from "@/plugins/axios";
import mixin from "@/mixins";

const supplierAttachments = {
    namespaced: true,
    state: {
        createdSupplierAttachment: {},
    },
    mutations: {
        SET_CREATED_SUPPLIER_ATTACHMENT (state, value) {state.createdSupplierAttachment = value},
    },
    actions: {
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.post(`/Suppliers/SupplierAttachment`, form)
                    .then((response) => {
                        commit('SET_CREATED_SUPPLIER_ATTACHMENT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_SUPPLIER_ATTACHMENT', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/Suppliers/SupplierAttachment/${ids}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
        TOGGLE_DATA(commit, data) {
            return new Promise((resolve, reject) => {
                HTTP.post(`/Suppliers/ToggleData${data}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        createdSupplierAttachment: state => state.createdSupplierAttachment,
    }
}

export default supplierAttachments;
