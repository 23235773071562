import { i18n } from "@/plugins/i18n";

export class Invoice {
  columns = [];
  id = "id";

  fillAbles = [
    ["inventoryType", i18n.t("inventoryType"), true],
    ["invoiceNumber", i18n.t("invoiceNumber"), true],
    ["address", i18n.t("address"), true],
    ["date", i18n.t("date"), true],
    ["totalPrice", i18n.t("totalPrice"), true],
    ["invoiceDiscount", i18n.t("invoiceDiscount"), true],
    ["finalPrice", i18n.t("finalPrice"), true],
    ["totalQty", i18n.t("totalQty"), true],
  ];

  getColumns() {
    this.columns = [
      {
        label: "Id",
        name: "id",
        sort: false,
        visibility: false,
        uniqueId: true,
      },
    ];
    this.fillAbles.map((e) => {
      this.columns.push({
        name: e[0],
        label: e[1],
        sort: e[2],
        visibility: e[3] ?? true,
      });
    });
    return this.columns;
  }
}
