<template>
  <b-row>
    <b-col lg="3" md="4" sm="6" v-for="attachment in allAttachments" :key="attachment.id">
      <b-card img-top style="max-width: 20rem;">
        <b-link :href="getImageUrl(attachment.attachment)" target="_blank">
          <b-card-img :src="getImageUrl(attachment.attachment)" :img-alt="attachment.name" width="200" height="200" class="mb-2"/>
        </b-link>
        <boolean-input
            :show-label="false"
            id="shareable"
            :name="$t('shareable')"
            :load-on-mount="false"
            :default-value="attachment.shareable"
            :checked-label="$t('share')"
            :un-checked-label="$t('no')"
            label-class="col-md-4"
            input-class="col-md-8"
            @switched="(e) => switched(e, attachment.id, 'shareable')"
        />
        <b-button href="#" variant="danger" @click.prevent="deleteAttachment(attachment.id)">{{ $t('delete') }}</b-button>
      </b-card>
    </b-col>
    <is-loading v-if="isLoading" />
  </b-row>
</template>

<script>
import IsLoading from "@/components/IsLoading";
import {swalMixin} from "@/plugins/constants";
import BooleanInput from "@/components/Inputs/BooleanInput";

export default {
  name: "SupplierAttachmentsList",
  components: {BooleanInput, IsLoading},
  props: {
    attachments: {
      type: Array
    }
  },
  watch: {
    attachments: function (value) {
      this.allAttachments = value
    }
  },
  data() {
    return {
      allAttachments: this.attachments,
      url: process.env.VUE_APP_BASE_URL,
      isLoading: false
    }
  },
  methods: {
    switched(value, id, name) {
      value = true;
      this.$store.dispatch('supplierAttachments/TOGGLE_DATA', `?id=${id}&${name}=${value}`)
    },
    deleteAttachment(id) {
      this.$store.dispatch('supplierAttachments/DELETE', id).then(() => {
        swalMixin('success', this.$t('deletedSuccessfully'))
        this.$emit('loadAll')
      })
    }
  }
}
</script>
