import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import mixin from "@/mixins";
import GroupTypes from "@/enums/GroupTypes";
import ProductAttributes from "@/enums/ProductAttributes";

const ads = {
    namespaced: true,
    state: {
        ads: {},
        createdAds: {},
        getOneAds: {},
        updatedAds: {},
    },
    mutations: {
        SET_ADS (state, value) { state.ads = value },
        SET_CREATED_ADS (state, value) {state.createdAds = value},
        SET_GET_ONE_ADS (state, value) {state.getOneAds = value},
        SET_UPDATED_ADS (state, value) {state.updatedAds = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/Main` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}` +
                        `&objectName=${ProductAttributes.Group}&groupType=${GroupTypes.Ads}`)
                    .then((response) => {
                        commit('SET_ADS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_ADS', {})
                        reject(error)
                    });
            })
        },
        GET_ONE({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/Main/${id}?objectName=${ProductAttributes.Group}`)
                    .then((response) => {
                        commit('SET_GET_ONE_ADS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GET_ONE_ADS', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.post(`/Main?objectName=${ProductAttributes.Group}`, form)
                    .then((response) => {
                        commit('SET_CREATED_ADS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_ADS', {})
                        reject(error)
                    });
            })
        },
        UPDATE({commit}, {id, form}) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.put(`/Main/${id}?objectName=${ProductAttributes.Group}`, form)
                    .then((response) => {
                        commit('SET_UPDATED_ADS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_UPDATED_ADS', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/Main/${ids}?objectName=${ProductAttributes.Group}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        ads: state => state.ads,
        createdAds: state => state.createdAds,
        getOneAds: state => state.getOneAds,
        updatedAds: state => state.updatedAds,
    }
}

export default ads;
