import {HTTP} from "@/plugins/axios";
import mixin from "@/mixins";

const customerAttachments = {
    namespaced: true,
    state: {
        createdCustomerAttachment: {},
    },
    mutations: {
        SET_CREATED_CUSTOMER_ATTACHMENT (state, value) {state.createdCustomerAttachment = value},
    },
    actions: {
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.post(`/Customers/CustomerAttachment`, form)
                    .then((response) => {
                        commit('SET_CREATED_CUSTOMER_ATTACHMENT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_CUSTOMER_ATTACHMENT', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/Customers/CustomerAttachment/${ids}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
        TOGGLE_DATA(commit, data) {
            return new Promise((resolve, reject) => {
                HTTP.post(`/Customers/ToggleData${data}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        createdCustomerAttachment: state => state.createdCustomerAttachment,
    }
}

export default customerAttachments;
