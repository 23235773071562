import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import mixin from "@/mixins";

const productTypeUser = {
    namespaced: true,
    state: {
        productTypeUser: {},
        createdProductTypeUser: {},
    },
    mutations: {
        SET_PRODUCT_TYPE_USER (state, value) { state.productTypeUser = value },
        SET_CREATED_PRODUCT_TYPE_USER (state, value) {state.createdProductTypeUser = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/Utilities/GetProductTypeUser` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}`)
                    .then((response) => {
                        commit('SET_PRODUCT_TYPE_USER', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_PRODUCT_TYPE_USER', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.post(`/Utilities/AddProductTypeUser`, form)
                    .then((response) => {
                        commit('SET_CREATED_PRODUCT_TYPE_USER', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_PRODUCT_TYPE_USER', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/Utilities/DeleteProductTypeUser/${ids}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        productTypeUser: state => state.productTypeUser,
        createdProductTypeUser: state => state.createdProductTypeUser,
    }
}

export default productTypeUser;
