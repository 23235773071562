import Swal from "sweetalert2";
import store from '@/store';
import lodash, {isInteger} from 'lodash';
import {i18n} from "./i18n";

export const serverParams = {
    _start: 0,
    _end: 10,
    _sort: '',
    _order: '',
    _search: '',
    custom_params: ''
}

export const tableConfigure = (name = null, globalSearch = true, showCheckbox = false) => {
    return {
        card_title: name,
        server_mode: true,
        per_page_options: pagination,
        checkbox_rows: showCheckbox,
        show_refresh_button: false,
        show_reset_button: false,
        global_search: {
            placeholder: i18n.t('search'),
            visibility: globalSearch,
            case_sensitive: false
        }
    };
}

export const pagination = [5, 10, 20, 50];

export const resolveServerParams = (serverParam) => {
    if (serverParam._start !== 0 && isInteger(serverParam._start)) {
        serverParam._start = `?start=${serverParam._start}`;
    } else {
        serverParam._start = `?start=0`;
    }
    if (serverParam._end !== 0 && isInteger(serverParam._end)) {
        serverParam._end = `&end=${serverParam._end}`;
    } else {
        serverParam._end = `&end=10`;
    }
    if (serverParam._sort !== '') {
        serverParam._sort = `&sort=${serverParam._sort}`;
    } else {
        serverParam._sort = ``;
    }
    if (serverParam._order !== '') {
        serverParam._order = `&order=${serverParam._order}`;
    } else {
        serverParam._order = ``;
    }
    if (serverParam._search !== '') {
        serverParam._search = `&search=${serverParam._search}`;
    } else {
        serverParam._search = ``;
    }
    if (serverParam.custom_params !== '' && serverParam.custom_params !== undefined) {
        lodash.forOwn(serverParam.custom_params, (value, key) => {
            if (value !== '' && value !== undefined && value !== null) {
                serverParam.custom_params = `&${key}=${value}`;
            } else {
                serverParam.custom_params = ``;
            }
        })
    } else {
        serverParam.custom_params = '';
    }
    return serverParam;
}

export const tableActions = (createBtn, multipleDeleteBtn, multipleRestoreBtn, multipleForceDeleteBtn, exportBtn) => {
    let buttons = [];
    if (createBtn) {
        buttons.push({
            btn_text: "<i class='fal fa-plus-circle'></i> " + i18n.t('create'),
            event_name: "on-create",
            class: "btn btn-outline-primary"
        })
    }
    if (multipleDeleteBtn) {
        buttons.push({
            btn_text: "<i class='fal fa-trash-alt'></i> " + i18n.t('multipleDelete'),
            event_name: "on-multiple-delete",
            class: "btn btn-outline-danger"
        })
    }
    if (multipleRestoreBtn) {
        buttons.push({
            btn_text: "<i class='fal fa-trash-restore-alt'></i> " + i18n.t('multipleRestore'),
            event_name: "on-multiple-restore",
            class: "btn btn-outline-success"
        })
    }
    if (multipleForceDeleteBtn) {
        buttons.push({
            btn_text: "<i class='fal fa-times'></i> " + i18n.t('multipleForceDelete'),
            event_name: "on-multiple-force-delete",
            class: "btn btn-outline-danger"
        })
    }
    if (exportBtn) {
        buttons.push({
            btn_text: "<i class='fal fa-cloud-download'></i> " + i18n.t('export'),
            event_name: "on-export",
            class: "btn btn-outline-info"
        })
    }
    return buttons;
};

export const swalMixin = (icon, title, timer = 1000, position = 'top-end') => {
    if (title === 'noRows') {
        title = i18n.t('noRowsSelected')
    }
    if (title === 'wrong') {
        title = i18n.t('someThingWentWrong')
    }
    const Toast = Swal.mixin({
        toast: true,
        position: position,
        showConfirmButton: false,
        timer: timer
    });
    Toast.fire({
        icon: icon,
        title: title
    })
}

export const actions = async (action, selectedIds, type) => {
    if (selectedIds.length === 0) {
        swalMixin('error', 'noRows')
        return false;
    }
    let convertedType = type.charAt(0).toUpperCase() + type.slice(1)
    let val = false;
    await Swal.fire({
        title: i18n.t('areYouSure'),
        text: i18n.t(`youWantTo${convertedType}PermanentlySelectedRows`),
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: i18n.t('noCancel'),
        confirmButtonText: i18n.t(`yes${convertedType}`)
    }).then(async (result) => {
        if (result.value) {
            if (typeof selectedIds === 'object') {
                let onlyIds = selectedIds.map(x => x.id);
                onlyIds.map(async (id) => {
                    await store.dispatch(action, id)
                        .then(() => {
                            Swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: i18n.t(`yourDataHasBeen${convertedType}dPermanently`),
                                showConfirmButton: false,
                                timer: 1000
                            });
                            val = true;
                        }).catch(() => {
                            val = false;
                        })
                })
            } else {
                await store.dispatch(action, selectedIds)
                    .then(() => {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: i18n.t(`yourDataHasBeen${convertedType}dPermanently`),
                            showConfirmButton: false,
                            timer: 1000
                        });
                        val = true;
                    }).catch(() => {
                        val = false;
                    })
            }
        }
    });
    return val;
}

export const convertIds = (selectedRows) => {
    let id = '';
    if (typeof selectedRows === 'object') {
        let onlyIds = selectedRows.map(x => x.id);
        id = `?ids[]=${onlyIds[0]}`;
        onlyIds.shift();
        onlyIds.map(item => {
            id += `&ids[]=` + item;
        })
    } else {
        id = `?ids=${selectedRows}`;
    }
    return id;
}
