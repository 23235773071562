<template>
  <div>
    <div>
      <content-header>{{ $t('createColorsToProduct') }}</content-header>
      <v-content>
        <b-card>
          <b-card-body>
            <form class="forms-sample" @submit.prevent="onSubmit">
              <select-input
                  id="color"
                  :name="$t('color')"
                  :validate="$v"
                  :required="true"
                  api-link="productColors/GET_ALL"
                  label="name"
                  @itemSelected="e => form.colorId = e"
              />
              <number-input id="extraPrice" :name="$t('extraPrice')" :validate="$v" :default-value="0" :is-loading="isLoading" />
              <file-input
                  id="attachment"
                  :required="true"
                  :name="$t('Attachment')"
                  @fileAdded="base64File => form.attachment = base64File"
              />
              <submit-input :name="$t('create')" :disabled="isLoading"/>
            </form>
          </b-card-body>
        </b-card>
      </v-content>
    </div>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import {ColorToProduct} from "@/models/ColorToProduct";
import SelectInput from "@/components/Inputs/SelectInput";
import SubmitInput from "@/components/Inputs/SubmitInput";
import NumberInput from "@/components/Inputs/NumberInput";
import FileInput from "@/components/Inputs/FileInput";

export default {
  name: "ColorsToProductCreate",
  components: {FileInput, NumberInput, SubmitInput, SelectInput, ContentHeader, 'v-content': Content},
  data() {
    return {
      form: this.$helpers.convertArrayToObject(new ColorToProduct().fillAbles),
      isLoading: false
    }
  },
  validations: {
    form: {
      extraPrice: {}
    }
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return false;
      this.form.productId = this.$route.params.id;
      this.isLoading = true;
      this.$store.dispatch('colorsToProduct/CREATE', this.form).then(() => {
        this.$v.$reset();
        this.$router.push({name: 'products.edit', params: {id: this.$route.params.id}})
      }).catch(() => this.isLoading = false);
    },
  }
}
</script>
