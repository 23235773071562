<template>

  <div>
    <content-header>{{ $t('editStock') }}</content-header>
    <v-content>
      <b-card>
        <b-card-body>
          <select-input
              :is-loaded="isLoading"
              id="products"
              :name="$t('products')"
              :placeholder="$t('products')"
              validate="$v"
              :required="true"
              api-link="products/GET_ALL"
              label="name"
              @itemSelected="productSelected"
          />
          <div class="row">
            <div class="col-1">{{ $t('id') }}</div>
            <div class="col-1">{{ $t('colors') }}</div>
            <div class="col-1">{{ $t('diskSize') }}</div>
            <div class="col-1">{{ $t('ramSize') }}</div>
            <div class="col-2">{{ $t('screenSize') }}</div>
            <div class="col-2">{{ $t('qty') }}</div>
            <div class="col-2">{{ $t('price') }}</div>
            <div class="col-2">{{ $t('update') }}</div>
          </div>
          <div class="colors" v-for="stockProduct in stockProducts" :key="stockProduct.id">
            <div class="row">
              <div class="col-1">{{ stockProduct.id }}</div>
              <div class="col-1">{{ stockProduct.colorName }}</div>
              <div class="col-1">{{ stockProduct.diskSizeName }}</div>
              <div class="col-1">{{ stockProduct.ramSizeName }}</div>
              <div class="col-2">{{ stockProduct.screenSizeName && stockProduct.screenSizeName }}</div>
              <div class="col-2">
                <div class="form-group row">
                  <div class="col-sm-12">
                    <input
                        readonly
                        :id="'qty_' + stockProduct.colorId + '_' + stockProduct.diskSizeId + '_' + stockProduct.ramSizeId + '_' + stockProduct.screenSizeId"
                        :disabled="isLoading"
                        :placeholder="$t('qty')"
                        type="number"
                        autocomplete="off"
                        class="form-control"
                        @focus="$event.target.select()"
                        v-on:keypress="e => $helpers.isNumeric(e)"
                        step=".001"
                        :value="stockProduct.qty"
                    />
                  </div>
                </div>
              </div>
              <div class="col-2">
                <div class="form-group row">
                  <div class="col-sm-12">
                    <input
                        :id="'price_' + stockProduct.colorId + '_' + stockProduct.diskSizeId + '_' + stockProduct.ramSizeId + '_' + stockProduct.screenSizeId"
                        :disabled="isLoading"
                        :placeholder="$t('price')"
                        type="number"
                        autocomplete="off"
                        class="form-control"
                        @focus="$event.target.select()"
                        v-on:keypress="e => $helpers.isNumeric(e)"
                        step=".001"
                        :value="stockProduct.price"
                    />
                  </div>
                </div>
              </div>
              <div class="col-2">
                <button
                    class="btn btn-primary m-2"
                    @click="updateInStock(stockProduct.id, productId, stockProduct.colorId, stockProduct.diskSizeId, stockProduct.ramSizeId, stockProduct.screenSizeId)"
                >
                  {{ $t('update') }}
                </button>
                <button
                    class="btn btn-danger m-2"
                    @click="deleteInStock(stockProduct.id)"
                >
                  {{ $t('delete') }}
                </button>
              </div>
            </div>
          </div>

          <submit-input :name="$t('create')" :disabled="isLoading"/>
          <is-loading v-if="isLoading" />
        </b-card-body>
      </b-card>
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import IsLoading from "@/components/IsLoading";
import SubmitInput from "@/components/Inputs/SubmitInput";
import {Invoice} from "@/models/Invoice";
import SelectInput from "@/components/Inputs/SelectInput";
import {swalMixin} from "@/plugins/constants";
import Swal from "sweetalert2";
import {i18n} from "@/plugins/i18n";

export default {
  name: "StockProductEdit",
  components: {SelectInput, SubmitInput, ContentHeader, 'v-content': Content, IsLoading},
  data() {
    return {
      form: new Invoice(),
      isLoading: false,
      productId: 0,
      diskSizes: [],
      ramSizes: [],
      colors: [],
      screenSizes: [],
      stockProducts: []
    }
  },
  methods: {
    productSelected(e) {
      this.productId = e;
      this.isLoading = true;
      this.$store.dispatch('products/GET_PRODUCT_BY_ID_STOCK', e).then((response) => {
        this.stockProducts  = response.data;
        this.isLoading    = false;
      }).catch(() => this.isLoading = false);
    },
    findInStock(product, colorId, diskSizeId, ramSizeId, screenSizeId) {
      let filter = {product, colorId, diskSizeId, ramSizeId, screenSizeId}
      this.$store.dispatch('products/GET_PRODUCT_PRICE_ID_STOCK', filter).then((response) => {
        document.getElementById('qty_' + colorId + '_' + diskSizeId + '_' + ramSizeId + '_' + screenSizeId).value = response.data.qty;
        document.getElementById('price_' + colorId + '_' + diskSizeId + '_' + ramSizeId + '_' + screenSizeId).value = response.data.price;
        this.isLoading    = false;
      }).catch(() => this.isLoading = false);
    },
    updateInStock(id, product, colorId, diskSizeId, ramSizeId, screenSizeId) {
      let filter = {
        id,
        price: document.getElementById('price_' + colorId + '_' + diskSizeId + '_' + ramSizeId + '_' + screenSizeId).value
      }
      this.$store.dispatch('products/EDIT_PRODUCT_STOCK', filter).then(() => {
        this.isLoading    = false;
        swalMixin('success', this.$t('updatedSuccessfully'))
      }).catch(() => this.isLoading = false);
    },
    deleteInStock(id) {
      Swal.fire({
        title: i18n.t('areYouSure'),
        text: i18n.t(`youWantToDeletePermanentlySelectedRows`),
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: i18n.t('noCancel'),
        confirmButtonText: i18n.t(`yesDelete`)
      }).then(async (result) => {
        if (result.value) {
          this.$store.dispatch('products/DELETE_PRODUCT_STOCK', id).then(() => {
            this.isLoading    = false;
            swalMixin('success', this.$t('deletedSuccessfully'))
          }).catch(() => this.isLoading = false);
        }
      });
    }
  }
}
</script>
