<template>
  <div>
    <b-button
        variant="outline-primary"
        class="float-right"
        @click.prevent="$router.push({ name: 'contract.insurances.create', params: {id: $route.params.id} })"
    >
      <i class="fas fa-plus-circle"></i> {{ $t('create') }}
    </b-button>
    <table class="table table-bordered table-striped">
      <thead>
      <tr>
        <th>{{ $t('firstName') }}</th>
        <th>{{ $t('middleName') }}</th>
        <th>{{ $t('lastName') }}</th>
        <th>{{ $t('address') }}</th>
        <th>{{ $t('phone') }}</th>
        <th>{{ $t('phone2') }}</th>
        <th>{{ $t('from') }}</th>
        <th>{{ $t('dob') }}</th>
        <th>{{ $t('isMale') }}</th>
        <th>{{ $t('note') }}</th>
        <th>{{ $t('attachment') }}</th>
        <th>{{ $t('remove') }}</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="contractInsurance in allContractInsurances" :key="contractInsurance.id">
        <td>{{ contractInsurance.firstName }}</td>
        <td>{{ contractInsurance.middleName }}</td>
        <td>{{ contractInsurance.lastName }}</td>
        <td>{{ contractInsurance.address }}</td>
        <td>{{ contractInsurance.phone }}</td>
        <td>{{ contractInsurance.phone2 }}</td>
        <td>{{ contractInsurance.from }}</td>
        <td>{{ contractInsurance.dob }}</td>
        <td>{{ contractInsurance.isMale }}</td>
        <td>{{ contractInsurance.note }}</td>
        <td><ShowAttachment :attachment="contractInsurance.attachment" /></td>
        <td>
          <button class="btn btn-danger btn-sm" @click="removeContractInsurance(contractInsurance.id)">
            <b-icon icon="trash" size="is-small"></b-icon>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import {i18n} from "@/plugins/i18n";
import ShowAttachment from "@/components/ShowAttachment";

export default {
  name: "ContractInsuranceList",
  components: {ShowAttachment},
  props: {
    contractInsurances: {
      type: Array
    }
  },
  watch: {
    contractInsurances: function (value) {
      this.allContractInsurances = value
    }
  },
  data() {
    return {
      allContractInsurances: this.contractInsurances,
      isLoading: false
    }
  },
  methods: {
    async removeContractInsurance(id) {
      await Swal.fire({
        title: i18n.t('areYouSure'),
        text: i18n.t(`youWantToDeletePermanentlySelectedRows`),
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: i18n.t('noCancel'),
        confirmButtonText: i18n.t(`yesDelete`)
      }).then(async (result) => {
        if (result.value) {
          this.isLoading = true;
          this.$store.dispatch('contracts/DELETE_CONTRACT_INSURANCES', id).then(() => {
            this.isLoading = false;
            this.$emit('refreshData');
          }).catch(() => this.isLoading = false);
        }
      });
    },
  }
}
</script>
