import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import mixin from "@/mixins";

const colorsToProduct = {
    namespaced: true,
    state: {
        colorsToProduct: {},
        createdColorsToProduct: {},
    },
    mutations: {
        SET_COLORS_TO_PRODUCT (state, value) { state.colorsToProduct = value },
        SET_CREATED_COLOR_TO_PRODUCT (state, value) {state.createdColorsToProduct = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/ProductColors` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}`)
                    .then((response) => {
                        commit('SET_COLORS_TO_PRODUCT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_COLORS_TO_PRODUCT', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.post(`/ProductColors`, form)
                    .then((response) => {
                        commit('SET_CREATED_COLOR_TO_PRODUCT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_COLOR_TO_PRODUCT', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/ProductColors/${ids}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
        GET_ALL_ATTACHMENT(commit, colorId) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/ProductColors/ProductColorAttachment?prodColorId=${colorId}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
        ADD_ATTACHMENT(commit, form) {
            return new Promise((resolve, reject) => {
                HTTP.post(`/ProductColors/ProductColorAttachment`, form)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
        DELETE_ATTACHMENT(commit, colorAttachmentId) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/ProductColors/ProductColorAttachment/${colorAttachmentId}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        colorsToProduct: state => state.colorsToProduct,
        createdColorsToProduct: state => state.createdColorsToProduct,
    }
}

export default colorsToProduct;
