<template>
  <div>
    <v-content>
      <b-button
          variant="outline-primary"
          class="float-right"
          @click.prevent="$router.push({ name: 'products.screen.size.create', params: {id: $route.params.id} })"
      >
        <i class="fas fa-plus-circle"></i> {{ $t('create') }}
      </b-button>
      <table class="table table-striped">
        <thead>
        <tr>
          <th scope="col">{{ $t('sl') }}</th>
          <th scope="col">{{ $t('name') }}</th>
          <th scope="col">{{ $t('sizeInGB') }}</th>
          <th scope="col">{{ $t('extraPrice') }}</th>
          <th scope="col">{{ $t('feature1') }}</th>
          <th scope="col">{{ $t('feature2') }}</th>
          <th scope="col">{{ $t('feature3') }}</th>
          <th scope="col">{{ $t('feature4') }}</th>
          <th scope="col">{{ $t('delete') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(screenSize, index) in data" :key="index">
          <td>{{ ++index }}</td>
          <td>{{ screenSize.name }}</td>
          <td>{{ screenSize.size }}</td>
          <td>{{ screenSize.extraPrice }}</td>
          <td>{{ screenSize.feature1 }}</td>
          <td>{{ screenSize.feature2 }}</td>
          <td>{{ screenSize.feature3 }}</td>
          <td>{{ screenSize.feature4 }}</td>
          <td>
            <b-button variant="danger" @click="deleteScreenSize(screenSize.id)">
              <i class="fas fa-trash"></i>
            </b-button>
          </td>
        </tr>
        </tbody>
      </table>
    </v-content>
    <is-loading v-if="isLoading"/>
  </div>
</template>

<script>
import Content from "@/components/Content";
import isLoading from "@/components/IsLoading";
import {actions, swalMixin} from "@/plugins/constants";

export default {
  name: "ScreenSizeToProductList",
  components: {'v-content': Content, isLoading},
  data() {
    return {
      isLoading: false,
      data: [],
    };
  },
  props: {
    screenSizesToProduct: {
      type: Array
    }
  },
  watch: {
    screenSizesToProduct: function (value) {
      this.data = value
    }
  },
  methods: {
    async deleteScreenSize(screenSizeId) {
      if (await actions('screenSizesToProduct/DELETE', screenSizeId, 'delete')) {
        swalMixin('success', this.$t('deletedSuccessfully'))
        this.$emit('loadAll')
      }
    },
    available(screenSizeId) {
      this.isLoading = true;
      this.$store.dispatch('screenSizesToProduct/DELETE', screenSizeId).then(() => {
        swalMixin('success', this.$t('deletedSuccessfully'))
        this.isLoading = false;
        this.$emit('loadAll')
      }).catch(() => this.isLoading = false);
    }
  }
}
</script>
