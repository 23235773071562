import Vue from 'vue';
import VueLocalStorage from 'vue-localstorage';
import {i18n} from "@/plugins/i18n";

Vue.use(VueLocalStorage);

const language = {
    namespaced: true,
    state: {
        language: Vue.localStorage.get('language')
    },
    mutations: {
        SET_LANGUAGE(state, lang) {
            Vue.localStorage.set('language', lang);
            state.language = lang;
            i18n.locale = lang;
        }
    },
    actions: {
        CHANGE_LANGUAGE({ commit }, locale) {
            commit('SET_LANGUAGE', locale)
        },
    },
    getters : {
        language: state => state.language,
    }
}

export default language;
