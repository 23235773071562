export default {
    // Start Generals
    kurdish: 'کوردی',
    arabic: 'عربی',
    english: 'English',
    language: 'زمان',
    controls: 'دەستکاری',
    dashboard: 'داشبۆرد',
    kurdishName: 'ناوی کوردی',
    arabicName: 'ناوی عەرەبی',
    englishName: 'ناوی ئینگلیزی',
    isoCode: 'کۆدی نێودەوڵەتی',
    postalCode: 'پۆستال كۆد',
    city: 'شار',
    noResultsFound: "هیچ ئەنجامێک نەدۆزرایەوە",
    totalRecords: "کۆی تۆمارەکان",
    save: "پاشەکەوت بکە",
    yes: "بەڵێ",
    no: "نەخێر",
    delete: "سڕینەوو",
    edit: "دەستکاریکردن",
    someThingWentWrong: "هەڵەیەک ڕوویدا",
    settings: "ڕێکخستنەکان",
    listIsEmpty: "لیستەکە بەتاڵە",
    // End Generals

    // Start Custom Components
    isRequired: "داواکراوە",
    remove: "سڕینەوە",
    fileIsTooBig: "قەبارەی فایلەکە زۆرە",
    typeNotSupported: "جۆر پشتگیری نەکراوە",
    maxFileUploadReach: "فایلەکان زۆرە",
    pleaseSelect: "تکایە هەڵبژێرە",
    mustHaveAtLeast: "دەبێت لانی کەم هەبێت",
    letters: "پیت",
    mustNotHaveGreaterThen: "نابێت گەورەتر بێت",
    attachments: "هاوپێچەکان",
    close: "داخستن",
    search: "گەڕان",
    multipleDelete: "فرە سڕینەوە",
    multipleRestore: "فرە گەڕاندنەوە",
    multipleForceDelete: "فرە سڕینەوەی هەتایی",
    export: "ناردن",
    noRowsSelected: "هیچ ڕیزێک دەستنیشان نەکراوە",
    youWantToDeletePermanentlySelectedRows: "دەتەوێت ڕیزە دیاریکراوە  بۆ هەمیشەییەکان بسڕیتەوە",
    youWantToRestorePermanentlySelectedRows: "دەتەوێت ڕیزە دیاریکراوە بۆ هەمیشەییەکان بگەڕێنیتەوە",
    yesDelete: "بەڵێ، بسڕەوە",
    yesRestore: "بەڵێ، بگەڕێنەوە",
    yourDataHasBeenDeletedPermanently: "داتاکەت بە هەمیشەیی سڕاوەتەوە",
    yourDataHasBeenRestoredPermanently: "داتاکەت بە هەمیشەیی گەڕاوەتەوە",
    noCancel: "نەخێر!",
    dragAndDropToUploadContent: "ڕاکێشان و دانان بۆ بارکردنی ناوەڕۆک",
    orClickToSelectAFileFromYourComputer: "...یان کرتە بکە بۆ دیاریکردنی فایلێک لە کۆمپیوتەرەکەتەوە",
    areYouSure: "ئایا دڵنیایت؟",
    sl: "زنجیرە",
    addedSuccessfully: "بەسەرکەوتوویی زیادکرا",
    updatedSuccessfully: "بەسەرکەوتووی گۆڕدرا",
    deletedSuccessfully: "بەسەرکەوتووی سڕایەوە",
    // End Custom Components

    // Start Dashboard
    numberOfProducts: "ژمارەی بەرهەمەکان",
    numberOfBrands: "ژمارەی براندەکان",
    numberOfCollections: "ژمارەی کۆکراوەکان",
    numberOfTags: "ژمارەی تاگەکان",
    latestProducts: "دوایین بەرهەمەکان",
    latestCollections: "دوا کۆکردنەوەکان",
    productsInFrontSlider: "بەرهەمەکان لە پێشەوەی سلایدەر",
    productsInAllPageTopSlider: "بەرهەمەکان لە هەموو سڵایدەری سەرەوەی لاپەڕە",
    // End Dashboard

    // Start Sidebar
    products: "بەرهەمەکان",
    list: "لیست",
    productSizes: "قەبارەی بەرهەم",
    productRamSizes: "قەبارەیی بەرهەم",
    productColors: "ڕەنگەکانی بەرهەم",
    brands: "براندەکان",
    tags: "تاگەکان",
    collections: "کۆکراوەکان",
    productTypes: "جۆرەکانی بەرهەم",
    installmentMethods: "شێوازەکانی قیستەکان",
    utilities: "سوودەکان",
    sliders: "سلایدەکان",
    allPageTopSlider: "سلایدی سەرەوەی پەڕە",
    customers: "کڕیارەکان",
    create: "دروستکردن",
    contracts: "گرێبەستەکان",
    logout: "چوونەژوورەوە",
    // End Sidebar

    // Start Contracts
    createContract: "دروستکردنی گرێبەست",
    customer: "کڕیار",
    color: "ڕەنگ",
    diskSize: "قەبارەی",
    ramSize: "قەبارەی خوارەوە",
    price: "نرخ",
    extraPrice: "نرخی زیادە",
    discount: "داشکاندن ٪",
    totalPrice: "کۆی نرخ",
    installmentMethod: "شێوازی قیست",
    editPriceToProduct: "گۆڕینی نرخی بەرهەم",
    editProductPrice: "گۆڕینی نرخی بەرهەم",
    index: "پێڕست",
    payDate: "بەرواری پارەدان",
    deadline: "دوا مۆڵەت",
    prePayment: "پێش پارەدان",
    contractNumber: "ژمارەی گرێبەست",
    isAllPayed: "ئایا هەمووی پارەدراوە",
    customerName: "ناوی کڕیار",
    productName: "ناوی بەرهەم",
    colorHex: "شەشی ڕەنگ",
    diskSizeName: "ناوی قەبارەی",
    ramSizeName: "ناوی قەبارەی خوارەوە",
    pleaseCheckPayments: "تکایە پارەدانەکان بپشکنە!!!",
    showContract: "گرێبەست نیشان بدە",
    contractsList: "لیستی گرێبەستەکان",
    contract: "گرێبەست",
    customerPhone: "تەلەفۆنی کڕیار",
    productColor: "ڕەنگی بەرهەم",
    productDiskSize: "قەبارەیی بەرهەم",
    productRamSize: "قەبارەی خوارەوەی بەرهەم",
    note: "تێبینی",
    print: "چاپکردن",
    pay: "پارەدان",
    // End Contracts

    // Start Customers
    createCustomer: "دروستکردنی کڕیار",
    firstName: "ناوی یەکەم",
    MiddleName: "ناوی ناوەڕاست",
    LastName: "دوا ناو",
    dob: "بەرواری لەدایکبوون",
    phone: "تەلەفۆن",
    phone2: "تەلەفۆن ٢",
    address: "ناونیشان",
    email: "ئیمەیڵ",
    hasAccount: "ئەژمێری هەیە",
    editCustomer: "بژارکردنی کڕیار",
    customersList: "لیستی کڕیارەکان",
    createCustomerAttachment: "لکێندراوی کڕیار پێکبهێنە",
    sharable: "هاوبەشکراو",
    share: "هاوبەشکردن",
    // End Customers

    // Start All Top Page Slider
    createAllPageTopSlider: "سلایدی سەرەوەی لاپەڕە",
    title: "ناونیشان",
    kuTitle: "ناونیشانی کوردی",
    arTitle: "ناونیشانی عەرەبی",
    product: "بەرهەم",
    attachment: "هاوپێچ",
    code: "کۆد",
    barcode: "بارکۆد",
    name: "ناو",
    kuName: "ناوی کوردی",
    arName: "ناوی عەرەبی",
    groupProductType: "گروپی جۆری بەرهەم",
    allPageTopSlidersList: "هەموو لیستی سلایدەکانی سەرەوەی پەڕە",
    allPageTopSliders: "لیستی سلایدەکانی سەرەوەی پەڕە",
    topSlidersList: "لیستی سلایدەکانی سەرەوەی پەڕە",
    // End All Top Page Slider

    // Start Front Page Slider
    createFrontPageSlider: "دروستکردنی سلایدی لاپەڕەی پێشەوە",
    frontPageSliders: "سلایدەرەکانی پەڕەی پێشەوە",
    frontPageSlidersList: "لیستی سلایدەرەکانی پەڕەی پێشەوە",
    editFrontPageSlider: "دەستکاری کردنی سلایدەرەکانی پەڕەی پێشەوە",
    createFrontPageSliderProduct: "زیادکردنی بەرهەم بۆ سلایدەرەکانی پەڕەی پێشەوە",
    // End Front Page Slider

    // Start Most Sale
    createMostSale: "زیادکردنی زۆرترین فرۆش",
    mostSale: "زۆرترین فرۆش",
    mostSales: "زۆرترین فرۆشەکان",
    mostSalesList: "لیستی زۆرترین فرۆشەکان",
    editMostSale: "دەستکاری زۆرترین فرۆشەکان",
    createMostSaleProduct: "زیادکردنی بەرهەم بۆ زۆرترین فرۆشەکان",
    // End Most Sale

    // Start New Season
    createNewSeason: "زیادکردنی وەرزی نوێ",
    newSeason: "وەرزی نوێ",
    newSeasons: "وەرزە نوێیەکان",
    newSeasonList: "لیستی وەرزە نوێیەکان",
    editNewSeason: "دەستکاری وەرزە نوێیەکان",
    createNewSeasonProduct: "زیادکردنی بەرهەم بۆ وەرزە نوێیەکان",
    // End New Season

    // Start Featured Collection
    createFeaturedCollection: "زیادکردنی کۆکراوەی تایبەت",
    featuredCollection: "کۆکراوەی تایبەت",
    featuredCollections: "کۆکراوە تایبەتەکان",
    featuredCollectionsList: "لیستی کۆکراوە تایبەتەکان",
    editFeaturedCollection: "دەستکاری کۆکراوە تایبەتەکان",
    createFeaturedCollectionProduct: "زیادکردنی بەرهەم بۆ کۆکراوە تایبەتەکان",
    // End Featured Collection

    // Start Take Pay
    createTake3Pay2: "زیادکردنی ٣ ببە پارەی ٢ بدە",
    take3Pay2: "٣ ببە پارەی ٢ بدە",
    take3Pay2s: "٣ ببە پارەی ٢ بدە",
    take3Pay2List: "لیستی ٣ ببە پارەی ٢ بدە",
    takePayList: "لیستی ٣ ببە پارەی ٢ بدە",
    editTakePay: "دەستکاری ٣ ببە پارەی ٢ بدە",
    createTake3Pay2Product: "زیادکردنی بەرهەم بۆ ٣ ببە پارەی ٢ بدە",
    // End Take Pay

    // Start Installment Methods
    createInstallmentMethod: "دروستکردنی شێوازی دامەزراندن",
    prePaymentRate: "ڕێژەی پێش پارەدان",
    paymentCount: "ژمارەی پارەدان",
    paymentPeriodDays: "ڕۆژانی کاتی پارەدان",
    editInstallmentMethod: "بژارکردنی شێوازی دامەزراندن",
    installmentMethodsList: "لیستی شێوازەکانی دامەزراندن",
    // End Installment Methods

    // Start Products
    createBrand: "دروستکردنی براند",
    editBrand: "بژارکردنی براند",
    brandList: "لیستی براند",
    createCollection: "کۆکردنەوە پێکبهێنە",
    sort: "پۆلێنکردن",
    editCollection: "بژارکردنی کۆکردنەوە",
    collectionList: "لیستی کۆکردنەوە",
    createProductColor: "دروستکردنی ڕەنگی بەرهەم",
    editProductColor: "بژارکردنی ڕەنگی بەرهەم",
    hexCode: "کۆدی شەشی",
    sizeInGB: "قەبارە",
    createProductRamSize: "دروستکردنی قەبارەی خوارەوەی بەرهەم",
    editProductRamSize: "بژارکردنی قەبارەی خوارەوەی بەرهەم",
    productRamSizeList: "لیستی قەبارەی خوارەوەی بەرهەم",
    createProductSize: "دروستکردنی قەبارەی بەرهەم",
    editProductSize: "بژارکردنی قەبارەی بەرهەم",
    productSizeList: "لیستی قەبارەی بەرهەم",
    createTag: "دروستکردنی تاگ",
    editTag: "دەستکاری تاگ بکە",
    tagList: "لیستی تاگ",
    createProductType: "دروستکردنی جۆری بەرهەم",
    editProductType: "بژارکردنی جۆری بەرهەم",
    productTypeList: "لیستی جۆری بەرهەم",
    productList: "لیستی بەرهەم",
    cost: "تێچوون",
    createPriceToProduct: "زیادکردنی نرخ بۆ بەرهەم",
    brandName: "ناوی براند",
    productTypeName: "ناوی جۆری بەرهەم",
    description: "وەسف",
    kuDescription: "وەسفی کوردی",
    arDescription: "وەسفی عەرەبی",
    createProduct: "بەرهەم دروست بکە",
    productType: "جۆری بەرهەم",
    thumbnail: "وێنەی بچووک",
    editProduct: "بژارکردنی بەرهەم",
    prices: "نرخەکان",
    brand: "براند",
    prefix: "پێشگر",
    suffix: "پاشگر",
    branchName: "ناوی لق",
    branch: "لق",
    createProductAttachment: "پاشکۆی بەرهەم پێکبهێنە",
    addAttachments: "هاوپێچەکان زیاد بکە",
    showAttachments: "هاوپێچەکان نیشان بدە",
    colors: "ڕەنگەکان",
    createColorsToProduct: "دروستکردنی ڕەنگەکان بۆ بەرهەم",
    chest: "سنگ",
    waist: "کەمەر",
    hips: "هیپ",
    thigh: "ڕان",
    length: "درێژیس",
    createDiskSizeToProduct: "دروستکردنی قەبارەی بۆ بەرهەم",
    createRamSizeToProduct: "دروستکردنی قەبارەی خوارەوە بۆ بەرهەم",
    features: "تایبەتمەندیەکان",
    detail: "وردەکاری",
    createFeatureToProduct: "دروستکردنی تایبەتمەندی بۆ بەرهەم",
    editFeatureToProduct: "بژارکردنی تایبەتمەندی بۆ بەرهەم",
    createInstallmentMethodsToProduct: "دروستکردنی شێوازەکانی دامەزراندن بۆ بەرهەم",
    // End Products

    // Start Customer Feedbacks
    customerFeedbacks: "ڕای کڕیارەکانمان",
    customerFeedbackList: "لیستی ڕای کڕیارەکانمان",
    date: "بەروار",
    reviewed: "پێداچونەوەی بۆ کراوە",
    accepted: "پەسەندکرا",
    shareable: "هاوبەشکراو",
    kuDetail: "وردەکارییەکانی کوردی",
    arDetail: "وردەکارییەکانی عەرەبی",
    createCustomerFeedback: "دروستکردنی کاردانەوەکانی کڕیار",
    editCustomerFeedback: "دەستکاریکردنی کاردانەوەکانی کڕیار",
    kuAttachment: "هاوپێچی کورد",
    arAttachment: "هاوپێچی عەرەبی",
    available: "بەردەستە؟",
    // End Customer Feedbacks

    // Start Top Slider
    topSlider: "سلایدی سەرەوە",
    topSliderList: "لیستی سلایدەری سەرەوە",
    createTopSlider: "دروستکردنی سلایدی سەرەوە",
    editTopSlider: "دەستکاریکردنی سلایدی سەرەوە",
    // End Top Slider

    // Start Settings
    pages: "پەیجەکان",
    iraqFacebookPage: "پەیجی فەیسبووکی عیراق",
    arabFacebookPage: "بەیجی فەیسبووکی وڵاتانی عەرەبی",
    euroFacebookPage: "بەیجی فەیسبووکی ئەوڕوپا",
    updateSettings: "هەڵگرتنی رێکخستنەکان",
    settingsUpdated: "ڕێکخستنەکان هەڵگیرا",
    // End Settings

    // Start Currency
    currency: "دراوەکان",
    currencyList: "لیستی دراوەکان",
    createCurrency: "دروستکردنی دراو",
    editCurrency: "دەستکاری دراو",
    // End Currency

    // Start Ads
    ads: "ڕێکلام",
    adsList: "لیستی ڕێکلام",
    createAds: "زیادکردنی ڕێکلام",
    editAds: "دەستکاری کردنی ڕێکلام",
    useSeparateImage: "بەکارهێنانی وێنەی جیا",
    useSeparateAttachment: "بەکارهێنانی وێنەی جیا",
    numberOfTypes: "ژمارەی جۆرەکان",
    pleaseEnterAtLeastOneType: "تکایە جۆرێک بنووسە",
    // End Ads
}
