import { HTTP } from "@/plugins/axios";
import { resolveServerParams } from "@/plugins/constants";
import ProductAttributes from "@/enums/ProductAttributes";
import mixin from "@/mixins";

const madeIn = {
  namespaced: true,
  state: {
    madeIn: {},
    createdMadeIn: {},
    getOneMadeIn: {},
    updatedMadeIn: {},
  },
  mutations: {
    SET_MADE_IN(state, value) {
      state.madeIn = value;
    },
    SET_CREATED_MADE_IN(state, value) {
      state.createdMadeIn = value;
    },
    SET_GET_ONE_MADE_IN(state, value) {
      state.getOneMadeIn = value;
    },
    SET_UPDATED_MADE_IN(state, value) {
      state.updatedMadeIn = value;
    },
  },
  actions: {
    GET_ALL({ commit }, serverParams) {
      return new Promise((resolve, reject) => {
        serverParams = resolveServerParams(serverParams);
        HTTP.get(
          `/Main` +
            `${serverParams._start}` +
            `${serverParams._end}` +
            `${serverParams._sort}` +
            `${serverParams._order}` +
            `${serverParams._search}` +
            `${serverParams.custom_params}` +
            `&objectName=` +
            ProductAttributes.MadeIn
        )
          .then((response) => {
            commit("SET_MADE_IN", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_MADE_IN", {});
            reject(error);
          });
      });
    },
    GET_ONE({ commit }, id) {
      return new Promise((resolve, reject) => {
        HTTP.get(`/Main/${id}?objectName=` + ProductAttributes.MadeIn)
          .then((response) => {
            commit("SET_GET_ONE_MADE_IN", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_GET_ONE_MADE_IN", {});
            reject(error);
          });
      });
    },
    CREATE({ commit }, form) {
      mixin.removeEmptyAndNull(form);
      return new Promise((resolve, reject) => {
        HTTP.post(`/Main?objectName=` + ProductAttributes.MadeIn, form)
          .then((response) => {
            commit("SET_CREATED_MADE_IN", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_CREATED_MADE_IN", {});
            reject(error);
          });
      });
    },
    UPDATE({ commit }, { id, form }) {
      mixin.removeEmptyAndNull(form);
      return new Promise((resolve, reject) => {
        HTTP.put(`/Main/${id}?objectName=` + ProductAttributes.MadeIn, form)
          .then((response) => {
            commit("SET_UPDATED_MADE_IN", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_UPDATED_MADE_IN", {});
            reject(error);
          });
      });
    },
    DELETE(commit, ids) {
      return new Promise((resolve, reject) => {
        HTTP.delete(`/Main/${ids}?objectName=` + ProductAttributes.MadeIn)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  getters: {
    madeIn: (state) => state.madeIn,
    createdMadeIn: (state) => state.createdMadeIn,
    getOneMadeIn: (state) => state.getOneMadeIn,
    updatedMadeIn: (state) => state.updatedMadeIn,
  },
};

export default madeIn;
