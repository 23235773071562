<template>
  <div>
    <content-header>{{ $t('createInvoice') }}</content-header>
    <v-content>
      <b-card>
        <b-card-body>
          <select-input
              :is-loaded="isLoading"
              id="products"
              :name="$t('products')"
              :placeholder="$t('products')"
              validate="$v"
              :required="true"
              api-link="products/GET_ALL"
              label="name"
              @itemSelected="productSelected"
          />
          <div class="row">
            <div class="col-2">{{ $t('colors') }}</div>
            <div class="col-2">{{ $t('diskSize') }}</div>
            <div class="col-2">{{ $t('ramSize') }}</div>
            <div class="col-2">{{ $t('screenSize') }}</div>
            <div class="col-2">{{ $t('price') }}</div>
            <div class="col-2">{{ $t('add') }}</div>
          </div>
          <div class="colors" v-for="color in colors" :key="color.id">
            <div class="diskSizes" v-for="diskSize in diskSizes" :key="diskSize.id">
              <div class="ramSizes" v-for="ramSize in ramSizes" :key="ramSize.id">
                <div v-if="screenSizeLength >= 1">
                  <div class="screenSizes" v-for="screenSize in screenSizes" :key="screenSize.id">
                    <div class="row">
                      <div class="col-2">{{ color.name }}</div>
                      <div class="col-2">{{ diskSize.name }}</div>
                      <div class="col-2">{{ ramSize.name }}</div>
                      <div class="col-2">{{ screenSize.name }}</div>
                      <div class="col-2">
                        <div class="form-group row">
                          <div class="col-sm-12">
                            <input
                                :id="'price_' + color.colorId + '_' + diskSize.diskSizeId + '_' + ramSize.ramSizeId + '_' + screenSize.screenSizeId"
                                :disabled="isLoading"
                                :placeholder="$t('price')"
                                type="number"
                                autocomplete="off"
                                class="form-control"
                                @focus="$event.target.select()"
                                v-on:keypress="e => $helpers.isNumeric(e)"
                                step=".001"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-2">
                        <div class="form-group row">
                          <div class="col-sm-12">
                            <button
                                class="btn btn-primary m-2"
                                @click="addToStock(productId, color.colorId, diskSize.diskSizeId, ramSize.ramSizeId, screenSize.screenSizeId)"
                            >
                              {{ $t('addToStock') }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="row">
                    <div class="col-2">{{ color.name }}</div>
                    <div class="col-2">{{ diskSize.name }}</div>
                    <div class="col-2">{{ ramSize.name }}</div>
                    <div class="col-2">-</div>
                    <div class="col-2">
                      <div class="form-group row">
                        <div class="col-sm-12">
                          <input
                              :id="'price_' + color.colorId + '_' + diskSize.diskSizeId + '_' + ramSize.ramSizeId + '_' + 'undefined'"
                              :disabled="isLoading"
                              :placeholder="$t('price')"
                              type="number"
                              autocomplete="off"
                              class="form-control"
                              @focus="$event.target.select()"
                              v-on:keypress="e => $helpers.isNumeric(e)"
                              step=".001"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="form-group row">
                        <div class="col-sm-12">
                          <button
                              class="btn btn-primary m-2"
                              @click="addToStock(productId, color.colorId, diskSize.diskSizeId, ramSize.ramSizeId)"
                          >
                            {{ $t('addToStock') }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <is-loading v-if="isLoading" />
        </b-card-body>
      </b-card>
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import IsLoading from "@/components/IsLoading";
import {Invoice} from "@/models/Invoice";
import SelectInput from "@/components/Inputs/SelectInput";
import mixins from "@/mixins";

export default {
  name: "OneStock",
  components: {SelectInput, ContentHeader, 'v-content': Content, IsLoading},
  data() {
    return {
      form: new Invoice(),
      isLoading: false,
      invoiceDate: mixins.getCurrentDate(),
      productId: 0,
      diskSizes: [],
      ramSizes: [],
      colors: [],
      screenSizes: [],
      stockProducts: []
    }
  },
  methods: {
    addToStock(product, colorId, diskSizeId, ramSizeId, screenSizeId = undefined) {
      let form = {
        productId: this.productId,
        colorId: colorId,
        diskSizeId: diskSizeId,
        ramSizeId: ramSizeId,
        screenSizeId: this.screenSizes.length === 0 ? 0 : screenSizeId,
        price: document.getElementById('price_' + colorId + '_' + diskSizeId + '_' + ramSizeId + '_' + (this.screenSizes.length === 0 ? 'undefined' : screenSizeId)).value
      };
      this.isLoading = true;
      this.$store.dispatch('invoices/CREATE_ONE_ROW', form).then(() => {
        this.$router.push({name: "products.stock"})
      }).catch(() => this.isLoading = false);
    },
    productSelected(e) {
      this.productId = e;
      this.isLoading = true;
      this.$store.dispatch('products/GET_ONE', e).then((response) => {
        this.diskSizes    = response.data.availableDiskSizes;
        this.ramSizes     = response.data.availableRamSizes;
        this.colors       = response.data.availableColors;
        this.screenSizes  = response.data.availableScreenSizes;
        this.screenSizeLength = response.data.availableScreenSizes.length === 0 ? 1 : response.data.availableScreenSizes.length;
        this.isLoading    = false;
      }).catch(() => this.isLoading = false);
      this.$store.dispatch('products/GET_PRODUCT_BY_ID_STOCK', e).then((response) => {
        this.stockProducts  = response.data;
        this.isLoading    = false;
      }).catch(() => this.isLoading = false);
    },
    findInStock(product, colorId, diskSizeId, ramSizeId, screenSizeId) {
      let filter = {product, colorId, diskSizeId, ramSizeId, screenSizeId: screenSizeId === 'undefined' ? 1 : screenSizeId}
      this.$store.dispatch('products/GET_PRODUCT_PRICE_ID_STOCK', filter).then((response) => {
        document.getElementById('qty_' + colorId + '_' + diskSizeId + '_' + ramSizeId + '_' + screenSizeId).value = response.data.qty;
        document.getElementById('price_' + colorId + '_' + diskSizeId + '_' + ramSizeId + '_' + screenSizeId).value = response.data.price;
        this.isLoading    = false;
      }).catch(() => this.isLoading = false);
    }
  }
}
</script>
