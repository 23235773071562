<template>
  <div>
    <content-header>{{ $t("editProduct") }}</content-header>
    <v-content>
      <b-card no-body>
        <b-tabs card>
          <b-tab :title="$t('edit')" active>
            <form class="forms-sample" @submit.prevent="onSubmit">
              <text-input
                id="code"
                :name="$t('code')"
                :validate="$v"
                :required="true"
                :is-loading="isLoading"
              />
              <text-input
                id="barcode"
                :name="$t('barcode')"
                :validate="$v"
                :required="true"
                :is-loading="isLoading"
              />
              <text-input
                id="name"
                :name="$t('name')"
                :validate="$v"
                :required="true"
                :max-length="true"
                :is-loading="isLoading"
              />
              <text-input
                id="kuName"
                :name="$t('kuName')"
                :validate="$v"
                :required="true"
                :max-length="true"
                :is-loading="isLoading"
              />
              <text-input
                id="arName"
                :name="$t('arName')"
                :validate="$v"
                :required="true"
                :max-length="true"
                :is-loading="isLoading"
              />
              <boolean-input
                  id="available"
                  :name="$t('isAvailable')"
                  :load-on-mount="false"
                  :default-value="true"
                  :checked-label="$t('yes')"
                  :un-checked-label="$t('no')"
                  label-class="col-md-4"
                  input-class="col-md-8"
                  @switched="(e) => form.isAvailable = e"
              />
              <rich-text-input
                id="description"
                :name="$t('description')"
                :validate="$v"
                :is-loading="isLoading"
              />
              <rich-text-input
                id="kuDescription"
                :name="$t('kuDescription')"
                :validate="$v"
                :is-loading="isLoading"
              />
              <rich-text-input
                id="arDescription"
                :name="$t('arDescription')"
                :validate="$v"
                :is-loading="isLoading"
              />
              <number-input
                id="cost"
                :name="$t('cost')"
                :validate="$v"
                :required="true"
                :is-loading="isLoading"
              />

              <div class="form-group row">
                <label for="id" class="col-sm-2 col-form-label text-right">{{ $t('increasePercentage') }}</label>
                <div class="col-sm-10">
                  <input
                      id="id"
                      v-model.trim="increasePercentage"
                      :disabled="isLoading"
                      :placeholder="$t('increasePercentage')"
                      type="number"
                      autocomplete="off"
                      class="form-control"
                      @focus="$event.target.select()"
                      v-on:keypress="e => $helpers.isNumeric(e)"
                      step=".001"
                  />
                </div>
              </div>
              <number-input
                id="price"
                :name="$t('price')"
                :validate="$v"
                :required="true"
                :is-loading="isLoading"
              />
              <text-input
                id="discount"
                :name="$t('discountPer')"
                :validate="$v"
                :required="true"
                :is-loading="isLoading"
              />
              <number-input
                  id="shippingPrice"
                  :name="$t('shippingPrice')"
                  :validate="$v"
                  :required="true"
                  :is-loading="isLoading"
              />
              <number-input
                  id="shippingTime"
                  :name="$t('shippingTime')"
                  :validate="$v"
                  :required="true"
                  :is-loading="isLoading"
              />
              <number-input
                  id="minOrder"
                  :name="$t('minOrder')"
                  :validate="$v"
                  :required="true"
                  :is-loading="isLoading"
              />
              <text-input
                  id="contains"
                  :name="$t('contains')"
                  :validate="$v"
                  :is-loading="isLoading"
              />
              <text-input
                  id="height"
                  :name="$t('height')"
                  :validate="$v"
                  :is-loading="isLoading"
              />
              <text-input
                  id="width"
                  :name="$t('width')"
                  :validate="$v"
                  :is-loading="isLoading"
              />
              <text-input
                  id="length"
                  :name="$t('length')"
                  :validate="$v"
                  :is-loading="isLoading"
              />
              <text-input
                  id="cbm"
                  :name="$t('cbm')"
                  :validate="$v"
                  :is-loading="isLoading"
              />
              <select-input
                  :is-loaded="isLoading"
                  id="madeIn"
                  :name="$t('madeIn')"
                  :placeholder="$t('madeIn')"
                  :validate="$v"
                  :required="true"
                  api-link="madeIn/GET_ALL"
                  :default-value="form.madeInId"
                  label="name"
                  @itemSelected="(e) => (form.madeInId = e)"
              />
              <select-input
                  :is-loaded="isLoading"
                  id="supplier"
                  :name="$t('supplier')"
                  :placeholder="$t('supplier')"
                  :validate="$v"
                  :required="true"
                  api-link="suppliers/GET_ALL"
                  :default-value="form.supplierId"
                  label="name"
                  @itemSelected="(e) => (form.supplierId = e)"
              />
              <text-input
                  id="season"
                  :name="$t('season')"
                  :validate="$v"
                  :is-loading="isLoading"
              />
              <select-input
                :is-loaded="isLoading"
                id="brand"
                :name="$t('brand')"
                :validate="$v"
                :required="true"
                api-link="brands/GET_ALL"
                label="name"
                :default-value="form.brandId"
                @itemSelected="(e) => (form.brandId = e)"
              />
              <text-view name="productType" :value="form.productTypeName" type="String" />
              <select-input
                  tabIndex="16"
                  :is-loaded="isLoading"
                  id="productType"
                  :name="$t('departments')"
                  :placeholder="$t('productType')"
                  :validate="$v"
                  :required="true"
                  api-link="productTypes/GET_ALL"
                  label="name"
                  @itemSelected="parentSelected"
              />
              <select-input
                  tabIndex="17"
                  :is-loaded="isLoading"
                  id="productType"
                  :name="$t('sections')"
                  :placeholder="$t('productType')"
                  :validate="$v"
                  :required="true"
                  api-link="productTypes/GET_ALL"
                  :api-params="{ id: form.parentId }"
                  label="name"
                  @itemSelected="subParentSelected"
                  :disabled="subParentDisable"
              />
              <select-input
                  :is-loaded="isLoading"
                  id="productType"
                  :name="$t('categories')"
                  :placeholder="$t('productType')"
                  :validate="$v"
                  :required="true"
                  api-link="productTypes/GET_ALL"
                  :api-params="{ id: form.subParentId }"
                  label="name"
                  @itemSelected="productTypeSelected"
                  :disabled="productTypeDisabled"
              />
              <select-input
                  tabIndex="17"
                  :is-loaded="isLoading"
                  id="productType"
                  :name="$t('families')"
                  :placeholder="$t('productType')"
                  :validate="$v"
                  :required="true"
                  api-link="productTypes/GET_ALL"
                  :api-params="{ id: form.lastProductType }"
                  label="name"
                  @itemSelected="childSelected"
                  :disabled="productTypeChildDisabled"
              />
              <text-area-input
                id="note"
                :name="$t('note')"
                :validate="$v"
                :is-loading="isLoading"
              />
              <file-input
                id="attachment"
                :name="$t('attachment')"
                @fileAdded="(base64File) => (form.attachment = base64File)"
              />
              <submit-input
                icon="fa-save"
                :name="$t('save')"
                :disabled="isLoading"
              />
              <show-attachment
                :attachment="form.attachment"
                :title="form.name"
              />
              <is-loading v-if="isLoading" />
            </form>
          </b-tab>
          <b-tab :title="$t('prices')">
            <price-to-product-list
              :prices-to-product="productPriceBranches"
              @loadAll="loadOneData"
            />
          </b-tab>
          <b-tab :title="$t('attachment')">
            <b-button
              variant="outline-primary"
              class="float-right"
              @click.prevent="
                $router.push({
                  name: 'products.attachment.create',
                  params: { id: $route.params.id },
                })
              "
            >
              <i class="fas fa-plus-circle"></i> {{ $t("create") }}
            </b-button>
            <product-attachments-list
              :attachments="productAttachments"
              @loadAll="loadOneData"
            />
          </b-tab>
          <b-tab :title="$t('colors')">
            <color-to-product-list
              :colors-to-product="availableColors"
              @loadAll="loadOneData"
            />
          </b-tab>
          <b-tab :title="$t('diskSize')">
            <disk-size-to-product-list
              :disk-sizes-to-product="availableDiskSizes"
              @loadAll="loadOneData"
            />
          </b-tab>
          <b-tab :title="$t('ramSize')">
            <ram-size-to-product-list
              :ram-sizes-to-product="availableRamSizes"
              @loadAll="loadOneData"
            />
          </b-tab>
          <b-tab :title="$t('screenSize')">
            <screen-size-to-product-list
              :screen-sizes-to-product="availableScreenSizes"
              @loadAll="loadOneData"
            />
          </b-tab>
          <b-tab :title="$t('features')">
            <feature-to-product-list
              :features-to-product="productFeatures"
              @loadAll="loadOneData"
            />
          </b-tab>
          <b-tab :title="$t('installments')">
            <installment-methods-to-product-list
              :installments-to-product="installmentMethods"
              @loadAll="loadOneData"
            />
          </b-tab>
        </b-tabs>
      </b-card>
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import {maxLength, required} from "vuelidate/lib/validators";
import IsLoading from "@/components/IsLoading";
import {Product} from "@/models/Product";
import SubmitInput from "@/components/Inputs/SubmitInput";
import TextInput from "@/components/Inputs/TextInput";
import FileInput from "@/components/Inputs/FileInput";
import ShowAttachment from "@/components/ShowAttachment";
import TextAreaInput from "@/components/Inputs/TextAreaInput";
import SelectInput from "@/components/Inputs/SelectInput";
import NumberInput from "@/components/Inputs/NumberInput";
import ProductAttachmentsList from "@/views/products/edit/attachments/List";
import ColorToProductList from "@/views/products/edit/colors/List";
import DiskSizeToProductList from "@/views/products/edit/diskSize/List";
import RamSizeToProductList from "@/views/products/edit/ramSize/List";
import FeatureToProductList from "@/views/products/edit/features/List";
import PriceToProductList from "@/views/products/edit/prices/List";
import RichTextInput from "@/components/Inputs/RichTextInput";
import ScreenSizeToProductList from "@/views/products/edit/screenSize/List";
import InstallmentMethodsToProductList from "@/views/products/edit/installmentMethods/List";
import TextView from "@/components/TextView";
import BooleanInput from "@/components/Inputs/BooleanInput";

export default {
  name: "ProductEdit",
  components: {
    BooleanInput,
    TextView,
    ScreenSizeToProductList,
    RichTextInput,
    PriceToProductList,
    FeatureToProductList,
    RamSizeToProductList,
    DiskSizeToProductList,
    ColorToProductList,
    ProductAttachmentsList,
    InstallmentMethodsToProductList,
    NumberInput,
    SelectInput,
    TextAreaInput,
    ShowAttachment,
    FileInput,
    SubmitInput,
    ContentHeader,
    "v-content": Content,
    IsLoading,
    TextInput,
  },
  data() {
    return {
      form: this.$helpers.convertArrayToObject(new Product().fillAbles),
      isLoading: false,
      increasePercentage: 0,
      selectedItem: {},
      productAttachments: [],
      availableColors: [],
      availableDiskSizes: [],
      availableRamSizes: [],
      availableScreenSizes: [],
      productFeatures: [],
      installmentMethods: [],
      productPriceBranches: [],
      subParentDisable: true,
      productTypeDisabled: true,
      productTypeChildDisabled: true
    };
  },
  mounted() {
    this.isLoading = true;
    this.loadOneData();
  },
  watch: {
    "increasePercentage": function (val) {
      this.form.price = (parseFloat(this.form.cost) + parseFloat(this.form.cost * (val / 100))).toFixed(3);
    },
    "form.cost": function(val) {
      this.form.price = (parseFloat(val) + parseFloat(val * (this.increasePercentage / 100))).toFixed(3);
    },
  },
  validations: {
    form: {
      code: { required },
      barcode: { required },
      name: { required, maxLength: maxLength(255) },
      kuName: { required, maxLength: maxLength(255) },
      arName: { required, maxLength: maxLength(255) },
      description: {},
      kuDescription: {},
      arDescription: {},
      price: {},
      cost: {},
      discount: {},
      season: {},
      brandId: {},
      productTypeId: {},
      note: {},
      attachment: {},
      minOrder: {},
      shippingPrice: {},
      shippingTime: {},
      width: {},
      length: {},
      height: {},
      weight: {},
      cbm: {},
      madeInId: {},
      supplierId: {},
      productStatus: {},
    },
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return false;
      this.isLoading = true;
      this.form.tags = [];
      let discount = this.form.discount;
      let price = this.form.price;
      let remain = 100 - discount;
      let beforeDiscount = (price / remain) * 100;
      this.form.discount = beforeDiscount - price;

      this.form.productTypeId =
        this.form.childId !== 0 && this.form.childId !== undefined
          ? this.form.childId
          : this.form.lastProductType !== 0 && this.form.lastProductType !== undefined
            ? this.form.lastProductType
            : this.form.subParentId !== 0 && this.form.subParentId !== undefined
              ? this.form.subParentId
              : this.form.parentId !== 0 && this.form.parentId !== undefined
                ? this.form.parentId
                : this.form.productTypeId;

      this.$store
        .dispatch("products/UPDATE", {
          id: this.$route.params.id,
          form: this.form,
        })
        .then(() => {
          this.$v.$reset();
          this.$router.push({ name: "products" });
        })
        .catch(() => (this.isLoading = false));
    },
    loadOneData() {
      this.$store
        .dispatch("products/GET_ONE", this.$route.params.id)
        .then((response) => {
          this.form = response.data;
          this.increasePercentage = (((response.data.price - response.data.cost) / response.data.cost) * 100).toFixed(3)
          this.form.discount = response.data.price === 0 ? 0 : (response.data.discount / response.data.price) * 100;
          this.productAttachments = response.data.attachments;
          this.availableColors = response.data.availableColors;
          this.availableDiskSizes = response.data.availableDiskSizes;
          this.availableRamSizes = response.data.availableRamSizes;
          this.availableScreenSizes = response.data.availableScreenSizes;
          this.productFeatures = response.data.productFeatures;
          this.productPriceBranches = response.data.productPriceBranches;
          this.installmentMethods = response.data.installmentMethods;
          this.isLoading = false;
        })
        .catch(() => (this.isLoading = false));
    },
    async parentSelected(e) {
      this.subParentDisable = true;
      this.form.subParentId = 0;
      this.form.parentId = await e;
      this.subParentDisable = false;
    },
    async subParentSelected(e) {
      this.productTypeDisabled = true;
      this.form.lastProductType = 0;
      this.form.subParentId = await e;
      this.productTypeDisabled = false;
    },
    async productTypeSelected(e) {
      this.productTypeChildDisabled = true;
      this.form.childid = 0;
      this.form.lastProductType = await e;
      this.productTypeChildDisabled = false;
    },
    async childSelected(e) {
      this.form.childId = await e;
    },
  },
};
</script>
