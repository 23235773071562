import { i18n } from "@/plugins/i18n";

import Dashboard from "@/views/Dashboard";

import ProductSizeList from "@/views/products/sizes/List";
import ProductSizeCreate from "@/views/products/sizes/Create";
import ProductSizeEdit from "@/views/products/sizes/Edit";

import ProductRamSizeList from "@/views/products/ram-sizes/List";
import ProductRamSizeCreate from "@/views/products/ram-sizes/Create";
import ProductRamSizeEdit from "@/views/products/ram-sizes/Edit";

import ProductScreenSizeList from "@/views/products/screen-sizes/List";
import ProductScreenSizeCreate from "@/views/products/screen-sizes/Create";
import ProductScreenSizeEdit from "@/views/products/screen-sizes/Edit";

import ProductThirdSizeList from "@/views/products/features/List";
import ProductThirdSizeCreate from "@/views/products/features/Create";
import ProductThirdSizeEdit from "@/views/products/features/Edit";

import ProductColorList from "@/views/products/colors/List";
import ProductColorCreate from "@/views/products/colors/Create";
import ProductColorEdit from "@/views/products/colors/Edit";

import BrandList from "@/views/products/brands/List";
import BrandCreate from "@/views/products/brands/Create";
import BrandEdit from "@/views/products/brands/Edit";

import CurrencyList from "@/views/currencies/List";
import CurrencyCreate from "@/views/currencies/Create";
import CurrencyEdit from "@/views/currencies/Edit";

import MadeInList from "@/views/products/madeIn/List";
import MadeInCreate from "@/views/products/madeIn/Create";
import MadeInEdit from "@/views/products/madeIn/Edit";

import TagList from "@/views/products/tags/List";
import TagCreate from "@/views/products/tags/Create";
import TagEdit from "@/views/products/tags/Edit";

import ProductTypeList from "@/views/products/types/List";
import ProductTypeListView from "@/views/products/types/ListView";
import ProductTypeCreate from "@/views/products/types/Create";
import ProductTypeEdit from "@/views/products/types/Edit";
import ProductTypeCreateBatch from "@/views/products/types/CreateBatch";
import ProductTypeDragDrop from "@/views/products/types/DragAndDrop";

import InstallmentMethodList from "@/views/installment-methods/List";
import InstallmentMethodCreate from "@/views/installment-methods/Create";
import InstallmentMethodEdit from "@/views/installment-methods/Edit";

import CustomerList from "@/views/customers/List";
import CustomerAccountList from "@/views/customers/ListAccount";
import CustomerCreate from "@/views/customers/Create";
import CustomerEdit from "@/views/customers/Edit";
import CustomerAttachmentCreate from "@/views/customers/attachments/Create";

import SupplierList from "@/views/suppliers/List";
import SupplierCreate from "@/views/suppliers/Create";
import SupplierEdit from "@/views/suppliers/Edit";
import SupplierAttachmentCreate from "@/views/suppliers/attachments/Create";

import ProductList from "@/views/products/List";
import ProductCreate from "@/views/products/Create";
import ProductCreateMultiple from "@/views/products/CreateMultiple";
import ProductEdit from "@/views/products/Edit";
import ProductAttachmentCreate from "@/views/products/edit/attachments/Create";
import ColorProductCreate from "@/views/products/edit/colors/Create";
import DiskSizeProductCreate from "@/views/products/edit/diskSize/Create";
import RamSizeProductCreate from "@/views/products/edit/ramSize/Create";
import ScreenSizeProductCreate from "@/views/products/edit/screenSize/Create";
import FeatureProductCreate from "@/views/products/edit/features/Create";
import FeatureProductEdit from "@/views/products/edit/features/Edit";
import InstallmentMethodsToProductCreate from "@/views/products/edit/installmentMethods/Create";
import PriceProductCreate from "@/views/products/edit/prices/Create";
import ProductStockList from "@/views/products/StockList";
import StockProductEdit from "@/views/products/StockProductEdit";
import OneStock from "@/views/invoices/CreateOneRow";

import ContractList from "@/views/contracts/List";
import ContractCreate from "@/views/contracts/Create";
import ContractEdit from "@/views/contracts/Edit";
import ContractShow from "@/views/contracts/Show";
import ContractInsurancesCreate from "@/views/contracts/contract-insurances/Create";

import Settings from "@/views/Settings";

import FrontPageSliderList from "@/views/frontPageSliders/List";
import FrontPageSliderCreate from "@/views/frontPageSliders/Create";
import FrontPageSliderEdit from "@/views/frontPageSliders/Edit";
import FrontPageSliderGroupProductCreate from "@/views/frontPageSliders/products/Create";

import NewArrivalsList from "@/views/newArrivals/List";
import NewArrivalsCreate from "@/views/newArrivals/Create";
import NewArrivalsEdit from "@/views/newArrivals/Edit";
import NewArrivalsGroupProductCreate from "@/views/newArrivals/products/Create";

import DiscountList from "@/views/discount/List";
import DiscountCreate from "@/views/discount/Create";
import DiscountEdit from "@/views/discount/Edit";
import DiscountGroupProductCreate from "@/views/discount/products/Create";

import TopSliderList from "@/views/topSliders/List";
import TopSliderCreate from "@/views/topSliders/Create";
import TopSliderEdit from "@/views/topSliders/Edit";
import TopSliderGroupProductCreate from "@/views/topSliders/products/List";

import MostSaleList from "@/views/mostSales/List";
import MostSaleCreate from "@/views/mostSales/Create";
import MostSaleEdit from "@/views/mostSales/Edit";
import MostSaleGroupProductCreate from "@/views/mostSales/products/Create";

import AdsList from "@/views/ads/List";
import AdsCreate from "@/views/ads/Create";
import AdsEdit from "@/views/ads/Edit";

import NewSeasonList from "@/views/newSeason/List";
import NewSeasonCreate from "@/views/newSeason/Create";
import NewSeasonEdit from "@/views/newSeason/Edit";
import NewSeasonGroupProductCreate from "@/views/newSeason/products/Create";

import FeaturedCollectionList from "@/views/featuredCollections/List";
import FeaturedCollectionCreate from "@/views/featuredCollections/Create";
import FeaturedCollectionEdit from "@/views/featuredCollections/Edit";
import FeaturedCollectionGroupProductCreate from "@/views/featuredCollections/products/Create";

import TakePayList from "@/views/take3Pay2/List";
import TakePayCreate from "@/views/take3Pay2/Create";
import TakePayEdit from "@/views/take3Pay2/Edit";
import TakePayGroupProductCreate from "@/views/take3Pay2/products/Create";
import SupplierBatch from "@/views/suppliers/SupplierBatch";

import InvoiceList from "@/views/invoices/List";
import InvoiceCreate from "@/views/invoices/Create";

import ProductTypeUserList from "@/views/productTypeUser/List";
import ProductTypeUserCreate from "@/views/productTypeUser/Create";

export default [
  {
    path: "/",
    name: "dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
      title: i18n.t("dashboard"),
    },
  },
  {
    path: "/dashboard",
    name: "dashboard.page",
    component: Dashboard,
    meta: {
      requiresAuth: true,
      title: i18n.t("dashboard"),
    },
  },
  {
    path: "/admin",
    name: "admin",
    component: Dashboard,
    meta: {
      requiresAuth: true,
      title: i18n.t("dashboard"),
    },
  },

  {
    path: "/products/sizes",
    name: "productSizes",
    component: ProductSizeList,
    meta: {
      requiresAuth: true,
      title: i18n.t("productSizes"),
    },
  },
  {
    path: "/products/sizes/create",
    name: "productSizes.create",
    component: ProductSizeCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createProductSize"),
    },
  },
  {
    path: "/products/sizes/:id/edit",
    name: "productSizes.edit",
    component: ProductSizeEdit,
    meta: {
      requiresAuth: true,
      title: i18n.t("editProductSize"),
    },
  },

  {
    path: "/products/ram-sizes",
    name: "productRamSizes",
    component: ProductRamSizeList,
    meta: {
      requiresAuth: true,
      title: i18n.t("ramSizes"),
    },
  },
  {
    path: "/products/ram-sizes/create",
    name: "productRamSizes.create",
    component: ProductRamSizeCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createProductRamSize"),
    },
  },
  {
    path: "/products/ram-sizes/:id/edit",
    name: "productRamSizes.edit",
    component: ProductRamSizeEdit,
    meta: {
      requiresAuth: true,
      title: i18n.t("editProductRamSize"),
    },
  },

  {
    path: "/products/screen-sizes",
    name: "productScreenSizes",
    component: ProductScreenSizeList,
    meta: {
      requiresAuth: true,
      title: i18n.t("screenSizes"),
    },
  },
  {
    path: "/products/screen-sizes/create",
    name: "productScreenSizes.create",
    component: ProductScreenSizeCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createProductScreenSize"),
    },
  },
  {
    path: "/products/screen-sizes/:id/edit",
    name: "productScreenSizes.edit",
    component: ProductScreenSizeEdit,
    meta: {
      requiresAuth: true,
      title: i18n.t("editProductScreenSize"),
    },
  },

  {
    path: "/products/third-sizes",
    name: "productThirdSizes",
    component: ProductThirdSizeList,
    meta: {
      requiresAuth: true,
      title: i18n.t("thirdSizes"),
    },
  },
  {
    path: "/products/third-sizes/create",
    name: "productThirdSizes.create",
    component: ProductThirdSizeCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createProductThirdSize"),
    },
  },
  {
    path: "/products/third-sizes/:id/edit",
    name: "productThirdSizes.edit",
    component: ProductThirdSizeEdit,
    meta: {
      requiresAuth: true,
      title: i18n.t("editProductThirdSize"),
    },
  },

  {
    path: "/products/colors",
    name: "productColors",
    component: ProductColorList,
    meta: {
      requiresAuth: true,
      title: i18n.t("productColors"),
    },
  },
  {
    path: "/products/colors/create",
    name: "productColors.create",
    component: ProductColorCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createProductColor"),
    },
  },
  {
    path: "/products/colors/:id/edit",
    name: "productColors.edit",
    component: ProductColorEdit,
    meta: {
      requiresAuth: true,
      title: i18n.t("editProductColor"),
    },
  },

  {
    path: "/products/brands",
    name: "brands",
    component: BrandList,
    meta: {
      requiresAuth: true,
      title: i18n.t("brands"),
    },
  },
  {
    path: "/products/brands/create",
    name: "brands.create",
    component: BrandCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createBrand"),
    },
  },
  {
    path: "/products/brands/:id/edit",
    name: "brands.edit",
    component: BrandEdit,
    meta: {
      requiresAuth: true,
      title: i18n.t("editBrand"),
    },
  },

  {
    path: "/currencies",
    name: "currencies",
    component: CurrencyList,
    meta: {
      requiresAuth: true,
      title: i18n.t("currencies"),
    },
  },
  {
    path: "/currencies/create",
    name: "currencies.create",
    component: CurrencyCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createCurrency"),
    },
  },
  {
    path: "/currencies/:id/edit",
    name: "currencies.edit",
    component: CurrencyEdit,
    meta: {
      requiresAuth: true,
      title: i18n.t("editCurrency"),
    },
  },

  {
    path: "/products/madeIn",
    name: "madeIn",
    component: MadeInList,
    meta: {
      requiresAuth: true,
      title: i18n.t("madeIn"),
    },
  },
  {
    path: "/products/madeIn/create",
    name: "madeIn.create",
    component: MadeInCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createMadeIn"),
    },
  },
  {
    path: "/products/madeIn/:id/edit",
    name: "madeIn.edit",
    component: MadeInEdit,
    meta: {
      requiresAuth: true,
      title: i18n.t("editMadeIn"),
    },
  },

  {
    path: "/products/types",
    name: "productTypes",
    component: ProductTypeList,
    meta: {
      requiresAuth: true,
      title: i18n.t("productTypes"),
    },
  },
  {
    path: "/products/types/view",
    name: "productTypesView",
    component: ProductTypeListView,
    meta: {
      requiresAuth: true,
      title: i18n.t("productTypesView"),
    },
  },
  {
    path: "/products/types/create",
    name: "productTypes.create",
    component: ProductTypeCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createProductType"),
    },
  },
  {
    path: "/products/types/:id/edit",
    name: "productTypes.edit",
    component: ProductTypeEdit,
    meta: {
      requiresAuth: true,
      title: i18n.t("editProductType"),
    },
  },
  {
    path: "/products/types/create/batch",
    name: "productTypes.create.batch",
    component: ProductTypeCreateBatch,
    meta: {
      requiresAuth: true,
      title: i18n.t("createProductTypeBatch"),
    },
  },
  {
    path: "/products/types/create/dragdrop",
    name: "productTypes.create.dragdrop",
    component: ProductTypeDragDrop,
    meta: {
      requiresAuth: true,
      title: i18n.t("createProductTypeDragDrop"),
    },
  },

  {
    path: "/installment-methods",
    name: "installmentMethods",
    component: InstallmentMethodList,
    meta: {
      requiresAuth: true,
      title: i18n.t("installmentMethods"),
    },
  },
  {
    path: "/installment-methods/create",
    name: "installmentMethods.create",
    component: InstallmentMethodCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createInstallmentMethod"),
    },
  },
  {
    path: "/installment-methods/:id/edit",
    name: "installmentMethods.edit",
    component: InstallmentMethodEdit,
    meta: {
      requiresAuth: true,
      title: i18n.t("editInstallmentMethod"),
    },
  },

  {
    path: "/products/tags",
    name: "tags",
    component: TagList,
    meta: {
      requiresAuth: true,
      title: i18n.t("tags"),
    },
  },
  {
    path: "/products/tags/create",
    name: "tags.create",
    component: TagCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createTag"),
    },
  },
  {
    path: "/products/tags/:id/edit",
    name: "tags.edit",
    component: TagEdit,
    meta: {
      requiresAuth: true,
      title: i18n.t("editTag"),
    },
  },

  {
    path: "/customers",
    name: "customers",
    component: CustomerList,
    meta: {
      requiresAuth: true,
      title: i18n.t("customers"),
    },
  },
  {
    path: "/customer-accounts",
    name: "customer.accounts",
    component: CustomerAccountList,
    meta: {
      requiresAuth: true,
      title: i18n.t("customerAccounts"),
    },
  },
  {
    path: "/customers/create",
    name: "customers.create",
    component: CustomerCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createCustomer"),
    },
  },
  {
    path: "/customers/:id/edit",
    name: "customers.edit",
    component: CustomerEdit,
    meta: {
      requiresAuth: true,
      title: i18n.t("editCustomer"),
    },
  },
  {
    path: "/customer-attachment/:id/create",
    name: "customers.attachment.create",
    component: CustomerAttachmentCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createCustomerAttachment"),
    },
  },

  {
    path: "/suppliers",
    name: "suppliers",
    component: SupplierList,
    meta: {
      requiresAuth: true,
      title: i18n.t("suppliers"),
    },
  },
  {
    path: "/suppliers/create",
    name: "suppliers.create",
    component: SupplierCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createSupplier"),
    },
  },
  {
    path: "/suppliers/:id/edit",
    name: "suppliers.edit",
    component: SupplierEdit,
    meta: {
      requiresAuth: true,
      title: i18n.t("editSupplier"),
    },
  },
  {
    path: "/supplier-attachment/:id/create",
    name: "suppliers.attachment.create",
    component: SupplierAttachmentCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createSupplierAttachment"),
    },
  },
  {
    path: "/suppliers/batch",
    name: "suppliers.batch",
    component: SupplierBatch,
    meta: {
      requiresAuth: true,
      title: i18n.t("supplierBatch"),
    },
  },

  {
    path: "/products",
    name: "products",
    component: ProductList,
    meta: {
      requiresAuth: true,
      title: i18n.t("products"),
    },
  },
  {
    path: "/products/create",
    name: "products.create",
    component: ProductCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createProduct"),
    },
  },
  {
    path: "/products/create-multiple",
    name: "products.create.multiple",
    component: ProductCreateMultiple,
    meta: {
      requiresAuth: true,
      title: i18n.t("createProduct"),
    },
  },
  {
    path: "/products/:id/edit",
    name: "products.edit",
    component: ProductEdit,
    meta: {
      requiresAuth: true,
      title: i18n.t("editProduct"),
    },
  },
  {
    path: "/product-attachment/:id/create",
    name: "products.attachment.create",
    component: ProductAttachmentCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createProductAttachment"),
    },
  },
  {
    path: "/product-color/:id/create",
    name: "products.color.create",
    component: ColorProductCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createProductColor"),
    },
  },
  {
    path: "/product-disk-size/:id/create",
    name: "products.disk.size.create",
    component: DiskSizeProductCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createProductDiskSize"),
    },
  },
  {
    path: "/product-ram-size/:id/create",
    name: "products.ram.size.create",
    component: RamSizeProductCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createProductRamSize"),
    },
  },
  {
    path: "/product-screen-size/:id/create",
    name: "products.screen.size.create",
    component: ScreenSizeProductCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createProductScreenSize"),
    },
  },
  {
    path: "/product-features/:id/create",
    name: "products.features.create",
    component: FeatureProductCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createProductFeature"),
    },
  },
  {
    path: "/product-features/:id/edit",
    name: "products.features.edit",
    component: FeatureProductEdit,
    meta: {
      requiresAuth: true,
      title: i18n.t("editProductFeature"),
    },
  },
  {
    path: "/product-installment-methods/:id/create",
    name: "products.installment.methods.create",
    component: InstallmentMethodsToProductCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createProductInstallmentMethod"),
    },
  },
  {
    path: "/product-prices/:id/create",
    name: "products.prices.create",
    component: PriceProductCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createProductPrice"),
    },
  },
  {
    path: "/products/stock-list",
    name: "products.stock",
    component: ProductStockList,
    meta: {
      requiresAuth: true,
      title: i18n.t("productStock"),
    },
  },
  {
    path: "/products/stock-list/edit-product",
    name: "products.stock.edit",
    component: StockProductEdit,
    meta: {
      requiresAuth: true,
      title: i18n.t("productStock"),
    },
  },
  {
    path: "/products/stock-list/add-product",
    name: "products.stock.add",
    component: OneStock,
    meta: {
      requiresAuth: true,
      title: i18n.t("oneStock"),
    },
  },

  {
    path: "/contracts",
    name: "contracts",
    component: ContractList,
    meta: {
      requiresAuth: true,
      title: i18n.t("contracts"),
    },
  },
  {
    path: "/contracts/create",
    name: "contracts.create",
    component: ContractCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createContract"),
    },
  },
  {
    path: "/contracts/:id/edit",
    name: "contracts.edit",
    component: ContractEdit,
    meta: {
      requiresAuth: true,
      title: i18n.t("editContract"),
    },
  },
  {
    path: "/contracts/:id/show",
    name: "contracts.show",
    component: ContractShow,
    meta: {
      requiresAuth: true,
      title: i18n.t("showContract"),
    },
  },
  {
    path: "/contracts/insurances/:id/create",
    name: "contract.insurances.create",
    component: ContractInsurancesCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("contractInsurancesCreate"),
    },
  },

  {
    path: "/settings",
    name: "settings",
    component: Settings,
    meta: {
      requiresAuth: true,
      title: i18n.t("settings"),
    },
  },
  {
    path: "/products/front-page-slider",
    name: "front.page.slider",
    component: FrontPageSliderList,
    meta: {
      requiresAuth: true,
      title: i18n.t("frontPageSliders"),
    },
  },
  {
    path: "/products/front-page-slider/create",
    name: "front.page.slider.create",
    component: FrontPageSliderCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createFrontPageSlider"),
    },
  },
  {
    path: "/products/front-page-slider/:id/edit",
    name: "front.page.slider.edit",
    component: FrontPageSliderEdit,
    meta: {
      requiresAuth: true,
      title: i18n.t("editFrontPageSlider"),
    },
  },
  {
    path: "/products/front-page-slider/:id/edit/create",
    name: "front.page.group.products.create",
    component: FrontPageSliderGroupProductCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createFrontPageSliderGroupProduct"),
    },
  },

  {
    path: "/products/new-arrivals",
    name: "new.arrivals",
    component: NewArrivalsList,
    meta: {
      requiresAuth: true,
      title: i18n.t("newArrivals"),
    },
  },
  {
    path: "/products/new-arrivals/create",
    name: "new.arrivals.create",
    component: NewArrivalsCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createNewArrivals"),
    },
  },
  {
    path: "/products/new-arrivals/:id/edit",
    name: "new.arrivals.edit",
    component: NewArrivalsEdit,
    meta: {
      requiresAuth: true,
      title: i18n.t("editNewArrivals"),
    },
  },
  {
    path: "/products/new-arrivals/:id/edit/create",
    name: "new.arrivals.products.create",
    component: NewArrivalsGroupProductCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createNewArrivalsGroupProduct"),
    },
  },

  {
    path: "/products/discount",
    name: "discount",
    component: DiscountList,
    meta: {
      requiresAuth: true,
      title: i18n.t("discount"),
    },
  },
  {
    path: "/products/discount/create",
    name: "discount.create",
    component: DiscountCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createDiscount"),
    },
  },
  {
    path: "/products/discount/:id/edit",
    name: "discount.edit",
    component: DiscountEdit,
    meta: {
      requiresAuth: true,
      title: i18n.t("editDiscount"),
    },
  },
  {
    path: "/products/discount/:id/edit/create",
    name: "discount.products.create",
    component: DiscountGroupProductCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createDiscountGroupProduct"),
    },
  },

  {
    path: "/products/top-sliders",
    name: "top.slider",
    component: TopSliderList,
    meta: {
      requiresAuth: true,
      title: i18n.t("allPageTopSliders"),
    },
  },
  {
    path: "/products/top-sliders/create",
    name: "top.slider.create",
    component: TopSliderCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createAllPageTopSlider"),
    },
  },
  {
    path: "/products/top-sliders/:id/edit",
    name: "top.slider.edit",
    component: TopSliderEdit,
    meta: {
      requiresAuth: true,
      title: i18n.t("editFrontPageSlider"),
    },
  },
  {
    path: "/products/top-sliders/:id/edit/create",
    name: "top.slider.group.products.create",
    component: TopSliderGroupProductCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createTopSliderGroupProduct"),
    },
  },

  {
    path: "/products/most-sales",
    name: "most.sale",
    component: MostSaleList,
    meta: {
      requiresAuth: true,
      title: i18n.t("mostSales"),
    },
  },
  {
    path: "/products/most-sales/create",
    name: "most.sale.create",
    component: MostSaleCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createMostSale"),
    },
  },
  {
    path: "/products/most-sales/:id/edit",
    name: "most.sale.edit",
    component: MostSaleEdit,
    meta: {
      requiresAuth: true,
      title: i18n.t("editMostSale"),
    },
  },
  {
    path: "/products/most-sales/:id/edit/create",
    name: "most.sale.group.products.create",
    component: MostSaleGroupProductCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createMostSaleGroupProduct"),
    },
  },

  {
    path: "/products/ads",
    name: "ads",
    component: AdsList,
    meta: {
      requiresAuth: true,
      title: i18n.t("ads"),
    },
  },
  {
    path: "/products/ads/create",
    name: "ads.create",
    component: AdsCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createAds"),
    },
  },
  {
    path: "/products/ads/:id/edit",
    name: "ads.edit",
    component: AdsEdit,
    meta: {
      requiresAuth: true,
      title: i18n.t("editAds"),
    },
  },

  {
    path: "/products/new-season",
    name: "new.season",
    component: NewSeasonList,
    meta: {
      requiresAuth: true,
      title: i18n.t("newSeason"),
    },
  },
  {
    path: "/products/new-season/create",
    name: "new.season.create",
    component: NewSeasonCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createNewSeason"),
    },
  },
  {
    path: "/products/new-season/:id/edit",
    name: "new.season.edit",
    component: NewSeasonEdit,
    meta: {
      requiresAuth: true,
      title: i18n.t("editNewSeason"),
    },
  },
  {
    path: "/products/new-season/:id/edit/create",
    name: "new.season.group.products.create",
    component: NewSeasonGroupProductCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createNewSeasonGroupProduct"),
    },
  },

  {
    path: "/products/featured-collections",
    name: "featured.collection",
    component: FeaturedCollectionList,
    meta: {
      requiresAuth: true,
      title: i18n.t("featuredCollections"),
    },
  },
  {
    path: "/products/featured-collections/create",
    name: "featured.collection.create",
    component: FeaturedCollectionCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createFeaturedCollections"),
    },
  },
  {
    path: "/products/featured-collections/:id/edit",
    name: "featured.collection.edit",
    component: FeaturedCollectionEdit,
    meta: {
      requiresAuth: true,
      title: i18n.t("editFeaturedCollection"),
    },
  },
  {
    path: "/products/featured-collections/:id/edit/create",
    name: "featured.collection.group.products.create",
    component: FeaturedCollectionGroupProductCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createFeaturedCollectionGroupProduct"),
    },
  },

  {
    path: "/products/take-3-pay2",
    name: "take.3.pay.2",
    component: TakePayList,
    meta: {
      requiresAuth: true,
      title: i18n.t("take3Pay2"),
    },
  },
  {
    path: "/products/take-3-pay2/create",
    name: "take.3.pay.2.create",
    component: TakePayCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createTake3Pay2"),
    },
  },
  {
    path: "/products/take-3-pay2/:id/edit",
    name: "take.3.pay.2.edit",
    component: TakePayEdit,
    meta: {
      requiresAuth: true,
      title: i18n.t("editTakePay"),
    },
  },
  {
    path: "/products/take-3-pay2/:id/edit/create",
    name: "take.3.pay.2.group.products.create",
    component: TakePayGroupProductCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createTakePayGroupProduct"),
    },
  },

  {
    path: "/invoice",
    name: "invoice",
    component: InvoiceList,
    meta: {
      requiresAuth: true,
      title: i18n.t("invoice"),
    },
  },
  {
    path: "/invoice/create",
    name: "invoice.create",
    component: InvoiceCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createInvoice"),
    },
  },

  {
    path: "/product-type-user",
    name: "product.type.user",
    component: ProductTypeUserList,
    meta: {
      requiresAuth: true,
      title: i18n.t("productTypeUser"),
    },
  },
  {
    path: "/product-type-user/create",
    name: "product.type.user.create",
    component: ProductTypeUserCreate,
    meta: {
      requiresAuth: true,
      title: i18n.t("createProductTypeUser"),
    },
  },
];
