import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import mixin from "@/mixins";
import ProductAttributes from "@/enums/ProductAttributes";
import GroupTypes from "@/enums/GroupTypes";

const discount = {
    namespaced: true,
    state: {
        discount: {},
        createdDiscount: {},
        getOneDiscount: {},
        updatedDiscount: {},
    },
    mutations: {
        SET_DISCOUNT (state, value) { state.discount = value },
        SET_CREATED_DISCOUNTS (state, value) {state.createdDiscount = value},
        SET_GET_ONE_DISCOUNTS (state, value) {state.getOneDiscount = value},
        SET_UPDATED_DISCOUNTS (state, value) {state.updatedDiscount = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/Main` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}&objectName=${ProductAttributes.Group}&groupType=${GroupTypes.Discount}`)
                    .then((response) => {
                        commit('SET_DISCOUNT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_DISCOUNT', {})
                        reject(error)
                    });
            })
        },
        GET_ONE({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/Main/${id}?objectName=${ProductAttributes.Group}`)
                    .then((response) => {
                        commit('SET_GET_ONE_DISCOUNTS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GET_ONE_DISCOUNTS', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.post(`/Main?objectName=${ProductAttributes.Group}`, form)
                    .then((response) => {
                        commit('SET_CREATED_DISCOUNTS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_DISCOUNTS', {})
                        reject(error)
                    });
            })
        },
        UPDATE({commit}, {id, form}) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.put(`/Main/${id}?objectName=${ProductAttributes.Group}`, form)
                    .then((response) => {
                        commit('SET_UPDATED_DISCOUNTS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_UPDATED_DISCOUNTS', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/Main/${ids}?objectName=${ProductAttributes.Group}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        discount: state => state.discount,
        createdDiscount: state => state.createdDiscount,
        getOneDiscount: state => state.getOneDiscount,
        updatedDiscount: state => state.updatedDiscount,
    }
}

export default discount;
