<template>
  <div class="form-group row">
    <label v-if="showLabel" :for="id" class="col-sm-2 col-form-label text-right" :class="{'required-field': required}">{{ name }}</label>
    <div :class="{'col-sm-12': !showLabel, 'col-sm-10': showLabel}">
      <input
          :id="id"
          type="color"
          :disabled="isLoading || readonly"
          class="form-control form-control-color"
          :title="name"
          v-model.trim="validate[form][id].$model"
          :class="{'is-invalid': validationStatus(validate[form][id])}"
      >
      <div v-if="!validate[form][id].required && required" class="invalid-feedback">{{ name }} {{ $t('isRequired') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ColorInput",
  props: {
    form: {
      type: String,
      default: 'form'
    },
    id: {
      type: String
    },
    name: {
      type: String
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    defaultValue: {
      default: 0
    },
    readonly: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean
    },
    validate: {
      type: Object
    },
    required: {
      type: Boolean
    },
    minLength: {
      type: Boolean
    },
    maxLength: {
      type: Boolean
    },
    showLocaleString: {
      type: Boolean,
      default: false
    },
  },
  mounted() {
    if (this.defaultValue !== '') {
      this.validate[this.form][this.id].$model = this.defaultValue
    }
  },
  methods: {
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    }
  }
}
</script>
