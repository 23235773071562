<template>
  <div>
    <v-content>
      <b-button
          variant="outline-primary"
          class="float-right"
          @click.prevent="$router.push({ name: 'products.features.create', params: {id: $route.params.id} })"
      >
        <i class="fas fa-plus-circle"></i> {{ $t('create') }}
      </b-button>
      <table class="table table-striped">
        <thead>
        <tr>
          <th scope="col">{{ $t('sl') }}</th>
          <th scope="col">{{ $t('name') }}</th>
          <th scope="col">{{ $t('kuName') }}</th>
          <th scope="col">{{ $t('arName') }}</th>
          <th scope="col">{{ $t('detail') }}</th>
          <th scope="col">{{ $t('kuDetail') }}</th>
          <th scope="col">{{ $t('arDetail') }}</th>
          <th scope="col">{{ $t('controls') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(feature, index) in data" :key="index">
          <td>{{ ++index }}</td>
          <td>{{ feature.name }}</td>
          <td>{{ feature.feature1 }}</td>
          <td>{{ feature.feature2 }}</td>
          <td>{{ feature.feature3 }}</td>
          <td>{{ feature.feature4 }}</td>
          <td>
<!--            <button class="btn btn-outline-success ml-1" @click.prevent="$router.push({ name: 'products.features.edit', params: {id: feature.id} })"><i class="fal fa-edit"></i></button>-->
            <button class="btn btn-danger ml-1" @click="deleteFeature(feature.id)"><i class="fal fa-trash"></i></button>
          </td>
        </tr>
        </tbody>
      </table>
    </v-content>
    <is-loading v-if="isLoading"/>
  </div>
</template>

<script>
import Content from "@/components/Content";
import isLoading from "@/components/IsLoading";
import {actions, swalMixin} from "@/plugins/constants";

export default {
  name: "FeatureToProductList",
  components: {'v-content': Content, isLoading},
  data() {
    return {
      isLoading: false,
      data: [],
    }
  },
  props: {
    featuresToProduct: {
      type: Array
    }
  },
  watch: {
    featuresToProduct: function (value) {
      this.data = value
    }
  },
  methods: {
    async deleteFeature(featureId) {
      if (await actions('featuresToProduct/DELETE', featureId, 'delete')) {
        swalMixin('success', this.$t('deletedSuccessfully'))
        this.$emit('loadAll')
      }
    },
    available(featureId) {
      this.isLoading = true;
      this.$store.dispatch('featuresToProduct/DELETE', featureId).then(() => {
        swalMixin('success', this.$t('deletedSuccessfully'))
        this.isLoading = false;
        this.$emit('loadAll')
      }).catch(() => this.isLoading = false);
    }
  }
}
</script>
