<template>
  <div>
    <content-header>{{ $t('editProductType') }}</content-header>
    <v-content>
      <div class="row">
        <div class="col-4">
          <select-input
              :showLabel="false"
              id="productType"
              :name="$t('departments')"
              :placeholder="$t('departments')"
              :required="true"
              api-link="productTypes/GET_ALL"
              label="name"
              @itemSelected="selectDepartment"
          />
        </div>
        <div class="col-4">
          <select-input
              :showLabel="false"
              id="productType"
              :name="$t('sections')"
              :placeholder="$t('sections')"
              :required="true"
              api-link="productTypes/GET_ALL"
              :api-params="{ id: selectedDepartment }"
              label="name"
              @itemSelected="selectSection"
          />
        </div>
        <div class="col-4">
          <select-input
              :showLabel="false"
              id="productType"
              :name="$t('categories')"
              :placeholder="$t('categories')"
              :required="true"
              api-link="productTypes/GET_ALL"
              :api-params="{ id: selectedSection }"
              label="name"
              @itemSelected="selectCategory"
          />
        </div>
        <div class="col-3">
          <h2>{{ $t('departments') }} / <button class="btn btn-primary" @click="rearrangeDepartments">{{ $t('rearrange') }}</button></h2>
          <draggable v-model="departments" @start="drag=true" @end="drag=false" @change="dragAndDrop" group="a">
              <one-item v-for="element in departments" :key="element.id" :data="element" />
          </draggable>
        </div>
        <div class="col-3">
          <h2>{{ $t('sections') }} / <button class="btn btn-primary" @click="rearrangeSections">{{ $t('rearrange') }}</button></h2>
          <draggable v-model="sections" @start="drag=true" @end="drag=false" @change="dragAndDrop" group="b">
              <one-item v-for="element in sections" :key="element.id" :data="element" />
          </draggable>
        </div>
        <div class="col-3">
          <h2>{{ $t('categories') }} / <button class="btn btn-primary" @click="rearrangeCategories">{{ $t('rearrange') }}</button></h2>
          <draggable v-model="categories" @start="drag=true" @end="drag=false" @change="dragAndDrop" group="c">
              <one-item v-for="element in categories" :key="element.id" :data="element" />
          </draggable>
        </div>
        <div class="col-3">
          <h2>{{ $t('families') }} / <button class="btn btn-primary" @click="rearrangeFamilies">{{ $t('rearrange') }}</button></h2>
          <draggable v-model="families" @start="drag=true" @end="drag=false" @change="dragAndDrop" group="d">
              <one-item v-for="element in families" :key="element.id" :data="element" />
          </draggable>
        </div>
      </div>
    </v-content>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import ContentHeader from "@/components/ContentHeader";
import Content from "@/components/Content";
import OneItem from "@/views/products/types/OneItem";
import SelectInput from "@/components/Inputs/SelectInput";
import {swalMixin} from "@/plugins/constants";

export default {
  name: "ProductTypeDragDrop",
  components: {
    SelectInput,
    OneItem,
    draggable,
    ContentHeader,
    'v-content': Content
  },
  data() {
    return {
      drag: false,
      departments: [],
      selectedDepartment: 0,
      sections: [],
      selectedSection: 0,
      categories: [],
      selectedCategory: 0,
      families: [],
      selectedFamily: 0,
    }
  },
  mounted() {
    this.getDepartments();
  },
  methods: {
    dragAndDrop(list) {
      this.$store
          .dispatch("productTypes/RE_SORT", {id: list.moved.element.id, sort: list.moved.newIndex + 1})
          .then(() => {
            swalMixin('success', this.$t('updatedSuccessfully'))
          })
          .catch((error) => {
            console.log(error);
          });
    },
    getDepartments() {
      let serverParams = {
        _start: 0,
        _end: 50000,
        _sort: "sort",
        _order: "ASC",
        _search: "",
      };
      this.$store
          .dispatch("productTypes/GET_ALL", serverParams)
          .then((response) => {
            this.departments = response.data;
            this.selectedDepartment = this.departments[0].id;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.getSections();
          });
    },
    selectDepartment(id) {
      this.selectedDepartment = id;
      this.getSections();
    },
    rearrangeDepartments() {
      this.$store
          .dispatch("productTypes/RE_ARRANGE", {id: null})
          .then(() => {
            swalMixin('success', this.$t('updatedSuccessfully'))
          })
          .catch((error) => {
            console.log(error);
          })
    },
    getSections() {
      let serverParams = {
        _start: 0,
        _end: 50000,
        _sort: "sort",
        _order: "ASC",
        _search: "",
        custom_params: {
          id: this.selectedDepartment,
        },
      };
      this.$store
          .dispatch("productTypes/GET_ALL", serverParams)
          .then((response) => {
            this.sections = response.data;
            this.selectedSection = this.sections[0].id;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            if (this.sections.length > 0) {
              this.getCategories();
            }
          });
    },
    selectSection(id) {
      this.selectedSection = id;
      this.getCategories();
    },
    rearrangeSections() {
      this.$store
          .dispatch("productTypes/RE_ARRANGE", {id: this.selectedDepartment})
          .then(() => {
            swalMixin('success', this.$t('updatedSuccessfully'))
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.getCategories();
          });
    },
    getCategories() {
      let serverParams = {
        _start: 0,
        _end: 50000,
        _sort: "sort",
        _order: "ASC",
        _search: "",
        custom_params: {
          id: this.selectedSection,
        },
      };
      this.$store
          .dispatch("productTypes/GET_ALL", serverParams)
          .then((response) => {
            this.categories = response.data;
            this.selectedCategory = this.categories[0].id;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            if (this.categories.length > 0) {
              this.getFamilies();
            }
          });
    },
    selectCategory(id) {
      this.selectedCategory = id;
      this.getFamilies();
    },
    rearrangeCategories() {
      this.$store
          .dispatch("productTypes/RE_ARRANGE", {id: this.selectedSection})
          .then(() => {
            swalMixin('success', this.$t('updatedSuccessfully'))
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.getFamilies();
          });
    },
    getFamilies() {
      let serverParams = {
        _start: 0,
        _end: 50000,
        _sort: "sort",
        _order: "ASC",
        _search: "",
        custom_params: {
          id: this.selectedCategory,
        },
      };
      this.$store
          .dispatch("productTypes/GET_ALL", serverParams)
          .then((response) => {
            this.families = response.data;
            this.selectedFamily = this.families[0].id;
          });
    },
    rearrangeFamilies() {
      this.$store
          .dispatch("productTypes/RE_ARRANGE", {id: this.selectedCategory})
          .then(() => {
            swalMixin('success', this.$t('updatedSuccessfully'))
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.getFamilies();
          });
    },
  }
}
</script>