import {HTTP} from "@/plugins/axios";
import mixins from "@/mixins";

const groupProducts = {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {
        ADD_PRODUCT_TO_GROUP(commit, form) {
            return new Promise((resolve, reject) => {
                mixins.removeEmptyAndNull(form);
                HTTP.post(`/Products/GroupProduct`, form)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
        REMOVE_PRODUCT_TO_GROUP(commit, id) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/Products/GroupProduct/${id}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {}
}

export default groupProducts;
