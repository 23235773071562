<template>
  <div>
    <div>
      <content-header>{{ $t('createScreenSizeToProduct') }}</content-header>
      <v-content>
        <b-card>
          <b-card-body>
            <form class="forms-sample" @submit.prevent="onSubmit">
              <select-input
                  id="screenSize"
                  :name="$t('screenSize')"
                  :validate="$v"
                  :required="true"
                  api-link="productScreenSizes/GET_ALL"
                  label="name"
                  @itemSelected="e => form.screenSizeId = e"
              />
              <number-input id="extraPrice" :name="$t('extraPrice')" :validate="$v" :default-value="0" :is-loading="isLoading" />
              <text-input id="feature1" :name="$t('feature1')" :validate="$v" :is-loading="isLoading" />
              <text-input id="feature2" :name="$t('feature2')" :validate="$v" :is-loading="isLoading" />
              <text-input id="feature3" :name="$t('feature3')" :validate="$v" :is-loading="isLoading" />
              <text-input id="feature4" :name="$t('feature4')" :validate="$v" :is-loading="isLoading" />
              <submit-input :name="$t('create')" :disabled="isLoading"/>
            </form>
          </b-card-body>
        </b-card>
      </v-content>
    </div>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import {ScreenSizeToProduct} from "@/models/ScreenSizeToProduct";
import SubmitInput from "@/components/Inputs/SubmitInput";
import TextInput from "@/components/Inputs/TextInput";
import NumberInput from "@/components/Inputs/NumberInput";
import SelectInput from "@/components/Inputs/SelectInput";

export default {
  name: "ScreenSizeToProductCreate",
  components: {SelectInput, NumberInput, TextInput, SubmitInput, ContentHeader, 'v-content': Content},
  data() {
    return {
      form: this.$helpers.convertArrayToObject(new ScreenSizeToProduct().fillAbles),
      isLoading: false
    }
  },
  validations: {
    form: {
      extraPrice: {},
      feature1: {},
      feature2: {},
      feature3: {},
      feature4: {},
    }
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return false;
      this.form.productId = this.$route.params.id;
      this.isLoading = true;
      this.$store.dispatch('screenSizesToProduct/CREATE', this.form).then(() => {
        this.$v.$reset();
        this.$router.push({name: 'products.edit', params: {id: this.$route.params.id}})
      }).catch(() => this.isLoading = false);
    },
  }
}
</script>
