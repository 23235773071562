export default Object.freeze({
  NoData: "NoData",
  AvailableDiskSizes: "AvailableDiskSizes",
  AvailableRamSizes: "AvailableRamSizes",
  ScreenSizes: 12,
  AvailableColors: "AvailableColors",
  Brand: "Brand",
  ProductType: "ProductType",
  InstallmentMethod: "InstallmentMethod",
  Tag: "Tag",
  Collection: "Collection",
  Group: "Group",
  MadeIn: 13,
});
