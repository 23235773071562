<template>
  <div>
    <content-header>{{ $t("productTypeUser") }}</content-header>
    <v-content>
      <select-input
          :is-loaded="isLoading"
          id="users"
          :name="$t('users')"
          :required="true"
          api-link="users/GET_ALL"
          label="firstName,middleName,lastName"
          @itemSelected="e => this.selectedUserId = e"
      />
      <div class="row">
        <div class="col-6">
          <select-input
              :is-loaded="isLoading"
              id="departments"
              :name="$t('departments')"
              :required="true"
              api-link="productTypes/GET_ALL"
              label="name"
              @itemSelected="e => this.selectedDepartment = e"
          />
        </div>
        <div class="col-6">
          <select-input
              :is-loaded="isLoading"
              id="sections"
              :name="$t('sections')"
              :required="true"
              api-link="productTypes/GET_ALL"
              label="name"
              :api-params="{ id: this.selectedDepartment }"
              @itemSelected="e => this.selectedSection = e"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="" v-for="category in categories" :key="category.id">
            <div class="card card-primary">
              <h3 class="card-header">{{ category.name }}</h3>
              <div class="card-body">
                <FamilyPart :category="category.id" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <button class="btn btn-success btn-block btn-lg" @click="submit">Save</button>
        </div>
      </div>
      <is-loading v-if="isLoading" />
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import IsLoading from "@/components/IsLoading";
import {serverParams} from "@/plugins/constants";
import SelectInput from "@/components/Inputs/SelectInput";
import FamilyPart from "@/views/productTypeUser/FamilyPart";

export default {
  name: "ProductTypeUserList",
  components: {FamilyPart, SelectInput, IsLoading, "v-content": Content, ContentHeader},
  data() {
    return {
      isLoading: false,
      totalRecords: 0,
      serverParams: serverParams,
      selectedUserId: 0,
      selectedDepartment: 0,
      selectedSection: 0,
      categories: []
    };
  },
  watch: {
    selectedSection: {
      handler: function() {
        this.getCategories();
      },
      deep: true
    },
  },
  methods: {
    loadData() {
      if (this.selectedUserId !== 0) {
        this.isLoading = true;
        this.$store
          .dispatch("productTypeUser/GET_ALL", this.serverParams)
          .then((response) => {
            this.rows = response.data;
            this.totalRecords = parseInt(response.headers["x-total-count"]);
            this.isLoading = false;
          })
          .catch(() => (this.isLoading = false));
      }
    },
    onChangeQuery(queryParams) {
      serverParams._start = (queryParams.page - 1) * queryParams.per_page;
      serverParams._end = queryParams.page * queryParams.per_page;
      serverParams._sort = queryParams.sort[0] ? queryParams.sort[0].name : "";
      serverParams._order = queryParams.sort[0] ? queryParams.sort[0].order : "";
      serverParams._search = queryParams.global_search ?? "";
      this.loadData();
    },
    getCategories() {
      let serverParameters = {
        _start: 0,
        _end: 50000,
        _sort: 'id',
        _order: 'ASC',
        _search: '',
        custom_params: { id: this.selectedSection }
      }
      this.$store
          .dispatch("productTypes/GET_ALL", serverParameters)
          .then((response) => {
            this.categories = response.data;
          })
          .catch(() => (this.isLoading = false));
    },
    submit() {
      console.log('Form Submitted');
      let inputs = document.querySelectorAll('input[name=familyCheckbox]:checked');
      for(let i = 0; i < inputs.length; i++) {
        console.log(inputs[i], 'Done');
      }
    }
  },
};
</script>
