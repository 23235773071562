import {HTTP} from "@/plugins/axios";
import mixin from "@/mixins";

const productAttachments = {
    namespaced: true,
    state: {
        createdProductAttachment: {},
    },
    mutations: {
        SET_CREATED_PRODUCT_ATTACHMENT (state, value) {state.createdProductAttachment = value},
    },
    actions: {
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.post(`/Products/ProductAttachment`, form)
                    .then((response) => {
                        commit('SET_CREATED_PRODUCT_ATTACHMENT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_PRODUCT_ATTACHMENT', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/Products/ProductAttachment/${ids}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
        TOGGLE_DATA(commit, data) {
            return new Promise((resolve, reject) => {
                HTTP.post(`/Products/ToggleData${data}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        createdProductAttachment: state => state.createdProductAttachment,
    }
}

export default productAttachments;
