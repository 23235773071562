export default Object.freeze({
  Default: "Default",
  FrontPageSlider: "FrontPageSlider",
  TopSlider: "TopSlider",
  NewSeason: "NewSeason",
  FeaturedCollection: "FeaturedCollection",
  MostSale: "MostSale",
  Take3Pay2: "Take3Pay2",
  Ads: "Ads",
  NewArrivals: "NewArrivals",
  Discount: "Discount",
});
