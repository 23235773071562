<template>
  <div>
    <content-header>{{ $t('createTag') }}</content-header>
    <v-content>
      <b-card>
        <b-card-body>
          <form class="forms-sample" @submit.prevent="onSubmit">
            <text-input id="name" :name="$t('name')" :validate="$v" :required="true" :max-length="true" :is-loading="isLoading" />
            <text-input id="kuName" :name="$t('kuName')" :validate="$v" :required="true" :max-length="true" :is-loading="isLoading" />
            <text-input id="arName" :name="$t('arName')" :validate="$v" :required="true" :max-length="true" :is-loading="isLoading" />
            <file-input
                id="attachment"
                :name="$t('attachment')"
                @fileAdded="base64File => form.attachment = base64File"
            />
            <submit-input :name="$t('create')" :disabled="isLoading"/>
            <is-loading v-if="isLoading" />
          </form>
        </b-card-body>
      </b-card>
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import { required, maxLength } from 'vuelidate/lib/validators'
import IsLoading from "@/components/IsLoading";
import SubmitInput from "@/components/Inputs/SubmitInput";
import TextInput from "@/components/Inputs/TextInput";
import {Tag} from "@/models/Tag";
import FileInput from "@/components/Inputs/FileInput";

export default {
  name: "TagCreate",
  components: {FileInput, TextInput, SubmitInput, ContentHeader, 'v-content': Content, IsLoading},
  data() {
    return {
      form: this.$helpers.convertArrayToObject(new Tag().fillAbles),
      isLoading: false,
      selectedItem: {},
    }
  },
  validations: {
    form: {
      name: {required, maxLength: maxLength(255)},
      kuName: {required, maxLength: maxLength(255)},
      arName: {required, maxLength: maxLength(255)},
      attachment: {},
    }
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return false;
      this.isLoading = true;
      this.$store.dispatch('tags/CREATE', this.form).then(() => {
        this.$v.$reset();
        this.$router.push({name: "tags"})
      }).catch(() => this.isLoading = false);
    },
  }
}
</script>
