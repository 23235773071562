import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import dashboard from "./modules/dashboard";
import language from "./modules/language";
import productSizes from "./modules/productSizes";
import productColors from "@/store/modules/productColors";
import productRamSizes from "@/store/modules/productRamSizes";
import brands from "@/store/modules/brands";
import productTypes from "@/store/modules/productTypes";
import installmentMethods from "@/store/modules/installmentMethods";
import customers from "@/store/modules/customers";
import customerAttachments from "@/store/modules/customerAttachments";
import products from "@/store/modules/products";
import productAttachments from "@/store/modules/productAttachments";
import colorsToProduct from "@/store/modules/colorsToProduct";
import diskSizesToProduct from "@/store/modules/diskSizesToProduct";
import ramSizesToProduct from "@/store/modules/ramSizesToProduct";
import contracts from "@/store/modules/contracts";
import featuresToProduct from "@/store/modules/featuresToProduct";
import installmentMethodsToProduct from "@/store/modules/installmentMethodsToProduct";
import pricesToProduct from "@/store/modules/pricesToProduct";
import branches from "@/store/modules/branches";
import tags from "@/store/modules/tags";
import suppliers from "@/store/modules/suppliers";
import supplierAttachments from "@/store/modules/supplierAttachments";
import screenSizesToProduct from "@/store/modules/screenSizesToProduct";
import productScreenSizes from "@/store/modules/productScreenSizes";
import productsToCollection from "@/store/modules/productsToCollection";
import frontPageSliders from "@/store/modules/frontPageSliders";
import topSliders from "@/store/modules/topSliders";
import mostSales from "@/store/modules/mostSales";
import newSeason from "@/store/modules/newSeason";
import featuredCollections from "@/store/modules/featuredCollections";
import takePay from "@/store/modules/takePay";
import groupProducts from "@/store/modules/groupProduct";
import ads from "@/store/modules/ads";
import collections from "@/store/modules/collections";
import madeIn from "@/store/modules/madeIn";
import invoices from "@/store/modules/invoices";
import newArrivals from "@/store/modules/newArrivals";
import discount from "@/store/modules/discount";
import productTypeUser from "@/store/modules/productTypeUser";
import users from "@/store/modules/users";
import currencies from "@/store/modules/currencies";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    dashboard,
    language,
    productSizes,
    productRamSizes,
    productColors,
    brands,
    productTypes,
    installmentMethods,
    customers,
    customerAttachments,
    products,
    productAttachments,
    colorsToProduct,
    diskSizesToProduct,
    ramSizesToProduct,
    featuresToProduct,
    installmentMethodsToProduct,
    pricesToProduct,
    contracts,
    branches,
    tags,
    suppliers,
    supplierAttachments,
    screenSizesToProduct,
    productScreenSizes,
    collections,
    productsToCollection,
    frontPageSliders,
    topSliders,
    mostSales,
    newSeason,
    featuredCollections,
    takePay,
    groupProducts,
    ads,
    madeIn,
    invoices,
    newArrivals,
    discount,
    productTypeUser,
    users,
    currencies
  },
});
