<template>
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0 text-dark">
            <slot/>
          </h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContentHeader"
}
</script>

<style scoped>

</style>
