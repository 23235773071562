import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import ProductAttributes from "@/enums/ProductAttributes";
import mixin from "@/mixins";

const installmentMethods = {
    namespaced: true,
    state: {
        installmentMethods: {},
        createdInstallmentMethods: {},
        getOneInstallmentMethods: {},
        updatedInstallmentMethods: {},
    },
    mutations: {
        SET_INSTALLMENT_METHODS (state, value) { state.installmentMethods = value },
        SET_CREATED_INSTALLMENT_METHOD (state, value) {state.createdInstallmentMethods = value},
        SET_GET_ONE_INSTALLMENT_METHOD (state, value) {state.getOneInstallmentMethods = value},
        SET_UPDATED_INSTALLMENT_METHOD (state, value) {state.updatedInstallmentMethods = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/Main` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}` +
                        `&objectName=` + ProductAttributes.InstallmentMethod)
                    .then((response) => {
                        commit('SET_INSTALLMENT_METHODS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_INSTALLMENT_METHODS', {})
                        reject(error)
                    });
            })
        },
        GET_ONE({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/Main/${id}?objectName=` + ProductAttributes.InstallmentMethod)
                    .then((response) => {
                        commit('SET_GET_ONE_INSTALLMENT_METHOD', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GET_ONE_INSTALLMENT_METHOD', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.post(`/Main?objectName=` + ProductAttributes.InstallmentMethod, form)
                    .then((response) => {
                        commit('SET_CREATED_INSTALLMENT_METHOD', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_INSTALLMENT_METHOD', {})
                        reject(error)
                    });
            })
        },
        UPDATE({commit}, {id, form}) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.put(`/Main/${id}?objectName=` + ProductAttributes.InstallmentMethod, form)
                    .then((response) => {
                        commit('SET_UPDATED_INSTALLMENT_METHOD', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_UPDATED_INSTALLMENT_METHOD', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/Main/${ids}?objectName=` + ProductAttributes.InstallmentMethod)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        installmentMethods: state => state.installmentMethods,
        createdInstallmentMethods: state => state.createdInstallmentMethods,
        getOneInstallmentMethods: state => state.getOneInstallmentMethods,
        updatedInstallmentMethods: state => state.updatedInstallmentMethods,
    }
}

export default installmentMethods;
