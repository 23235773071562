import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import mixin from "@/mixins";
import ProductAttributes from "@/enums/ProductAttributes";
import GroupTypes from "@/enums/GroupTypes";

const topSliders = {
    namespaced: true,
    state: {
        topSliders: {},
        createdTopSlider: {},
        getOneTopSlider: {},
        updatedTopSlider: {},
    },
    mutations: {
        SET_TOP_SLIDERS (state, value) { state.topSliders = value },
        SET_CREATED_TOP_SLIDER (state, value) {state.createdTopSlider = value},
        SET_GET_ONE_TOP_SLIDER (state, value) {state.getOneTopSlider = value},
        SET_UPDATED_TOP_SLIDER (state, value) {state.updatedTopSlider = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/Main` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}` +
                        `&objectName=${ProductAttributes.Group}&groupType=${GroupTypes.TopSlider}`)
                    .then((response) => {
                        commit('SET_TOP_SLIDERS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_TOP_SLIDERS', {})
                        reject(error)
                    });
            })
        },
        GET_ONE({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/Main/${id}?objectName=${ProductAttributes.Group}`)
                    .then((response) => {
                        commit('SET_GET_ONE_TOP_SLIDER', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GET_ONE_TOP_SLIDER', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.post(`/Main?objectName=${ProductAttributes.Group}`, form)
                    .then((response) => {
                        commit('SET_CREATED_TOP_SLIDER', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_TOP_SLIDER', {})
                        reject(error)
                    });
            })
        },
        UPDATE({commit}, {id, form}) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.put(`/Main/${id}?objectName=${ProductAttributes.Group}`, form)
                    .then((response) => {
                        commit('SET_UPDATED_TOP_SLIDER', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_UPDATED_TOP_SLIDER', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/Main/${ids}?objectName=${ProductAttributes.Group}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        topSliders: state => state.topSliders,
        createdTopSlider: state => state.createdTopSlider,
        getOneTopSlider: state => state.getOneTopSlider,
        updatedTopSlider: state => state.updatedTopSlider,
    }
}

export default topSliders;
