import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import ProductAttributes from "@/enums/ProductAttributes";
import mixin from "@/mixins";

const productSizes = {
    namespaced: true,
    state: {
        productSizes: {},
        createdProductSize: {},
        getOneProductSize: {},
        updatedProductSize: {},
    },
    mutations: {
        SET_PRODUCT_SIZES (state, value) { state.productSizes = value },
        SET_CREATED_PRODUCT_SIZE (state, value) {state.createdProductSize = value},
        SET_GET_ONE_PRODUCT_SIZE (state, value) {state.getOneProductSize = value},
        SET_UPDATED_PRODUCT_SIZE (state, value) {state.updatedProductSize = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/Main` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}` +
                        `&objectName=` + ProductAttributes.AvailableDiskSizes)
                    .then((response) => {
                        commit('SET_PRODUCT_SIZES', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_PRODUCT_SIZES', {})
                        reject(error)
                    });
            })
        },
        GET_ONE({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/Main/${id}?objectName=` + ProductAttributes.AvailableDiskSizes)
                    .then((response) => {
                        commit('SET_GET_ONE_PRODUCT_SIZE', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GET_ONE_PRODUCT_SIZE', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.post(`/Main?objectName=` + ProductAttributes.AvailableDiskSizes, form)
                    .then((response) => {
                        commit('SET_CREATED_PRODUCT_SIZE', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_PRODUCT_SIZE', {})
                        reject(error)
                    });
            })
        },
        UPDATE({commit}, {id, form}) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.put(`/Main/${id}?objectName=` + ProductAttributes.AvailableDiskSizes, form)
                    .then((response) => {
                        commit('SET_UPDATED_PRODUCT_SIZE', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_UPDATED_PRODUCT_SIZE', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/Main/${ids}?objectName=` + ProductAttributes.AvailableDiskSizes)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        productSizes: state => state.productSizes,
        createdProductSize: state => state.createdProductSize,
        getOneProductSize: state => state.getOneProductSize,
        updatedProductSize: state => state.updatedProductSize,
    }
}

export default productSizes;
