<template>
  <div>
    <v-content>
      <b-button
          variant="outline-primary"
          class="float-right"
          @click.prevent="$router.push({ name: 'products.prices.create', params: {id: $route.params.id} })"
      >
        <i class="fas fa-plus-circle"></i> {{ $t('create') }}
      </b-button>
      <table class="table table-striped">
        <thead>
        <tr>
          <th scope="col">{{ $t('sl') }}</th>
          <th scope="col">{{ $t('branchName') }}</th>
          <th scope="col">{{ $t('price') }}</th>
          <th scope="col">{{ $t('discount') }}</th>
          <th scope="col">{{ $t('controls') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(price, index) in data" :key="index">
          <td>{{ ++index }}</td>
          <td>{{ price.branchName }}</td>
          <td>{{ price.price }}</td>
          <td>{{ price.discount }}</td>
          <td>
            <button class="btn btn-outline-danger ml-1" @click="deleteFeature(price.id)"><i class="fal fa-trash"></i></button>
          </td>
        </tr>
        </tbody>
      </table>
    </v-content>
    <is-loading v-if="isLoading"/>
  </div>
</template>

<script>
import Content from "@/components/Content";
import isLoading from "@/components/IsLoading";
import {actions, swalMixin} from "@/plugins/constants";

export default {
  name: "PriceToProductList",
  components: {'v-content': Content, isLoading},
  data() {
    return {
      isLoading: false,
      data: [],
    };
  },
  props: {
    pricesToProduct: {
      type: Array
    }
  },
  watch: {
    pricesToProduct: function (value) {
      this.data = value
    }
  },
  methods: {
    async deleteFeature(priceId) {
      if (await actions('pricesToProduct/DELETE', priceId, 'delete')) {
        swalMixin('success', this.$t('deletedSuccessfully'))
        this.$emit('loadAll')
      }
    },
    available(priceId) {
      this.isLoading = true;
      this.$store.dispatch('pricesToProduct/DELETE', priceId).then(() => {
        swalMixin('success', this.$t('deletedSuccessfully'))
        this.isLoading = false;
        this.$emit('loadAll')
      }).catch(() => this.isLoading = false);
    }
  }
}
</script>
