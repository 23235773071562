<template>
  <div>
    <content-header>{{ $t('editProductScreenSize') }}</content-header>
    <v-content>
      <b-card>
        <b-card-body>
          <form class="forms-sample" @submit.prevent="onSubmit">
            <text-input id="name" :name="$t('name')" :validate="$v" required :is-loading="isLoading" />
            <text-input id="size" :name="$t('size')" :validate="$v" required :is-loading="isLoading" />
            <submit-input icon="fa-save" :name="$t('save')" :disabled="isLoading"/>
            <is-loading v-if="isLoading" />
          </form>
        </b-card-body>
      </b-card>
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import { required } from 'vuelidate/lib/validators'
import IsLoading from "@/components/IsLoading";
import {ProductScreenSize} from "@/models/ProductScreenSize";
import SubmitInput from "@/components/Inputs/SubmitInput";
import TextInput from "@/components/Inputs/TextInput";

export default {
  name: "ProductScreenSizeEdit",
  components: {SubmitInput, ContentHeader, 'v-content': Content, IsLoading, TextInput},
  data() {
    return {
      form: this.$helpers.convertArrayToObject(new ProductScreenSize().fillAbles),
      isLoading: false,
      selectedItem: {},
    };
  },
  mounted() {
    this.isLoading = true;
    this.$store.dispatch('productScreenSizes/GET_ONE', this.$route.params.id).then((response) => {
      this.form       = response.data;
      this.isLoading  = false;
    }).catch(() => this.isLoading = false)
  },
  validations: {
    form: {
      name: {required},
      kuName: {required},
      arName: {required},
      size: {required},
      note: {},
    }
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return false;
      this.isLoading = true;
      this.$store.dispatch('productScreenSizes/UPDATE', {id: this.$route.params.id, form: this.form}).then(() => {
        this.$v.$reset();
        this.$router.push({name: "productScreenSizes"})
      }).catch(() => this.isLoading = false);
    }
  }
}
</script>
