import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import mixin from "@/mixins";
import ProductAttributes from "@/enums/ProductAttributes";
import GroupTypes from "@/enums/GroupTypes";

const takePay = {
    namespaced: true,
    state: {
        takePay: {},
        createdTakePay: {},
        getOneTakePay: {},
        updatedTakePay: {},
    },
    mutations: {
        SET_TAKE_3_PAY_2 (state, value) { state.takePay = value },
        SET_CREATED_TAKE_3_PAY_2 (state, value) {state.createdTakePay = value},
        SET_GET_ONE_TAKE_3_PAY_2 (state, value) {state.getOneTakePay = value},
        SET_UPDATED_TAKE_3_PAY_2 (state, value) {state.updatedTakePay = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/Main` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}` +
                        `&objectName=${ProductAttributes.Group}&groupType=${GroupTypes.Take3Pay2}`)
                    .then((response) => {
                        commit('SET_TAKE_3_PAY_2', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_TAKE_3_PAY_2', {})
                        reject(error)
                    });
            })
        },
        GET_ONE({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/Main/${id}?objectName=${ProductAttributes.Group}`)
                    .then((response) => {
                        commit('SET_GET_ONE_TAKE_3_PAY_2', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GET_ONE_TAKE_3_PAY_2', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.post(`/Main?objectName=${ProductAttributes.Group}`, form)
                    .then((response) => {
                        commit('SET_CREATED_TAKE_3_PAY_2', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_TAKE_3_PAY_2', {})
                        reject(error)
                    });
            })
        },
        UPDATE({commit}, {id, form}) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.put(`/Main/${id}?objectName=${ProductAttributes.Group}`, form)
                    .then((response) => {
                        commit('SET_UPDATED_TAKE_3_PAY_2', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_UPDATED_TAKE_3_PAY_2', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/Main/${ids}?objectName=${ProductAttributes.Group}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        takePay: state => state.takePay,
        createdTakePay: state => state.createdTakePay,
        getOneTakePay: state => state.getOneTakePay,
        updatedTakePay: state => state.updatedTakePay,
    }
}

export default takePay;
