<template>
  <div>
    <content-header>{{ $t('createContract') }}</content-header>
    <v-content>
      <b-card>
        <b-card-body>
          <form class="forms-sample" @submit.prevent="onSubmit">
            <text-input id="contractNumber" :name="$t('contractNumber')" :validate="$v" :is-loading="isLoading" />
            <select-input
                id="customer"
                :name="$t('customer')"
                :validate="$v"
                :required="true"
                api-link="customers/GET_ALL"
                label="firstName,middleName,lastName,+phone"
                @itemSelected="e => form.customerId = e"
            />
            <select-input
                id="product"
                :name="$t('product')"
                :validate="$v"
                :required="true"
                api-link="products/GET_ALL"
                label="name"
                :return-object="true"
                @itemSelected="productSelected"
            />
            <select-input
                v-if="availableColors.length > 0"
                id="color"
                :name="$t('color')"
                :validate="$v"
                :required="true"
                :api-link="availableColors"
                label="name,+extraPrice"
                :return-object="true"
                @itemSelected="colorSelected"
            />
            <select-input
                v-if="availableDiskSizes.length > 0"
                id="diskSize"
                :name="$t('diskSize')"
                :validate="$v"
                :required="true"
                :api-link="availableDiskSizes"
                label="name,+extraPrice"
                :return-object="true"
                @itemSelected="diskSelected"
            />
            <select-input
                v-if="availableRamSizes.length > 0"
                id="ramSize"
                :name="$t('ramSize')"
                :validate="$v"
                :required="true"
                :api-link="availableRamSizes"
                label="name,+extraPrice"
                :return-object="true"
                @itemSelected="ramSizeSelected"
            />
            <number-input id="price" :name="$t('price')" :validate="$v" :required="true" :readonly="true" :is-loading="isLoading" />
            <number-input id="extraPrice" :name="$t('extraPrice')" :validate="$v" :default-value="0" :is-loading="isLoading" />
            <number-input id="discount" :name="$t('discount')" :validate="$v" :default-value="0" :is-loading="isLoading" />
            <div class="form-group row">
              <label class="col-sm-2 col-form-label text-right">{{ $t('totalPrice') }}</label>
              <div class="col-sm-10">
                <p class="lead pt-1">{{ itemPrice.toLocaleString() }}</p>
              </div>
            </div>
            <select-input
                id="installmentMethod"
                :name="$t('installmentMethod')"
                :validate="$v"
                :required="true"
                api-link="installmentMethods/GET_ALL"
                label="name"
                :return-object="true"
                @itemSelected="installmentMethodSelected"
            />
            <table class="table table-bordered">
              <thead>
              <tr>
                <th>{{ $t('index') }}</th>
                <th>{{ $t('price') }}</th>
                <th>{{ $t('payDate') }}</th>
                <th>{{ $t('deadline') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>{{ $t('prePayment') }}</td>
                <td>
                  <b-input-group prepend="IQD">
                    <b-form-input type="number" v-model="prePaymentPrice" @focus="$event.target.select()" step=".0001"></b-form-input>
                  </b-input-group>
                </td>
                <td>
                  <b-input-group :prepend="$t('payDate')">
                    <b-form-input type="date" v-model="prePaymentDate"></b-form-input>
                  </b-input-group>
                </td>
                <td>
                  <b-input-group :prepend="$t('deadline')">
                    <b-form-input readonly type="date" :value="moment().format('YYYY-MM-DD')"></b-form-input>
                  </b-input-group>
                </td>
              </tr>
              <!-- Loop Here -->
              <tr v-for="(payment, index) in payments" v-bind:key="index">
                <td>{{ ++index }}</td>
                <td>
                  <b-input-group prepend="IQD">
                    <b-form-input type="number" v-model="payment.mcPrice" @focus="$event.target.select()" step=".0001"></b-form-input>
                  </b-input-group>
                </td>
                <td>
                  <b-input-group :prepend="$t('payDate')">
                    <b-form-input type="date" v-model="payment.payDate"></b-form-input>
                  </b-input-group>
                </td>
                <td>
                  <b-input-group :prepend="$t('deadline')">
                    <b-form-input type="date" v-model="payment.deadline"></b-form-input>
                  </b-input-group>
                </td>
              </tr>
              <!-- Loop Here -->
              </tbody>
            </table>
            <submit-input :name="$t('create')" :disabled="isLoading"/>
            <is-loading v-if="isLoading" />
          </form>
        </b-card-body>
      </b-card>
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import { required } from 'vuelidate/lib/validators'
import IsLoading from "@/components/IsLoading";
import SubmitInput from "@/components/Inputs/SubmitInput";
import TextInput from "@/components/Inputs/TextInput";
import {Contract} from "@/models/Contract";
import NumberInput from "@/components/Inputs/NumberInput";
import SelectInput from "@/components/Inputs/SelectInput";
import moment from "moment";
import _ from "lodash";
import Swal from "sweetalert2";

export default {
  name: "ContractsCreate",
  components: {SelectInput, NumberInput, TextInput, SubmitInput, ContentHeader, 'v-content': Content, IsLoading},
  data() {
    return {
      form: this.$helpers.convertArrayToObject(new Contract().fillAbles),
      isLoading: false,
      selectedItem: {},
      product: {},
      itemPrice: 0,
      prePaymentPrice: 0,
      prePaymentDate: moment().format('YYYY-MM-DD'),
      payments: [],
      availableColors: [],
      availableDiskSizes: [],
      availableRamSizes: [],
      installmentMethods: [],
      selectedInstallmentMethod: {}
    }
  },
  watch: {
    form: {
      handler() {
        this.calculateTotalPrice();
        this.prePaymentPrice = this.itemPrice * this.selectedInstallmentMethod.prePaymentRate;
      },
      deep: true
    },
    prePaymentPrice: {
      handler() {
        this.calculateInstallments();
      },
      deep: true
    }
  },
  validations: {
    form: {
      contractNumber: {},
      customerId: {required},
      productId: {required},
      colorId: {},
      diskSizeId: {},
      ramSizeId: {},
      price: {required},
      discount: {},
      extraPrice: {}
    }
  },
  methods: {
    onSubmit() {
      let customPayments = _.clone(this.payments);
      _.map(customPayments, function (item) {
        delete item.index;
        item.paymentType = 1;
        return item;
      })
      customPayments.push({
        mcPrice: this.prePaymentPrice,
        scPrice: 0,
        byMain: true,
        isPaid: true,
        paymentType: 0,
        payDate: this.prePaymentDate,
        deadline: null
      });
      let sum = this._.sumBy(customPayments, (val) => {
        return parseFloat(val.mcPrice);
      });
      if (parseFloat(sum) !== this.itemPrice) {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: this.$t('pleaseCheckPayments'),
          showConfirmButton: false,
          timer: 1500
        });
        return false;
      }
      this.form.paymentProcesses = customPayments;
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return false;
      this.isLoading = true;
      this.$store.dispatch('contracts/CREATE', this.form).then(() => {
        this.$v.$reset();
        this.$router.push({name: "contracts"})
      }).catch(() => this.isLoading = false);
    },
    productSelected(e) {
      this.form.productId = e.id;
      this.$store.dispatch('products/GET_ONE', e.id).then((response) => {
        this.product            = response.data;
        this.availableColors    = response.data.availableColors;
        this.availableDiskSizes = response.data.availableDiskSizes;
        this.availableRamSizes  = response.data.availableRamSizes;
        this.installmentMethods = response.data.installmentMethods;
        this.form.price         = parseFloat(response.data.price) - parseFloat(response.data.discount);
        this.itemPrice          = this.form.price;
        this.isLoading          = false;
      }).catch(() => this.isLoading = false)
    },
    colorSelected(e) {
      this.form.colorId = e.colorId;
      this.calculateTotalPrice();
    },
    diskSelected(e) {
      this.form.diskSizeId = e.diskSizeId;
      this.calculateTotalPrice();
    },
    ramSizeSelected(e) {
      this.form.ramSizeId = e.ramSizeId;
      this.calculateTotalPrice();
    },
    installmentMethodSelected(e) {
      this.selectedInstallmentMethod = e;
      this.calculateTotalPrice();
      this.calculateInstallments();
    },
    calculateTotalPrice() {
      let purePrice = (parseFloat(this.form.price) + parseFloat(this.form.extraPrice)) - parseFloat(this.form.discount);
      let selectedColor     = _.find(this.availableColors, (item) => item.colorId === this.form.colorId);
      let selectedDiskSize  = _.find(this.availableDiskSizes, (item) => item.diskSizeId === this.form.diskSizeId);
      let selectedRamSize   = _.find(this.availableRamSizes, (item) => item.ramSizeId === this.form.ramSizeId);
      purePrice += (((selectedColor && selectedColor.extraPrice) ?? 0) +
          ((selectedDiskSize && selectedDiskSize.extraPrice) ?? 0) +
          ((selectedRamSize && selectedRamSize.extraPrice) ?? 0));
      this.itemPrice = purePrice;
      this.prePaymentPrice = this.itemPrice * this.selectedInstallmentMethod.prePaymentRate;
    },
    calculateInstallments() {
      let remainPrice = this.itemPrice - this.prePaymentPrice;
      let customInstallments = [];
      for (let i = 1; i <= this.selectedInstallmentMethod.paymentCount; i++) {
        customInstallments.push({
          index: i,
          mcPrice: remainPrice / this.selectedInstallmentMethod.paymentCount,
          scPrice: 0,
          byMain: true,
          isPaid: false,
          payDate: moment('2021-12-29').add(this.selectedInstallmentMethod.paymentPeriodDays * i, 'd').format('YYYY-MM-DD'),
          deadline: moment('2021-12-29').add((this.selectedInstallmentMethod.paymentPeriodDays * i) + 5, 'd').format('YYYY-MM-DD')
        });
      }
      this.payments = customInstallments;
    }
  }
}
</script>
