import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import ProductAttributes from "@/enums/ProductAttributes";
import mixin from "@/mixins";

const tags = {
    namespaced: true,
    state: {
        tags: {},
        createdTag: {},
        getOneTag: {},
        updatedTag: {},
    },
    mutations: {
        SET_TAGS (state, value) { state.tags = value },
        SET_CREATED_TAG (state, value) {state.createdTag = value},
        SET_GET_ONE_TAG (state, value) {state.getOneTag = value},
        SET_UPDATED_TAG (state, value) {state.updatedTag = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/Main` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}` +
                        `&objectName=` + ProductAttributes.Tag)
                    .then((response) => {
                        commit('SET_TAGS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_TAGS', {})
                        reject(error)
                    });
            })
        },
        GET_ONE({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/Main/${id}?objectName=` + ProductAttributes.Tag)
                    .then((response) => {
                        commit('SET_GET_ONE_TAG', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GET_ONE_TAG', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.post(`/Main?objectName=` + ProductAttributes.Tag, form)
                    .then((response) => {
                        commit('SET_CREATED_TAG', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_TAG', {})
                        reject(error)
                    });
            })
        },
        UPDATE({commit}, {id, form}) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.put(`/Main/${id}?objectName=` + ProductAttributes.Tag, form)
                    .then((response) => {
                        commit('SET_UPDATED_TAG', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_UPDATED_TAG', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/Main/${ids}?objectName=` + ProductAttributes.Tag)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        tags: state => state.tags,
        createdTag: state => state.createdTag,
        getOneTag: state => state.getOneTag,
        updatedTag: state => state.updatedTag,
    }
}

export default tags;
