<template>
  <div>
    <b-button
        variant="outline-primary"
        class="float-right"
        @click.prevent="$router.push({ name: 'new.arrivals.products.create', params: {id: $route.params.id} })"
    >
      <i class="fas fa-plus-circle"></i> {{ $t('create') }}
    </b-button>
    <table class="table table-bordered table-striped">
      <thead>
      <tr>
        <th>{{ $t('code') }}</th>
        <th>{{ $t('name') }}</th>
        <th>{{ $t('kurdishName') }}</th>
        <th>{{ $t('arabicName') }}</th>
        <th>{{ $t('price') }}</th>
        <th>{{ $t('discount') }}</th>
        <th>{{ $t('remove') }}</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="product in allProducts" :key="product.id">
        <td>{{ product.product.code }}</td>
        <td>{{ product.product.name }}</td>
        <td>{{ product.product.kuName }}</td>
        <td>{{ product.product.arName }}</td>
        <td>{{ product.product.price.toLocaleString() }}</td>
        <td>{{ (product.product.discount / (product.product.price + product.product.discount)) * 100 }}</td>
        <td>
          <button class="btn btn-danger btn-sm" @click="removeProduct(product.id)">
            <b-icon icon="trash" size="is-small"></b-icon>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import {i18n} from "@/plugins/i18n";

export default {
  name: "NewArrivalsGroupProductList",
  components: {},
  props: {
    products: {
      type: Array
    }
  },
  watch: {
    products: function (value) {
      this.allProducts = value
    }
  },
  data() {
    return {
      allProducts: this.products,
      isLoading: false
    }
  },
  methods: {
    async removeProduct(groupProductId) {
      await Swal.fire({
        title: i18n.t('areYouSure'),
        text: i18n.t(`youWantToDeletePermanentlySelectedRows`),
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: i18n.t('noCancel'),
        confirmButtonText: i18n.t(`yesDelete`)
      }).then(async (result) => {
        if (result.value) {
          this.isLoading = true;
          this.$store.dispatch('groupProducts/REMOVE_PRODUCT_TO_GROUP', groupProductId).then(() => {
            this.isLoading = false;
            this.$emit('refreshData');
          }).catch(() => this.isLoading = false);
        }
      });
    },
  }
}
</script>
