<template>
  <div>
    <div>
      <content-header>{{ $t('createSupplierAttachment') }}</content-header>
      <v-content>
        <b-card>
          <b-card-body>
            <form class="forms-sample" @submit.prevent="onSubmit">
              <text-input id="name" :name="$t('name')" :validate="$v" :required="true" :is-loading="isLoading" />
              <text-input id="kuName" :name="$t('kuName')" :validate="$v" :is-loading="isLoading" />
              <text-input id="arName" :name="$t('arName')" :validate="$v" :is-loading="isLoading" />
              <file-input
                  id="attachment"
                  :required="true"
                  :name="$t('attachment')"
                  @fileAdded="base64File => form.attachment = base64File"
              />
              <boolean-input
                  id="IsShareable"
                  :name="$t('sharable')"
                  :default-value="false"
                  :checked-label="$t('yes')"
                  :un-checked-label="$t('no')"
                  @switched="e => form.shareable = e"
              />
              <submit-input :name="$t('create')" :disabled="isLoading"/>
              <is-loading v-if="isLoading" />
            </form>
          </b-card-body>
        </b-card>
      </v-content>
    </div>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import { required } from 'vuelidate/lib/validators'
import IsLoading from "@/components/IsLoading";
import {SupplierAttachment} from "@/models/SupplierAttachment";
import SubmitInput from "@/components/Inputs/SubmitInput";
import FileInput from "@/components/Inputs/FileInput";
import BooleanInput from "@/components/Inputs/BooleanInput";
import TextInput from "@/components/Inputs/TextInput";

export default {
  name: "SupplierAttachmentCreate",
  components: {SubmitInput, IsLoading, ContentHeader, 'v-content': Content, FileInput, BooleanInput, TextInput},
  data() {
    return {
      form: this.$helpers.convertArrayToObject(new SupplierAttachment().fillAbles),
      isLoading: false
    }
  },
  validations: {
    form: {
      name: {required},
      kuName: {},
      arName: {},
      attachment: {required},
      shareable: {},
    }
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return false;
      this.form.targetId = this.$route.params.id;
      this.isLoading = true;
      this.$store.dispatch('supplierAttachments/CREATE', this.form).then(() => {
        this.$v.$reset();
        this.$router.push({name: 'suppliers.edit', params: {id: this.$route.params.id}})
      }).catch(() => this.isLoading = false);
    },
  }
}
</script>
