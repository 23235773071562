import Vue from 'vue'
import VueI18n from 'vue-i18n';
import messages from '@/translations';
import VueLocalStorage from 'vue-localstorage';

Vue.use(VueLocalStorage);
Vue.use(VueI18n);

export const i18n = new VueI18n({
    lazy: true,
    silentTranslationWarn: process.env.NODE_ENV === 'development',
    locale: Vue.localStorage.get('language') ?? 'ku',
    fallbackLocale: [ 'ku', 'ar', 'en' ],
    messages,
});
