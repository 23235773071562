<template>
  <div>
    <content-header>{{ $t('showContract') }}</content-header>
    <v-content>
      <b-card no-body>
        <b-card-header class="m-0">
          <h4 class="no-print">{{ $t('contract') }}</h4>
          <contract-design
              v-if="printContract && !isLoading"
              :customer="contract.customer"
              :buy-contract="contract"
              :product="contract.product"
              :diskSize="contract.diskSize"
              :ramSize="contract.ramSize"
              :color="contract.color"
              :contract-payments="payments"
              :contract-pre-payments="prePayments"
          />
          <div v-show="printContract" class="text-left m-2">
            <b-button
                class="no-print m-1"
                variant="primary"
                @click="printContract = false;"
            >
              {{ $t("back") }}
            </b-button>
            <b-button class="no-print m-1" variant="info" @click="printWindow">
              {{ $t("print") }}
            </b-button>
          </div>
        </b-card-header>
        <b-tabs v-if="!printContract" card>
          <b-tab title-item-class="no-print" :title="$t('contract')" active>
            <b-button
                class="m-2"
                variant="primary"
                @click="printContract = true"
            >
              {{ $t("printContract") }}
            </b-button>
            <div class="mt-2" v-if="contract">
              <b-row class="m-2">
                <b-col cols="4">
                  <b-input-group :prepend="$t('contractNumber')">
                    <b-form-input :value="contract.contractNumber" disabled></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col cols="4">
                  <b-input-group :prepend="$t('customerName')">
                    <b-form-input :value="`${contract.customer.firstName} ${contract.customer.middleName} ${contract.customer.lastName}`" disabled></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col cols="4">
                  <b-input-group :prepend="$t('customerPhone')">
                    <b-form-input :value="contract.customer.phone" disabled></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row class="m-2">
                <b-col cols="4">
                  <b-input-group :prepend="$t('productName')">
                    <b-form-input :value="contract.product.name" disabled></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col cols="4">
                  <b-input-group :prepend="$t('productColor')">
                    <b-form-input :value="contract.color.name" disabled></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col cols="4">
                  <b-input-group :prepend="$t('productDiskSize')">
                    <b-form-input :value="`${contract.diskSize.name} (${contract.diskSize.sizeInGB})`" disabled></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row class="m-2">
                <b-col cols="4">
                  <b-input-group :prepend="$t('productRamSize')">
                    <b-form-input :value="`${contract.ramSize.name} (${contract.ramSize.sizeInGB})`" disabled></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col cols="4">
                  <b-input-group :prepend="$t('screenSize')">
                    <b-form-input :value="`${contract.screenSize ? contract.screenSize.name : '-'}`" disabled></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col cols="4">
                  <b-input-group :prepend="$t('price')">
                    <b-form-input :value="contract.price.toLocaleString()" disabled></b-form-input>
                  </b-input-group>
                </b-col>
<!--                <b-col cols="4">-->
<!--                  <b-input-group :prepend="$t('discount')">-->
<!--                    <b-form-input :value="contract.discount.toLocaleString()" disabled></b-form-input>-->
<!--                  </b-input-group>-->
<!--                </b-col>-->
<!--                <b-col cols="4">-->
<!--                  <b-input-group :prepend="$t('extraPrice')">-->
<!--                    <b-form-input :value="contract.extraPrice ? contract.extraPrice.toLocaleString() : 0" disabled></b-form-input>-->
<!--                  </b-input-group>-->
<!--                </b-col>-->
              </b-row>
              <table class="table table-bordered">
                <thead>
                <tr>
                  <th>#</th>
                  <th>{{ $t('price') }}</th>
                  <th>{{ $t('payDate') }}</th>
                  <th>{{ $t('deadline') }}</th>
                  <th class="no-print">{{ $t('note') }}</th>
                  <th class="no-print">{{ $t('pay') }}</th>
                </tr>
                </thead>
                <tbody v-if="prePayments">
                <tr v-for="(prepayment, index) in prePayments" v-bind:key="prepayment.id">
                  <td>{{ $t('prePayment') }}</td>
                  <td>
                    <div role="group">
                      <b-input-group prepend="IQD">
                        <b-form-input type="number" v-model="prepayment.mcPrice" @focus="$event.target.select()" step=".0001" disabled></b-form-input>
                      </b-input-group>
                      <b-form-text style="font-size: 14px">{{ number2Text(prepayment.mcPrice, getLanguage()) }}</b-form-text>
                    </div>
                  </td>
                  <td>
                    <b-input-group :prepend="$t('payDate')">
                      <b-form-input type="date" v-model="prepayment.payDate" disabled></b-form-input>
                    </b-input-group>
                  </td>
                  <td>
                    <b-input-group :prepend="$t('deadline')">
                      <b-form-input type="date" v-model="prepayment.deadline" disabled></b-form-input>
                    </b-input-group>
                  </td>
                  <td class="no-print">
                    <b-input-group :prepend="$t('note')">
                      <b-textarea :id="'note_' + index" v-model="prepayment.note" :disabled="prepayment.isPaid"></b-textarea>
                    </b-input-group>
                  </td>
                  <td class="no-print">
                    <b-button
                        class="m-1"
                        :disabled="prepayment.isPaid"
                        variant="primary"
                        @click="pay(index)"
                    >
                      {{ $t('pay') }}
                    </b-button>
                    <b-button
                        class="m-1"
                        :disabled="!prepayment.isPaid"
                        variant="primary"
                        @click="printVoucher(index)"
                    >
                      {{ $t('print') }}
                    </b-button>
                    <div :id="'voucherReceiptDiv_' + index" style="display: none">
                      <voucher-receipt
                          :voucherNo="`${prepayment.id}`"
                          :voucherDate="prepayment.payDate"
                          :receivedFrom="contract.customer.firstName + ' ' + contract.customer.middleName + ' ' + contract.customer.lastName"
                          :amount="`${prepayment.mcPrice}`"
                          :note="prepayment.note"
                      />
                      <voucher-receipt
                          :voucherNo="`${prepayment.id}`"
                          :voucherDate="prepayment.payDate"
                          :receivedFrom="contract.customer.firstName + ' ' + contract.customer.middleName + ' ' + contract.customer.lastName"
                          :amount="`${prepayment.mcPrice}`"
                          :note="prepayment.note"
                      />
                    </div>
                  </td>
                </tr>
                <!-- Loop Here -->
                <tr v-for="(payment, index) in payments" v-bind:key="payment.id">
                  <td>{{ ++index }}</td>
                  <td>
                    <div role="group">
                      <b-input-group prepend="IQD">
                        <b-form-input type="number" v-model="payment.mcPrice" @focus="$event.target.select()" step=".0001" disabled></b-form-input>
                      </b-input-group>
                      <b-form-text style="font-size: 14px">{{ number2Text(payment.mcPrice, getLanguage()) }}</b-form-text>
                    </div>
                  </td>
                  <td>
                    <b-input-group :prepend="$t('payDate')">
                      <b-form-input type="date" v-model="payment.payDate" disabled></b-form-input>
                    </b-input-group>
                  </td>
                  <td>
                    <b-input-group :prepend="$t('deadline')">
                      <b-form-input type="date" v-model="payment.deadline" disabled></b-form-input>
                    </b-input-group>
                  </td>
                  <td class="no-print">
                    <b-input-group :prepend="$t('note')">
                      <b-textarea :id="'note_' + index" v-model="payment.note" :disabled="payment.isPaid"></b-textarea>
                    </b-input-group>
                  </td>
                  <td class="no-print">
                    <b-button
                        :disabled="payment.isPaid"
                        variant="primary"
                        @click="pay(index)"
                    >
                      {{ $t('pay') }}
                    </b-button>
                    <b-button
                        class="ml-2"
                        :disabled="!payment.isPaid"
                        variant="primary"
                        @click="printVoucher(index)"
                    >
                      {{ $t('print') }}
                    </b-button>
                    <div :id="'voucherReceiptDiv_' + index" style="display: none">
                      <voucher-receipt
                          :voucherNo="`${payment.id}`"
                          :voucherDate="payment.payDate"
                          :receivedFrom="contract.customer.firstName + ' ' + contract.customer.middleName + ' ' + contract.customer.lastName"
                          :amount="`${payment.mcPrice}`"
                          :note="payment.note"
                      />
                      <voucher-receipt
                          :voucherNo="`${payment.id}`"
                          :voucherDate="payment.payDate"
                          :receivedFrom="contract.customer.firstName + ' ' + contract.customer.middleName + ' ' + contract.customer.lastName"
                          :amount="`${payment.mcPrice}`"
                          :note="payment.note"
                      />
                    </div>
                  </td>
                </tr>
                <!-- Loop Here -->
                </tbody>
              </table>
            </div>
          </b-tab>
          <b-tab title-item-class="no-print" class="no-print" :title="$t('contractInsurances')">
            <ContractInsuranceList :contractInsurances="contractInsurances" @refreshData="loadOneData"/>
          </b-tab>
        </b-tabs>
        <is-loading v-if="isLoading" />
      </b-card>
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import IsLoading from "@/components/IsLoading";
import Swal from "sweetalert2";
import _ from "lodash";
import VoucherReceipt from "@/views/contracts/VoucherReceipt";
import ContractInsuranceList from "@/views/contracts/contract-insurances/List";
import ContractDesign from "@/views/ContractDesign";

export default {
  name: "ContractShow",
  components: {ContractDesign, ContractInsuranceList, VoucherReceipt, IsLoading, ContentHeader, 'v-content': Content},
  data() {
    return {
      isLoading: false,
      contract: null,
      prePayments: null,
      printContract: false,
      payments: [],
      contractInsurances: []
    };
  },
  mounted() {
    this.loadOneData();
  },
  methods: {
    loadOneData() {
      this.isLoading = true;
      this.$store.dispatch('contracts/GET_ONE', this.$route.params.id).then((response) => {
        this.contract           = response.data;
        this.contractInsurances = response.data.contractInsurances;
        const allPayments = response.data.paymentProcesses.map((item) => {
          item.payDate = this.$helpers.formatDate(item.payDate);
          item.deadline = this.$helpers.formatDate(item.deadline);
          return item;
        });
        this.payments = _.filter(allPayments, (x) => {
          return x.paymentType === 'Installment';
        });
        this.prePayments = _.filter(allPayments, (x) => {
          return x.paymentType === 'PrePayment';
        });
        this.isLoading  = false;
      }).catch(() => this.isLoading = false)
    },
    pay(index) {
      let installmentData = this.payments[index];
      let sendData = {
        id: installmentData.id,
        note: document.getElementById('note_' + index).value
      }
      Swal.fire({
        title: this.$t('areYouSure'),
        text: this.$t('youWantToMakePayment'),
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: this.$t('noCancel'),
        confirmButtonText: this.$t('yes')
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          this.$store.dispatch('contracts/PAY', sendData).then(() => {
            this.isLoading = false;
            Swal.fire({
              icon: 'success',
              title: this.$t('paymentSuccessfullyDone'),
              showConfirmButton: false,
              timer: 2500
            });
            this.loadOneData();
          }).catch(() => this.isLoading = false);
        }
      });
    },
    printVoucher(index) {
      let printContents = document.getElementById(`voucherReceiptDiv_${index}`).innerHTML;
      let originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
    },
    printWindow() {
      window.print();
    }
  }
}
</script>
