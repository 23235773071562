<template>
  <div class="form-group row">
    <label v-if="showLabel" class="col-sm-2 col-form-label text-right">{{
      name
    }}</label>
    <div :class="{ 'col-sm-12': !showLabel, 'col-sm-10': showLabel }">
      <div class="mb-2" v-show="attachments.length <= 0">
        <input
          :id="id"
          :placeholder="$t('url')"
          v-model="attachmentUrl"
          type="text"
          autocomplete="off"
          class="form-control"
          @change="attachmentUrlChanged"
          @focus="$event.target.select()"
        />
      </div>
      <div v-show="!attachmentUrl">
        <vue-dropzone
          id="dropzone"
          :options="options"
          :useCustomSlot="true"
          :multiple="multiple"
          :disabled="attachmentUrl"
          @vdropzone-file-added="onAddFiles"
          @vdropzone-removed-file="onRemoveFile"
        >
          <div class="dropzone-custom-content">
            <h3 class="dropzone-custom-title">
              {{ $t("dragAndDropToUploadContent") }}
            </h3>
            <div class="subtitle">
              {{ $t("orClickToSelectAFileFromYourComputer") }}
            </div>
          </div>
        </vue-dropzone>
      </div>
    </div>
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  name: "FileInput",
  props: {
    id: {
      type: String,
    },
    name: {},
    showLabel: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    maxFiles: {
      type: Number,
      default: 1,
    },
    maxFilesize: {
      type: Number,
      default: 8,
    },
  },
  components: { vueDropzone: vue2Dropzone },
  data() {
    return {
      attachments: [],
      attachmentUrl: null,
      options: {
        url: "http",
        autoProcessQueue: false,
        thumbnailWidth: 350,
        thumbnailHeight: 250,
        addRemoveLinks: true,
        uploadMultiple: this.multiple,
        maxFilesize: this.maxFilesize,
        maxFiles: this.maxFiles,
        acceptedFiles: ".jpeg,.jpg,.png,.gif,.webp",
        dictFileTooBig: this.$t("fileIsTooBig"),
        dictInvalidFileType: this.$t("typeNotSupported"),
        dictRemoveFile:
          '<i class="fas fa-times"> ' + this.$t("remove") + "</i>",
        dictMaxFilesExceeded: this.$t("maxFileUploadReach"),
      },
    };
  },
  methods: {
    onAddFiles(file) {
      this.$helpers
        .changeFileToBase64(file)
        .then((response) => {
          if (this.attachments.length < this.maxFiles) {
            this.attachments.push(response);
          }
        })
        .finally(() => {
          if (!this.multiple) {
            this.$emit("fileAdded", this.attachments[0] ?? null);
          } else {
            this.$emit("fileAdded", this.attachments ?? []);
          }
        });
    },
    onRemoveFile(file) {
      this.$helpers.changeFileToBase64(file).then((response) => {
        let fileIndex = this.attachments.findIndex(
          (rmFile) => rmFile === response
        );
        if (fileIndex !== -1) {
          this.attachments.splice(fileIndex, 1);
          if (!this.multiple) {
            this.$emit("fileAdded", this.attachments[0] ?? null);
          } else {
            this.$emit("fileAdded", this.attachments ?? []);
          }
        }
      });
    },
    attachmentUrlChanged() {
      this.$emit("fileAdded", this.attachmentUrl ?? null);
    },
  },
};
</script>

<style>
#dropzone {
  min-height: 325px !important;
}
.dropzone-custom-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.dropzone-custom-title {
  margin-top: 0;
  color: #00b782 !important;
}

.subtitle {
  color: #314b5f;
}
</style>
