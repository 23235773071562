<template>
  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
      </li>
      <li class="nav-item">
        <router-link tag="a" class="nav-link" :to="{ name: 'settings' }">
          <i class="fas fa-cogs nav-icon"></i>
        </router-link>
      </li>
    </ul>
    <switch-language />
  </nav>
</template>

<script>
import SwitchLanguage from "../SwitchLanguage";
export default {
  name: "Navbar",
  components: {SwitchLanguage},
}
</script>
