<template>
  <div>
    <content-header>{{ $t("supplierBatch") }}</content-header>
    <v-content>
      <b-card>
        <b-card-body>
          <select-input
              tabIndex="16"
              :is-loaded="isLoading"
              id="supplierId"
              :name="$t('supplierId')"
              :placeholder="$t('supplierId')"
              validate="$v"
              :required="true"
              api-link="suppliers/GET_ALL"
              label="name"
              @itemSelected="(e) => supplierId = e"
          />

          <div class="form-group row">
            <label for="methods" class="col-sm-2 col-form-label text-right">{{ $t('methods') }}</label>
            <div class="col-sm-10">
              <b-form-select id="methods" v-model="method" :options="methods"></b-form-select>
            </div>
          </div>

          <div v-if="method === 'sellprice' || method === 'discount'" class="form-group row">
            <label for="ratio" class="col-sm-2 col-form-label text-right">{{ $t('ratio') }}</label>
            <div class="col-sm-10">
              <b-form-input v-model="ratio" :placeholder="$t('ratio')" method="number" step=".001"/>
            </div>
          </div>

          <div v-if="method === 'shippingtime'" class="form-group row">
            <label for="shippingTime" class="col-sm-2 col-form-label text-right">{{ $t('shippingTime') }}</label>
            <div class="col-sm-10">
              <b-form-input v-model="shippingTime" :placeholder="$t('shippingTime')" method="number" />
            </div>
          </div>

          <div v-if="method === 'isavailable'" class="form-group row">
            <label for="isAvailable" class="col-sm-2 col-form-label text-right">{{ $t('isAvailable') }}</label>
            <div class="col-sm-10">
              <b-form-group id="input-group-4">
                <b-form-checkbox v-model="isAvailable" id="isAvailable" />
              </b-form-group>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-2 col-form-label"> </label>
            <button @click="onSubmit" class="btn btn-outline-primary col-md-3" type="submit" :disabled="isLoading"><i class="fas fa-save"></i> {{ $t('update') }}</button>
          </div>
          <is-loading v-if="isLoading" />
        </b-card-body>
      </b-card>
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import IsLoading from "@/components/IsLoading";
import SelectInput from "@/components/Inputs/SelectInput";
import {swalMixin} from "@/plugins/constants";

export default {
  name: "ProductTypesCreate",
  components: {SelectInput, ContentHeader, "v-content": Content, IsLoading},
  data() {
    return {
      isLoading: false,
      supplierId: 0,
      method: '',
      methods: [
        {
          value: "sellprice",
          text: this.$t("sellPrice"),
        },
        {
          value: "shippingtime",
          text: this.$t("shippingTime"),
        },
        {
          value: "isavailable",
          text: this.$t("isAvailable"),
        },
        {
          value: "discount",
          text: this.$t("discount"),
        },
      ],
      ratio: 0,
      shippingTime: 0,
      isAvailable: false
    };
  },
  methods: {
    onSubmit() {
      this.isLoading = true;
      let form = {
        supplierId: this.supplierId,
        method: this.method
      };
      if (this.method === 'sellprice') {
        form.ratio = parseFloat(parseFloat(this.ratio) / 100).toFixed(3);
      }

      if (this.method === 'shippingtime') {
        form.shippingTime = this.shippingTime
      }

      if (this.method === 'isavailable') {
        form.isAvailable = this.isAvailable
      }

      this.$store.dispatch('suppliers/SUPPLIER_BATCH', form).then(() => {
        swalMixin('success', this.$t('updatedSuccessfully'))
        window.location.reload();
      }).catch(() => this.isLoading = false);
    }
  },
};
</script>
