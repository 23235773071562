import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import mixin from "@/mixins";

const productsToCollection = {
    namespaced: true,
    state: {
        productsToCollection: {},
        createdProductsToCollection: {},
    },
    mutations: {
        SET_PRODUCTS_TO_COLLECTION (state, value) { state.productsToCollection = value },
        SET_CREATED_PRODUCTS_TO_COLLECTION (state, value) {state.createdProductsToCollection = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/ProductColors` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}`)
                    .then((response) => {
                        commit('SET_PRODUCTS_TO_COLLECTION', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_PRODUCTS_TO_COLLECTION', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.post(`/Products/CollectionProduct`, form)
                    .then((response) => {
                        commit('SET_CREATED_PRODUCTS_TO_COLLECTION', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_PRODUCTS_TO_COLLECTION', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/Products/CollectionProduct/${ids}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        }
    },
    getters : {
        productsToCollection: state => state.productsToCollection,
        createdProductsToCollection: state => state.createdProductsToCollection,
    }
}

export default productsToCollection;
