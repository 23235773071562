<template>
  <div>
    <content-header>{{ $t("createProduct") }}</content-header>
    <v-content>
      <b-card>
        <b-card-body>
          <form class="forms-sample" @submit.prevent="onSubmit">
            <div class="row">
              <div class="col-xs-12 col-md-6 col-lg-4">
                <text-input
                  id="code"
                  :name="$t('code')"
                  :validate="$v"
                  :show-label="false"
                  :required="true"
                  :is-loading="isLoading"
                  tabIndex="1"
                />
                <text-input
                  id="name"
                  :name="$t('name')"
                  :validate="$v"
                  :show-label="false"
                  :required="true"
                  :max-length="true"
                  :is-loading="isLoading"
                  tabIndex="4"
                />
                <div class="form-group">
                  <b-input-group prepend="$">
                    <b-form-input
                      v-model="form.cost"
                      :placeholder="$t('cost')"
                      type="number"
                      step=".001"
                    />
                  </b-input-group>
                </div>
                <!--                <number-input id="cost" :name="$t('cost')" :validate="$v" :show-label="false" :required="true" :is-loading="isLoading" tabIndex="7" default-value="" />-->
                <number-input
                  id="contains"
                  :name="$t('contains')"
                  :validate="$v"
                  :show-label="false"
                  :required="true"
                  :is-loading="isLoading"
                  tabIndex="10"
                  default-value=""
                />
                <number-input
                  id="length"
                  :name="$t('length')"
                  :validate="$v"
                  :show-label="false"
                  :required="true"
                  :is-loading="isLoading"
                  tabIndex="10"
                  default-value=""
                />
                <number-input
                  id="cbm"
                  :name="$t('cbm')"
                  :validate="$v"
                  :show-label="false"
                  :required="true"
                  :is-loading="isLoading"
                  tabIndex="10"
                  default-value=""
                />
                <number-input
                  id="weight"
                  :name="$t('weight')"
                  :validate="$v"
                  :show-label="false"
                  :required="true"
                  :is-loading="isLoading"
                  tabIndex="10"
                  default-value=""
                />
                <select-input
                  tabIndex="16"
                  :is-loaded="isLoading"
                  id="productType"
                  :name="$t('productType')"
                  :placeholder="$t('productType')"
                  :validate="$v"
                  :show-label="false"
                  :required="true"
                  api-link="productTypes/GET_ALL"
                  label="name"
                  @itemSelected="parentSelected"
                />
                <select-input
                  tabIndex="17"
                  :is-loaded="isLoading"
                  id="productType"
                  :name="$t('productType')"
                  :placeholder="$t('productType')"
                  :validate="$v"
                  :show-label="false"
                  :required="true"
                  api-link="productTypes/GET_ALL"
                  :api-params="{ id: form.lastProductType }"
                  label="name"
                  @itemSelected="childSelected"
                  :disabled="productTypeChildDisabled"
                />
                <!--Start Product Disk Sizes-->
                <label class="col-md-8 col-form-label required-field">{{
                  $t("diskSizes")
                }}</label>
                <div
                  class="row m-2"
                  style="min-height: 150px !important;max-height: 150px !important;overflow: auto"
                >
                  <div class="col-md-12">
                    <div class="form-group">
                      <b-form-checkbox-group
                        :options="diskSizes"
                        @input="onDiskSizeChange"
                        value-field="id"
                        text-field="name"
                      ></b-form-checkbox-group>
                    </div>
                  </div>
                </div>
                <!--End Product Disk Sizes-->
                <boolean-input
                  id="available"
                  :name="$t('isAvailable')"
                  :load-on-mount="false"
                  :default-value="true"
                  :checked-label="$t('yes')"
                  :un-checked-label="$t('no')"
                  label-class="col-md-4"
                  input-class="col-md-8"
                  @switched="(e) => (form.isAvailable = e)"
                />
                <rich-text-input
                  id="description"
                  :name="$t('description')"
                  :validate="$v"
                  :show-label="false"
                  :is-loading="isLoading"
                  tabIndex="19"
                />
                <br />

                <!--Start Product Branches-->
                <div class="row m-2">
                  <!--                  <label class="col-md-2 col-form-label text-right required-field">{{ $t('diskSizes') }}</label>-->
                  <div class="col-md-12">
                    <select-input
                      tabIndex="22"
                      :show-label="false"
                      id="branch"
                      :name="$t('branch')"
                      :placeholder="$t('branch')"
                      :validate="$v"
                      api-link="branches/GET_ALL"
                      label="name"
                      :multiple="true"
                      @itemSelected="onBranchChange"
                    />
                  </div>
                </div>
                <!--End Product Branches-->
                <div
                  class="input-group mb-3"
                  v-for="selectedBranch in selectedBranches"
                  :key="selectedBranch.id"
                >
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">{{
                      selectedBranch.kuName
                    }}</span>
                  </div>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Price"
                    v-model="selectedBranch.price"
                    step=".001"
                  />
                </div>

                <number-input
                  id="discount"
                  :name="$t('discountPer')"
                  :validate="$v"
                  :show-label="false"
                  :required="true"
                  :is-loading="isLoading"
                  tabIndex="25"
                  default-value=""
                />
                <text-area-input
                  id="note"
                  :name="$t('note')"
                  :validate="$v"
                  :show-label="false"
                  :is-loading="isLoading"
                  tabIndex="28"
                />
              </div>
              <div class="col-xs-12 col-md-6 col-lg-4">
                <text-input
                  id="barcode"
                  :name="$t('barcode')"
                  :validate="$v"
                  :show-label="false"
                  :required="true"
                  :is-loading="isLoading"
                  tabIndex="2"
                />
                <text-input
                  id="arName"
                  :name="$t('arName')"
                  :validate="$v"
                  :show-label="false"
                  :required="true"
                  :max-length="true"
                  :is-loading="isLoading"
                  tabIndex="5"
                />
                <div class="form-group">
                  <b-input-group prepend="%">
                    <b-form-input
                      v-model="form.increasePercentage"
                      :placeholder="$t('increasePercentage')"
                      type="number"
                      step=".001"
                    />
                  </b-input-group>
                </div>
                <!--                <number-input id="increasePercentage" :name="$t('increasePercentage')" :validate="$v" :show-label="false" :required="true" :is-loading="isLoading" tabIndex="8" default-value="" />-->
                <number-input
                  id="shippingPrice"
                  :name="$t('shippingPrice')"
                  :validate="$v"
                  :show-label="false"
                  :required="true"
                  :is-loading="isLoading"
                  tabIndex="12"
                  default-value=""
                />
                <number-input
                  id="width"
                  :name="$t('width')"
                  :validate="$v"
                  :show-label="false"
                  :required="true"
                  :is-loading="isLoading"
                  tabIndex="10"
                  default-value=""
                />
                <number-input
                  id="minOrder"
                  :name="$t('minOrder')"
                  :validate="$v"
                  :show-label="false"
                  :required="true"
                  :is-loading="isLoading"
                  tabIndex="11"
                  default-value=""
                />
                <select-input
                  :is-loaded="isLoading"
                  id="madeIn"
                  :name="$t('madeIn')"
                  :placeholder="$t('madeIn')"
                  :validate="$v"
                  :show-label="false"
                  :required="true"
                  api-link="madeIn/GET_ALL"
                  label="name"
                  @itemSelected="(e) => (form.madeInId = e)"
                />
                <select-input
                  tabIndex="17"
                  :is-loaded="isLoading"
                  id="productType"
                  :name="$t('productType')"
                  :placeholder="$t('productType')"
                  :validate="$v"
                  :show-label="false"
                  :required="true"
                  api-link="productTypes/GET_ALL"
                  :api-params="{ id: form.parentId }"
                  label="name"
                  @itemSelected="subParentSelected"
                  :disabled="subParentDisable"
                />

                <select-input
                  tabIndex="13"
                  :is-loaded="isLoading"
                  id="brandName"
                  :name="$t('brandName')"
                  :placeholder="$t('brandName')"
                  :validate="$v"
                  :show-label="false"
                  :required="true"
                  api-link="brands/GET_ALL"
                  label="name"
                  @itemSelected="(e) => (form.brandId = e)"
                />

                <!--Start Product Ram Sizes-->
                <label class="col-md-8 col-form-label required-field">{{
                  $t("ramSizes")
                }}</label>
                <div
                  class="row m-2"
                  style="min-height: 150px !important;max-height: 150px !important;overflow: auto"
                >
                  <div class="col-md-12">
                    <div class="form-group">
                      <b-form-checkbox-group
                        :options="ramSizes"
                        @input="onRamSizeChange"
                        value-field="id"
                        text-field="name"
                      ></b-form-checkbox-group>
                    </div>
                  </div>
                </div>
                <!--End Product Ram Sizes-->
                <rich-text-input
                  id="arDescription"
                  :name="$t('arDescription')"
                  :validate="$v"
                  :show-label="false"
                  :is-loading="isLoading"
                  tabIndex="20"
                />
                <br />
                <file-input
                  tabIndex="23"
                  id="attachment"
                  :name="$t('attachment')"
                  :show-label="false"
                  @fileAdded="(base64File) => (form.attachment = base64File)"
                />
              </div>
              <div class="col-xs-12 col-md-6 col-lg-4">
                <select-input
                  tabIndex="3"
                  style="max-height: 38px"
                  :is-loaded="isLoading"
                  id="supplier"
                  :name="$t('supplier')"
                  :placeholder="$t('supplier')"
                  :validate="$v"
                  :show-label="false"
                  :required="true"
                  api-link="suppliers/GET_ALL"
                  label="name"
                  :return-object="true"
                  @itemSelected="supplierSelected"
                />
                <text-input
                  id="kuName"
                  :name="$t('kuName')"
                  :validate="$v"
                  :show-label="false"
                  :required="true"
                  :max-length="true"
                  :is-loading="isLoading"
                  tabIndex="6"
                />
                <div class="form-group">
                  <b-input-group prepend="$">
                    <b-form-input
                      v-model="form.price"
                      :placeholder="$t('price')"
                      type="number"
                      step=".001"
                    />
                  </b-input-group>
                </div>
                <!--                <number-input id="price" :name="$t('price')" :validate="$v" :show-label="false" :required="true" :is-loading="isLoading" tabIndex="9" default-value="" />-->
                <number-input
                  id="shippingTime"
                  :name="$t('shippingTime')"
                  :validate="$v"
                  :show-label="false"
                  :required="true"
                  :is-loading="isLoading"
                  tabIndex="10"
                  default-value=""
                />
                <number-input
                  id="height"
                  :name="$t('height')"
                  :validate="$v"
                  :show-label="false"
                  :required="true"
                  :is-loading="isLoading"
                  tabIndex="10"
                  default-value=""
                />
                <!-- <text-input
                  id="season"
                  :name="$t('season')"
                  :validate="$v"
                  :show-label="false"
                  :required="true"
                  :is-loading="isLoading"
                  tabIndex="10"
                /> -->
                <div class="form-group">
                  <b-form-select
                    v-model="form.season"
                    :options="seasonOptions"
                  ></b-form-select>
                </div>
                <div class="form-group">
                  <b-form-select
                    v-model="form.productStatus"
                    :options="productStatusOptions"
                  ></b-form-select>
                </div>
                <select-input
                  :is-loaded="isLoading"
                  id="productType"
                  :name="$t('productType')"
                  :placeholder="$t('productType')"
                  :validate="$v"
                  :show-label="false"
                  :required="true"
                  api-link="productTypes/GET_ALL"
                  :api-params="{ id: form.subParentId }"
                  label="name"
                  @itemSelected="productTypeSelected"
                  :disabled="productTypeDisabled"
                />

                <select-input
                  tabIndex="13"
                  :is-loaded="isLoading"
                  id="brandName"
                  :name="$t('brandName')"
                  :placeholder="$t('brandName')"
                  :validate="$v"
                  :show-label="false"
                  :required="true"
                  api-link="brands/GET_ALL"
                  label="name"
                  disabled
                />

                <!--Start Product Colors-->
                <label class="col-md-8 col-form-label required-field">{{
                  $t("colors")
                }}</label>
                <div
                  class="row m-2"
                  style="min-height: 150px !important;max-height: 150px !important;overflow: auto"
                >
                  <div class="col-md-12">
                    <div class="form-group">
                      <b-form-checkbox-group @input="onColorChange">
                        <b-form-checkbox
                          style="min-width: 95px"
                          v-for="color in colors"
                          :value="color"
                          :key="color.id"
                          :style="{ color: color.hexCode }"
                        >
                          <span>{{ color.hexCode }}</span>
                        </b-form-checkbox>
                      </b-form-checkbox-group>
                    </div>
                  </div>
                </div>
                <!--End Product Colors-->
                <rich-text-input
                  id="kuDescription"
                  :name="$t('kuDescription')"
                  :validate="$v"
                  :show-label="false"
                  :is-loading="isLoading"
                  tabIndex="18"
                />
                <br />
                <file-input
                  tabIndex="21"
                  id="thumbnail"
                  :name="$t('thumbnail')"
                  :show-label="false"
                  @fileAdded="(base64File) => (form.thumbnail = base64File)"
                />
              </div>
              <div class="col-xs-12 col-lg-8">
                <!--Start Tags Sizes-->
                <!--<label class="col-md-8 col-form-label required-field">{{ $t('tags') }}</label>-->
                <div
                  class="row m-2"
                  style="min-height: 250px;max-height: 250px !important;overflow: auto"
                >
                  <div class="col-md-12">
                    <div class="form-group">
                      <b-form-checkbox-group
                        :options="tags"
                        @input="onTagChange"
                        value-field="id"
                        text-field="name"
                      ></b-form-checkbox-group>
                    </div>
                  </div>
                </div>
                <!--End Tags Sizes-->
              </div>
            </div>
            <submit-input :name="$t('create')" :disabled="isLoading" />
            <is-loading v-if="isLoading" />
          </form>
        </b-card-body>
      </b-card>
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import { required, maxLength } from "vuelidate/lib/validators";
import IsLoading from "@/components/IsLoading";
import SubmitInput from "@/components/Inputs/SubmitInput";
import TextInput from "@/components/Inputs/TextInput";
import { Product } from "@/models/Product";
import FileInput from "@/components/Inputs/FileInput";
import NumberInput from "@/components/Inputs/NumberInput";
import SelectInput from "@/components/Inputs/SelectInput";
import TextAreaInput from "@/components/Inputs/TextAreaInput";
import RichTextInput from "@/components/Inputs/RichTextInput";
import BooleanInput from "@/components/Inputs/BooleanInput";

export default {
  name: "ProductCreateMultiple",
  components: {
    BooleanInput,
    RichTextInput,
    TextAreaInput,
    SelectInput,
    NumberInput,
    FileInput,
    TextInput,
    SubmitInput,
    ContentHeader,
    "v-content": Content,
    IsLoading,
  },
  data() {
    return {
      form: this.$helpers.convertArrayToObject(new Product().fillAbles),
      isLoading: false,
      serverParams: {},
      tags: [],
      selectedTags: [],
      diskSizes: [],
      selectedDiskSizes: [],
      ramSizes: [],
      selectedRamSizes: [],
      colors: [],
      selectedColors: [],
      branches: [],
      selectedBranches: [],
      subParentDisable: true,
      productTypeDisabled: true,
      childDisabled: true,
      productTypeChildDisabled: true,
      productStatusOptions: [
        {
          value: "available",
          text: this.$t("available"),
        },
        {
          value: "unavailable",
          text: this.$t("unavailable"),
        },
        {
          value: "temporaryUnAvailable",
          text: this.$t("temporaryUnAvailable"),
        },
        {
          value: "iraqStock",
          text: this.$t("iraqStock"),
        },
        {
          value: "chinaStock",
          text: this.$t("chinaStock"),
        },
        {
          value: "iranStock",
          text: this.$t("iranStock"),
        },
        {
          value: "turkeyStock",
          text: this.$t("turkeyStock"),
        },
        {
          value: "uaeStock",
          text: this.$t("uaeStock"),
        },
        {
          value: "europeStock",
          text: this.$t("europeStock"),
        },
      ],
      seasonOptions: [
        {
          value: "spring",
          text: this.$t("spring"),
        },
        {
          value: "summer",
          text: this.$t("summer"),
        },
        {
          value: "autumn",
          text: this.$t("autumn"),
        },
        {
          value: "winter",
          text: this.$t("winter"),
        },
      ],
    };
  },
  watch: {
    "form.increasePercentage": function(val) {
      this.form.price = (
        parseFloat(this.form.cost) + parseFloat(this.form.cost * (val / 100))
      ).toFixed(3);
    },
    "form.cost": function(val) {
      this.form.price = (
        parseFloat(val) + parseFloat(val * (this.form.increasePercentage / 100))
      ).toFixed(3);
    },
    "form.length": function(val) {
      this.form.cbm =
        (parseFloat(val) *
          parseFloat(this.form.width) *
          parseFloat(this.form.height)) /
        1000000;
    },
    "form.width": function(val) {
      this.form.cbm =
        (parseFloat(val) *
          parseFloat(this.form.length) *
          parseFloat(this.form.height)) /
        1000000;
    },
    "form.height": function(val) {
      this.form.cbm =
        (parseFloat(val) *
          parseFloat(this.form.width) *
          parseFloat(this.form.length)) /
        1000000;
    },
  },
  validations: {
    form: {
      code: { required },
      barcode: {},
      name: { required, maxLength: maxLength(255) },
      kuName: { required, maxLength: maxLength(255) },
      arName: { required, maxLength: maxLength(255) },
      description: {},
      kuDescription: {},
      arDescription: {},
      increasePercentage: {},
      price: {},
      cost: {},
      discount: {},
      contains: {},
      minOrder: {},
      shippingPrice: {},
      supplierId: {},
      brandId: {},
      productTypeId: {},
      note: {},
      attachment: {},
      tags: {},
      productPriceBranches: {},
      availableDiskSizes: {},
      availableRamSizes: {},
      availableColors: {},
      shippingTime: {},
      width: {},
      length: {},
      height: {},
      weight: {},
      cbm: {},
      season: "",
      madeIn: {},
      productStatus: {},
    },
  },
  mounted() {
    this.getTags();
    this.getDiskSizes();
    this.getRamSizes();
    this.getColors();
    this.getBranches();
    this.getBarcode();
    this.form.season = "spring";
    this.form.productStatus = "available";
    this.form.isAvailable = true;
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return false;
      this.isLoading = true;

      let branchPrices = [];
      if (this.selectedBranches.length > 0) {
        this.selectedBranches.forEach((branch) => {
          branchPrices.push({
            // branchId: branch.id,
            price: branch.price ?? 0,
            discount: 0,
          });
        });
      }
      this.form.productPriceBranches = branchPrices;

      let tags = [];
      if (this.selectedTags.length > 0) {
        this.selectedTags.forEach((tag) => {
          tags.push(tag);
        });
      }
      this.form.tags = tags;

      let diskSizes = [];
      if (this.selectedDiskSizes.length > 0) {
        this.selectedDiskSizes.forEach((diskSize) => {
          diskSizes.push(diskSize);
        });
      }
      this.form.availableDiskSizes = diskSizes;

      let ramSizes = [];
      if (this.selectedRamSizes.length > 0) {
        this.selectedRamSizes.forEach((ramSize) => {
          ramSizes.push(ramSize);
        });
      }
      this.form.availableRamSizes = ramSizes;

      let colors = [];
      if (this.selectedColors.length > 0) {
        this.selectedColors.forEach((color) => {
          colors.push(color);
        });
      }
      this.form.availableColors = colors;

      this.form.discount = this.$helpers.calculateDiscount(
        this.form.discount,
        this.form.price
      );
      this.form.productTypeId =
        this.form.childId !== 0
          ? this.form.childId
          : this.form.lastProductType !== 0
          ? this.form.lastProductType
          : this.form.subParentId !== 0
          ? this.form.subParentId
          : this.form.parentId;

      delete this.form.diskSizes;
      delete this.form.ramSizes;
      delete this.form.colors;

      this.$store
        .dispatch("products/CREATE", this.form)
        .then(() => {
          this.$v.$reset();
          this.$router.push({ name: "products" });
        })
        .catch(() => (this.isLoading = false));
    },
    getTags() {
      this.$store
        .dispatch("tags/GET_ALL", {
          _start: 0,
          _end: 50,
          _sort: "id",
          _order: "asc",
          _search: "",
          custom_params: "",
        })
        .then((response) => {
          this.tags = response.data;
        });
    },
    onTagChange(value) {
      let tagsArray = [];
      this.tags.map((item) => {
        if (value.includes(item.id)) {
          tagsArray.push({
            id: item.id,
          });
        }
      });
      this.selectedTags = tagsArray;
    },
    getDiskSizes() {
      this.$store
        .dispatch("productSizes/GET_ALL", {
          _start: 0,
          _end: 50,
          _sort: "id",
          _order: "asc",
          _search: "",
          custom_params: "",
        })
        .then((response) => {
          this.diskSizes = response.data;
        });
    },
    onDiskSizeChange(value) {
      let diskSizeArray = [];
      this.diskSizes.map((item) => {
        if (value.includes(item.id)) {
          diskSizeArray.push({
            diskSizeId: item.id,
          });
        }
      });
      this.selectedDiskSizes = diskSizeArray;
    },
    getRamSizes() {
      this.$store
        .dispatch("productRamSizes/GET_ALL", {
          _start: 0,
          _end: 50,
          _sort: "id",
          _order: "asc",
          _search: "",
          custom_params: "",
        })
        .then((response) => {
          this.ramSizes = response.data;
        });
    },
    onRamSizeChange(value) {
      let ramSizeArray = [];
      this.ramSizes.map((item) => {
        if (value.includes(item.id)) {
          ramSizeArray.push({
            ramSizeId: item.id,
          });
        }
      });
      this.selectedRamSizes = ramSizeArray;
    },
    getColors() {
      this.$store
        .dispatch("productColors/GET_ALL", {
          _start: 0,
          _end: 50,
          _sort: "id",
          _order: "asc",
          _search: "",
          custom_params: "",
        })
        .then((response) => {
          this.colors = response.data;
        });
    },
    onColorChange(value) {
      let colorArray = [];
      this.colors.map((item) => {
        if (value.includes(item)) {
          colorArray.push({
            colorId: item.id,
          });
        }
      });
      this.selectedColors = colorArray;
    },
    getBranches() {
      this.$store
        .dispatch("branches/GET_ALL", {
          _start: 0,
          _end: 50,
          _sort: "id",
          _order: "asc",
          _search: "",
          custom_params: "",
        })
        .then((response) => {
          this.branches = response.data;
        });
    },
    onBranchChange(value) {
      let branchesArray = [];
      this.branches.map((item) => {
        if (value.includes(item.id)) {
          item.price = item.price && item.price !== 0 ? item.price : 0;
          branchesArray.push(item);
        }
      });
      this.selectedBranches = branchesArray;
    },
    getBarcode() {
      this.$store.dispatch("products/GET_BARCODE").then((response) => {
        this.form.barcode = response.data;
      });
    },
    async parentSelected(e) {
      this.subParentDisable = true;
      this.form.subParentId = 0;
      this.form.parentId = await e;
      this.subParentDisable = false;
    },
    async subParentSelected(e) {
      this.productTypeDisabled = true;
      this.form.lastProductType = 0;
      this.form.subParentId = await e;
      this.productTypeDisabled = false;
    },
    async productTypeSelected(e) {
      this.productTypeChildDisabled = true;
      this.form.childid = 0;
      this.form.lastProductType = await e;
      this.productTypeChildDisabled = false;
    },
    async childSelected(e) {
      this.form.childId = await e;
    },
    async supplierSelected(e) {
      this.form.supplierId = e.id;
      this.form.shippingTime = e.shippingTime;
      this.form.increasePercentage = parseFloat(e.profit).toFixed(3);
    },
  },
};
</script>
