<template>
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <a href="#" class="brand-link">
      <!--      <img :src="systemImage" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style="opacity: .8">-->
      <span class="brand-text font-weight-light">{{
        projectTenantName()
      }}</span>
    </a>
    <div class="sidebar">
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
          <!--          <img :src="getAuthUserProfileImage()" class="img-circle elevation-2" alt="User Image">-->
        </div>
        <div class="info">
          <a href="#" class="d-block">{{
            getAuthUser() ? getAuthUser().userName : "-"
          }}</a>
        </div>
      </div>
      <nav class="mt-2">
        <ul
          class="nav nav-pills nav-sidebar flex-column"
          data-widget="treeview"
          role="menu"
          data-accordion="false"
        >
          <li class="nav-item">
            <router-link
              tag="a"
              class="nav-link"
              :to="{ name: 'dashboard' }"
              :class="activeClass(['dashboard', 'admin', 'dashboard.page'])"
            >
              <i class="fal fa-tachometer-alt nav-icon"></i>
              <p>{{ $t("dashboard") }}</p>
            </router-link>
          </li>

          <li
            class="nav-item"
            :class="
              activeClass(
                [
                  'products',
                  'products.stock',
                  'products.create',
                  'products.create.multiple',
                  'products.edit',
                  'productSizes',
                  'productSizes.create',
                  'productSizes.edit',
                  'productRamSizes',
                  'productRamSizes.create',
                  'productRamSizes.edit',
                  'productScreenSizes',
                  'productScreenSizes.create',
                  'productScreenSizes.edit',
                  'productColors',
                  'productColors.create',
                  'productColors.edit',
                  'brands',
                  'brands.create',
                  'brands.edit',
                  'madeIn',
                  'madeIn.create',
                  'madeIn.edit',
                  'tags',
                  'tags.create',
                  'tags.edit',
                  'collections',
                  'collections.create',
                  'collections.edit',
                  'productTypes',
                  'productTypes.create',
                  'productTypes.edit',
                ],
                'menu-open'
              )
            "
          >
            <a
              href="#"
              class="nav-link"
              :class="
                activeClass([
                  'products',
                  'products.stock',
                  'products.create',
                  'products.create.multiple',
                  'products.edit',
                  'productSizes',
                  'productSizes.create',
                  'productSizes.edit',
                  'productRamSizes',
                  'productRamSizes.create',
                  'productRamSizes.edit',
                  'productScreenSizes',
                  'productScreenSizes.create',
                  'productScreenSizes.edit',
                  'productColors',
                  'productColors.create',
                  'productColors.edit',
                  'brands',
                  'brands.create',
                  'brands.edit',
                  'madeIn',
                  'madeIn.create',
                  'madeIn.edit',
                  'tags',
                  'tags.create',
                  'tags.edit',
                  'collections',
                  'collections.create',
                  'collections.edit',
                  'productTypes',
                  'productTypes.create',
                  'productTypes.edit',
                ])
              "
            >
              <i class="nav-icon fal fa-home-lg-alt"></i>
              <p>{{ $t("products") }}<i class="fal fa-angle-left right"></i></p>
            </a>
            <ul
              class="nav nav-treeview"
              :style="
                displaySubList([
                  'products',
                  'products.stock',
                  'products.create',
                  'products.create.multiple',
                  'products.edit',
                  'productSizes',
                  'productSizes.create',
                  'productSizes.edit',
                  'productRamSizes',
                  'productRamSizes.create',
                  'productRamSizes.edit',
                  'productScreenSizes',
                  'productScreenSizes.create',
                  'productScreenSizes.edit',
                  'productColors',
                  'productColors.create',
                  'productColors.edit',
                  'brands',
                  'brands.create',
                  'brands.edit',
                  'madeIn',
                  'madeIn.create',
                  'madeIn.edit',
                  'tags',
                  'tags.create',
                  'tags.edit',
                  'collections',
                  'collections.create',
                  'collections.edit',
                  'productTypes',
                  'productTypes.create',
                  'productTypes.edit',
                ])
              "
            >
              <li class="nav-item">
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'products' }"
                  :class="activeClass(['products'])"
                >
                  <i class="fal fa-list nav-icon"></i>
                  <p>{{ $t("list") }}</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                    tag="a"
                    class="nav-link"
                    :to="{ name: 'products.stock' }"
                    :class="activeClass(['products.stock'])"
                >
                  <i class="fal fa-list nav-icon"></i>
                  <p>{{ $t("productStock") }}</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'products.create.multiple' }"
                  :class="activeClass(['products.create.multiple'])"
                >
                  <i class="fal fa-plus nav-icon"></i>
                  <p>{{ $t("create") }}</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'productSizes' }"
                  :class="activeClass(['productSizes'])"
                >
                  <i class="fal fa-sort-size-up-alt nav-icon"></i>
                  <p>{{ $t("productSizes") }}</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'productRamSizes' }"
                  :class="activeClass(['productRamSizes'])"
                >
                  <i class="fal fa-ruler-vertical nav-icon"></i>
                  <p>{{ $t("productRamSizes") }}</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'productScreenSizes' }"
                  :class="activeClass(['productScreenSizes'])"
                >
                  <i class="fal fa-monitor-heart-rate nav-icon"></i>
                  <p>{{ $t("productScreenSizes") }}</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'productColors' }"
                  :class="activeClass(['productColors'])"
                >
                  <i class="fal fa-palette nav-icon"></i>
                  <p>{{ $t("productColors") }}</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'brands' }"
                  :class="activeClass(['brands'])"
                >
                  <i class="fal fa-store nav-icon"></i>
                  <p>{{ $t("brands") }}</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'madeIn' }"
                  :class="activeClass(['madeIn'])"
                >
                  <i class="fal fa-store nav-icon"></i>
                  <p>{{ $t("madeIn") }}</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'tags' }"
                  :class="activeClass(['tags'])"
                >
                  <i class="fal fa-tags nav-icon"></i>
                  <p>{{ $t("tags") }}</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'productTypes' }"
                  :class="activeClass(['productTypes'])"
                >
                  <i class="fal fa-clipboard-list-check nav-icon"></i>
                  <p>{{ $t("productTypes") }}</p>
                </router-link>
              </li>
            </ul>
          </li>

          <li
            class="nav-item"
            :class="
              activeClass(
                [
                  'front.page.slider',
                  'front.page.slider.create',
                  'front.page.slider.edit',
                  'front.page.group.products.create',
                  'top.slider',
                  'top.slider.create',
                  'top.slider.edit',
                  'top.slider.group.products.create',
                  'most.sale',
                  'most.sale.create',
                  'most.sale.edit',
                  'most.sale.group.products.create',
                  'ads',
                  'ads.create',
                  'ads.edit',
                  'new.season',
                  'new.season.create',
                  'new.season.edit',
                  'new.season.group.products.create',
                  'featured.collection',
                  'featured.collection.create',
                  'featured.collection.edit',
                  'featured.collection.group.products.create',
                  'take.3.pay.2',
                  'take.3.pay.2.create',
                  'take.3.pay.2.edit',
                  'take.3.pay.2.group.products.create',
                  'new.arrivals',
                  'new.arrivals.create',
                  'new.arrivals.edit',
                  'new.arrivals.products.create',
                  'discount',
                  'discount.create',
                  'discount.edit',
                  'discount.products.create',
                  'currencies',
                  'currencies.create',
                  'currencies.edit',
                ],
                'open-menu'
              )
            "
          >
            <a
              href="#"
              class="nav-link"
              :class="
                activeClass([
                  'front.page.slider',
                  'front.page.slider.create',
                  'front.page.slider.edit',
                  'front.page.group.products.create',
                  'top.slider',
                  'top.slider.create',
                  'top.slider.edit',
                  'top.slider.group.products.create',
                  'most.sale',
                  'most.sale.create',
                  'most.sale.edit',
                  'most.sale.group.products.create',
                  'ads',
                  'ads.create',
                  'ads.edit',
                  'new.season',
                  'new.season.create',
                  'new.season.edit',
                  'new.season.group.products.create',
                  'featured.collection',
                  'featured.collection.create',
                  'featured.collection.edit',
                  'featured.collection.group.products.create',
                  'take.3.pay.2',
                  'take.3.pay.2.create',
                  'take.3.pay.2.edit',
                  'take.3.pay.2.group.products.create',
                  'new.arrivals',
                  'new.arrivals.create',
                  'new.arrivals.edit',
                  'new.arrivals.products.create',
                  'discount',
                  'discount.create',
                  'discount.edit',
                  'discount.products.create',
                  'currencies',
                  'currencies.create',
                  'currencies.edit',
                ])
              "
            >
              <i class="nav-icon fal fa-tools"></i>
              <p>
                {{ $t("utilities") }}<i class="fal fa-angle-left right"></i>
              </p>
            </a>
            <ul
              class="nav nav-treeview"
              :style="
                displaySubList([
                  'front.page.slider',
                  'front.page.slider.create',
                  'front.page.slider.edit',
                  'front.page.group.products.create',
                  'top.slider',
                  'top.slider.create',
                  'top.slider.edit',
                  'top.slider.group.products.create',
                  'most.sale',
                  'most.sale.create',
                  'most.sale.edit',
                  'most.sale.group.products.create',
                  'ads',
                  'ads.create',
                  'ads.edit',
                  'new.season',
                  'new.season.create',
                  'new.season.edit',
                  'new.season.group.products.create',
                  'featured.collection',
                  'featured.collection.create',
                  'featured.collection.edit',
                  'featured.collection.group.products.create',
                  'take.3.pay.2',
                  'take.3.pay.2.create',
                  'take.3.pay.2.edit',
                  'take.3.pay.2.group.products.create',
                  'new.arrivals',
                  'new.arrivals.create',
                  'new.arrivals.edit',
                  'new.arrivals.products.create',
                  'discount',
                  'discount.create',
                  'discount.edit',
                  'discount.products.create',
                  'currencies',
                  'currencies.create',
                  'currencies.edit',
                ])
              "
            >
              <li class="nav-item">
                <router-link
                    tag="a"
                    class="nav-link"
                    :to="{ name: 'front.page.slider' }"
                    :class="
                    activeClass([
                      'front.page.slider',
                      'front.page.slider.create',
                      'front.page.slider.edit',
                      'front.page.group.products.create',
                    ])
                  "
                >
                  <i class="fal fa-sliders-v nav-icon"></i>
                  <p>{{ $t("sliders") }}</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                    tag="a"
                    class="nav-link"
                    :to="{ name: 'new.arrivals' }"
                    :class="
                    activeClass([
                      'new.arrivals',
                      'new.arrivals.create',
                      'new.arrivals.edit',
                      'new.arrivals.products.create',
                    ])
                  "
                >
                  <i class="fal fa-sliders-v nav-icon"></i>
                  <p>{{ $t("newArrivals") }}</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                    tag="a"
                    class="nav-link"
                    :to="{ name: 'discount' }"
                    :class="
                    activeClass([
                      'discount',
                      'discount.create',
                      'discount.edit',
                      'discount.products.create',
                    ])
                  "
                >
                  <i class="fal fa-sliders-v nav-icon"></i>
                  <p>{{ $t("discount") }}</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                    tag="a"
                    class="nav-link"
                    :to="{ name: 'currencies' }"
                    :class="
                    activeClass([
                      'currencies',
                      'currencies.create',
                      'currencies.edit',
                    ])
                  "
                >
                  <i class="fal fa-sliders-v nav-icon"></i>
                  <p>{{ $t("currencies") }}</p>
                </router-link>
              </li>
<!--              <li class="nav-item">-->
<!--                <router-link-->
<!--                  tag="a"-->
<!--                  class="nav-link"-->
<!--                  :to="{ name: 'ads' }"-->
<!--                  :class="activeClass(['ads', 'ads.create', 'ads.edit'])"-->
<!--                >-->
<!--                  <i class="fal fa-ad nav-icon"></i>-->
<!--                  <p>{{ $t("ads") }}</p>-->
<!--                </router-link>-->
<!--              </li>-->
<!--              <li class="nav-item">-->
<!--                <router-link-->
<!--                  tag="a"-->
<!--                  class="nav-link"-->
<!--                  :to="{ name: 'top.slider' }"-->
<!--                  :class="-->
<!--                    activeClass([-->
<!--                      'top.slider',-->
<!--                      'top.slider.create',-->
<!--                      'top.slider.edit',-->
<!--                      'top.slider.group.products.create',-->
<!--                    ])-->
<!--                  "-->
<!--                >-->
<!--                  <i class="fal fa-file nav-icon"></i>-->
<!--                  <p>{{ $t("topSlider") }}</p>-->
<!--                </router-link>-->
<!--              </li>-->
<!--              <li class="nav-item">-->
<!--                <router-link-->
<!--                  tag="a"-->
<!--                  class="nav-link"-->
<!--                  :to="{ name: 'most.sale' }"-->
<!--                  :class="-->
<!--                    activeClass([-->
<!--                      'most.sale',-->
<!--                      'most.sale.create',-->
<!--                      'most.sale.edit',-->
<!--                      'most.sale.group.products.create',-->
<!--                    ])-->
<!--                  "-->
<!--                >-->
<!--                  <i class="fal fa-badge-dollar nav-icon"></i>-->
<!--                  <p>{{ $t("mostSale") }}</p>-->
<!--                </router-link>-->
<!--              </li>-->
<!--              <li class="nav-item">-->
<!--                <router-link-->
<!--                  tag="a"-->
<!--                  class="nav-link"-->
<!--                  :to="{ name: 'new.season' }"-->
<!--                  :class="-->
<!--                    activeClass([-->
<!--                      'new.season',-->
<!--                      'new.season.create',-->
<!--                      'new.season.edit',-->
<!--                      'new.season.group.products.create',-->
<!--                    ])-->
<!--                  "-->
<!--                >-->
<!--                  <i class="fal fa-badge-check nav-icon"></i>-->
<!--                  <p>{{ $t("newSeason") }}</p>-->
<!--                </router-link>-->
<!--              </li>-->
<!--              <li class="nav-item">-->
<!--                <router-link-->
<!--                  tag="a"-->
<!--                  class="nav-link"-->
<!--                  :to="{ name: 'featured.collection' }"-->
<!--                  :class="-->
<!--                    activeClass([-->
<!--                      'featured.collection',-->
<!--                      'featured.collection.create',-->
<!--                      'featured.collection.edit',-->
<!--                      'featured.collection.group.products.create',-->
<!--                    ])-->
<!--                  "-->
<!--                >-->
<!--                  <i class="fal fa-album-collection nav-icon"></i>-->
<!--                  <p>{{ $t("featuredCollections") }}</p>-->
<!--                </router-link>-->
<!--              </li>-->
<!--              <li class="nav-item">-->
<!--                <router-link-->
<!--                  tag="a"-->
<!--                  class="nav-link"-->
<!--                  :to="{ name: 'take.3.pay.2' }"-->
<!--                  :class="-->
<!--                    activeClass([-->
<!--                      'take.3.pay.2',-->
<!--                      'take.3.pay.2.create',-->
<!--                      'take.3.pay.2.edit',-->
<!--                      'take.3.pay.2.group.products.create',-->
<!--                    ])-->
<!--                  "-->
<!--                >-->
<!--                  <i class="fal fa-hand-scissors nav-icon"></i>-->
<!--                  <p>{{ $t("take3Pay2") }}</p>-->
<!--                </router-link>-->
<!--              </li>-->
            </ul>
          </li>

          <li class="nav-item">
            <router-link
              tag="a"
              class="nav-link"
              :to="{ name: 'installmentMethods' }"
              :class="activeClass(['installmentMethods'])"
            >
              <i class="fal fa-receipt nav-icon"></i>
              <p>{{ $t("installmentMethods") }}</p>
            </router-link>
          </li>

          <li
            class="nav-item"
            :class="
              activeClass(
                ['customers', 'customer.accounts', 'customers.create', 'customers.edit'],
                'open-menu'
              )
            "
          >
            <a
              href="#"
              class="nav-link"
              :class="
                activeClass(['customers', 'customer.accounts', 'customers.create', 'customers.edit'])
              "
            >
              <i class="nav-icon fal fa-users"></i>
              <p>
                {{ $t("customers") }}<i class="fal fa-angle-left right"></i>
              </p>
            </a>
            <ul
              class="nav nav-treeview"
              :style="
                displaySubList([
                  'customers',
                  'customer.accounts',
                  'customers.create',
                  'customers.edit',
                ])
              "
            >
              <li class="nav-item">
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'customers' }"
                  :class="activeClass(['customers'])"
                >
                  <i class="fal fa-list nav-icon"></i>
                  <p>{{ $t("list") }}</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                    tag="a"
                    class="nav-link"
                    :to="{ name: 'customer.accounts' }"
                    :class="activeClass(['customer.accounts'])"
                >
                  <i class="fal fa-list nav-icon"></i>
                  <p>{{ $t("accountsList") }}</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'customers.create' }"
                  :class="activeClass(['customers.create'])"
                >
                  <i class="fal fa-plus nav-icon"></i>
                  <p>{{ $t("create") }}</p>
                </router-link>
              </li>
            </ul>
          </li>

          <li
            class="nav-item"
            :class="
              activeClass(
                ['suppliers', 'suppliers.create', 'suppliers.edit'],
                'open-menu'
              )
            "
          >
            <a
              href="#"
              class="nav-link"
              :class="
                activeClass(['suppliers', 'suppliers.create', 'suppliers.edit'])
              "
            >
              <i class="nav-icon fal fa-truck-container"></i>
              <p>
                {{ $t("suppliers") }}<i class="fal fa-angle-left right"></i>
              </p>
            </a>
            <ul
              class="nav nav-treeview"
              :style="
                displaySubList([
                  'suppliers',
                  'suppliers.create',
                  'suppliers.edit',
                ])
              "
            >
              <li class="nav-item">
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'suppliers' }"
                  :class="activeClass(['suppliers'])"
                >
                  <i class="fal fa-list nav-icon"></i>
                  <p>{{ $t("list") }}</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'suppliers.create' }"
                  :class="activeClass(['suppliers.create'])"
                >
                  <i class="fal fa-plus nav-icon"></i>
                  <p>{{ $t("create") }}</p>
                </router-link>
              </li>
            </ul>
          </li>

          <li
            class="nav-item"
            :class="
              activeClass(
                ['contracts', 'contracts.create', 'contracts.edit'],
                'open-menu'
              )
            "
          >
            <a
              href="#"
              class="nav-link"
              :class="
                activeClass(['contracts', 'contracts.create', 'contracts.edit'])
              "
            >
              <i class="nav-icon fal fa-file-contract"></i>
              <p>
                {{ $t("contracts") }}<i class="fal fa-angle-left right"></i>
              </p>
            </a>
            <ul
              class="nav nav-treeview"
              :style="
                displaySubList([
                  'contracts',
                  'contracts.create',
                  'contracts.edit',
                ])
              "
            >
              <li class="nav-item">
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'contracts' }"
                  :class="activeClass(['contracts'])"
                >
                  <i class="fal fa-list nav-icon"></i>
                  <p>{{ $t("list") }}</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  tag="a"
                  class="nav-link"
                  :to="{ name: 'contracts.create' }"
                  :class="activeClass(['contracts.create'])"
                >
                  <i class="fal fa-plus nav-icon"></i>
                  <p>{{ $t("create") }}</p>
                </router-link>
              </li>
            </ul>
          </li>

          <li class="nav-item" :class="activeClass(['invoice', 'invoice.create', 'invoice.edit'],'open-menu')">
            <a href="#" class="nav-link" :class="activeClass(['invoice', 'invoice.create', 'invoice.edit'])">
              <i class="nav-icon fal fa-file-contract"></i>
              <p>
                {{ $t("realStock") }}<i class="fal fa-angle-left right"></i>
              </p>
            </a>
            <ul class="nav nav-treeview" :style="displaySubList(['invoice', 'invoice.create', 'invoice.edit'])">
              <li class="nav-item">
                <router-link
                    tag="a"
                    class="nav-link"
                    :to="{ name: 'invoice' }"
                    :class="activeClass(['invoice'])"
                >
                  <i class="fal fa-clipboard-list-check nav-icon"></i>
                  <p>{{ $t("invoice") }}</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                    tag="a"
                    class="nav-link"
                    :to="{ name: 'invoice.create' }"
                    :class="activeClass(['invoice.create'])"
                >
                  <i class="fal fa-clipboard-list-check nav-icon"></i>
                  <p>{{ $t("invoiceCreate") }}</p>
                </router-link>
              </li>
            </ul>
          </li>

          <li
              class="nav-item"
              :class="
              activeClass(
                ['productTypesView', 'productTypes.create.batch', 'productTypes.create.dragdrop', 'suppliers.batch', 'product.type.user.create'],
                'open-menu'
              )
            "
          >
            <a
                href="#"
                class="nav-link"
                :class="
                activeClass(['productTypesView', 'productTypes.create.batch', 'productTypes.create.dragdrop', 'suppliers.batch', 'product.type.user', 'product.type.user.create'])
              "
            >
              <i class="nav-icon fal fa-file-contract"></i>
              <p>
                {{ $t("batch") }}<i class="fal fa-angle-left right"></i>
              </p>
            </a>
            <ul
                class="nav nav-treeview"
                :style="displaySubList(['productTypesView', 'productTypes.create.batch', 'productTypes.create.dragdrop', 'suppliers.batch', 'product.type.user.create'])"
            >
              <li class="nav-item">
                <router-link
                    tag="a"
                    class="nav-link"
                    :to="{ name: 'productTypesView' }"
                    :class="activeClass(['productTypesView'])"
                >
                  <i class="fal fa-clipboard-list-check nav-icon"></i>
                  <p>{{ $t("productTypesView") }}</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                    tag="a"
                    class="nav-link"
                    :to="{ name: 'productTypes.create.batch' }"
                    :class="activeClass(['productTypes.create.batch '])"
                >
                  <i class="fal fa-clipboard-list-check nav-icon"></i>
                  <p>{{ $t("createProductTypeBatch") }}</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                    tag="a"
                    class="nav-link"
                    :to="{ name: 'productTypes.create.dragdrop' }"
                    :class="activeClass(['productTypes.create.dragdrop'])"
                >
                  <i class="fal fa-clipboard-list-check nav-icon"></i>
                  <p>{{ $t("dragAndDrop") }}</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                    tag="a"
                    class="nav-link"
                    :to="{ name: 'suppliers.batch' }"
                    :class="activeClass(['suppliers.batch'])"
                >
                  <i class="fal fa-plus nav-icon"></i>
                  <p>{{ $t("supplierBatch") }}</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                    tag="a"
                    class="nav-link"
                    :to="{ name: 'product.type.user' }"
                    :class="activeClass(['product.type.user', 'product.type.user.create'])"
                >
                  <i class="fal fa-plus nav-icon"></i>
                  <p>{{ $t("productTypeUser") }}</p>
                </router-link>
              </li>
            </ul>
          </li>

          <li class="nav-item">
            <a
              class="nav-link"
              @click.prevent="logout"
              style="cursor: pointer;"
            >
              <i class="fal fa-sign-out-alt nav-icon"></i>
              <p>{{ $t("logout") }}</p>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </aside>
</template>

<script>
export default {
  name: "Sidebar",
  data() {
    return {
      systemImage: this.$helpers.projectTenantLogo(),
    };
  },
  methods: {
    async logout() {
      await this.$store
        .dispatch("auth/LOGOUT")
        .then(() => this.$router.push({ name: "login" }));
    },
    activeClass(routesName, className = "active") {
      if (routesName.includes(this.$route.name)) {
        return className;
      }
      return "";
    },
    displaySubList(routesName) {
      if (routesName.includes(this.$route.name)) {
        return "display: block";
      }
      return "display: none";
    },
  },
};
</script>
