import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import mixin from "@/mixins";

const diskSizesToProduct = {
    namespaced: true,
    state: {
        diskSizesToProduct: {},
        createdDiskSizesToProduct: {},
    },
    mutations: {
        SET_DISK_SIZES_TO_PRODUCT (state, value) { state.diskSizesToProduct = value },
        SET_CREATED_DISK_SIZES_TO_PRODUCT (state, value) {state.createdDiskSizesToProduct = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/Products/ProductDiskSize` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}`)
                    .then((response) => {
                        commit('SET_DISK_SIZES_TO_PRODUCT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_DISK_SIZES_TO_PRODUCT', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.post(`/Products/ProductDiskSize`, form)
                    .then((response) => {
                        commit('SET_CREATED_DISK_SIZES_TO_PRODUCT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_DISK_SIZES_TO_PRODUCT', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/Products/ProductDiskSize/${ids}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        diskSizesToProduct: state => state.diskSizesToProduct,
        createdDiskSizesToProduct: state => state.createdDiskSizesToProduct,
    }
}

export default diskSizesToProduct;
