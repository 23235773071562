<template>
  <div>
    <content-header>{{ $t('editCustomer') }}</content-header>
    <v-content>
      <b-card no-body>
        <b-tabs card>
          <b-tab :title="$t('edit')" active>
            <form class="forms-sample" @submit.prevent="onSubmit">
              <text-input id="firstName" :name="$t('firstName')" :validate="$v" :required="true" :max-length="true" :is-loading="isLoading" />
              <text-input id="middleName" :name="$t('middleName')" :validate="$v" :required="true" :max-length="true" :is-loading="isLoading" />
              <text-input id="lastName" :name="$t('lastName')" :validate="$v" :required="true" :max-length="true" :is-loading="isLoading" />
              <date-time-input id="dob" :name="$t('dob')" :validate="$v" :is-loading="isLoading" />
              <text-input id="phone" :name="$t('phone')" :validate="$v" :required="true" :is-loading="isLoading" />
              <text-input id="phone2" :name="$t('phone2')" :validate="$v" :is-loading="isLoading" />
              <text-input id="address" :name="$t('address')" :validate="$v" :is-loading="isLoading" />
              <text-input id="email" :name="$t('email')" :validate="$v" :is-loading="isLoading" />
              <text-area-input id="note" :name="$t('note')" :validate="$v" :is-loading="isLoading" />
              <file-input
                  id="attachment"
                  :required="true"
                  :name="$t('attachment')"
                  @fileAdded="base64File => form.attachment = base64File"
              />
              <submit-input icon="fa-save" :name="$t('save')" :disabled="isLoading"/>
              <is-loading v-if="isLoading" />
            </form>
          </b-tab>
          <b-tab :title="$t('attachment')">
            <b-button
                variant="outline-primary"
                class="float-right"
                @click.prevent="$router.push({ name: 'customers.attachment.create', params: {id: $route.params.id} })"
            >
              <i class="fas fa-plus-circle"></i> {{ $t('create') }}
            </b-button>
            <customer-attachments-list :attachments="customerAttachments" @loadAll="loadOneData" />
          </b-tab>
        </b-tabs>
      </b-card>
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import {required, maxLength} from 'vuelidate/lib/validators'
import IsLoading from "@/components/IsLoading";
import {Customer} from "@/models/Customer";
import SubmitInput from "@/components/Inputs/SubmitInput";
import TextInput from "@/components/Inputs/TextInput";
import FileInput from "@/components/Inputs/FileInput";
import DateTimeInput from "@/components/Inputs/DateTimeInput";
import TextAreaInput from "@/components/Inputs/TextAreaInput";
import CustomerAttachmentsList from "@/views/customers/attachments/List";

export default {
  name: "CustomerEdit",
  components: {CustomerAttachmentsList, TextAreaInput, DateTimeInput, FileInput, SubmitInput, ContentHeader, 'v-content': Content, IsLoading, TextInput},
  data() {
    return {
      form: this.$helpers.convertArrayToObject(new Customer().fillAbles),
      isLoading: false,
      selectedItem: {},
      customerAttachments: []
    };
  },
  mounted() {
    this.isLoading = true;
    this.$store.dispatch('customers/GET_ONE', this.$route.params.id).then((response) => {
      this.form                 = response.data;
      this.customerAttachments  = response.data.attachments;
      this.isLoading            = false;
    }).catch(() => this.isLoading = false)
  },
  validations: {
    form: {
      firstName: {required, maxLength: maxLength(255)},
      middleName: {required, maxLength: maxLength(255)},
      lastName: {required, maxLength: maxLength(255)},
      dob: {},
      phone: {required},
      phone2: {},
      address: {},
      attachment: {},
      email: {},
      note: {},
    }
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return false;
      this.isLoading = true;
      this.$store.dispatch('customers/UPDATE', {id: this.$route.params.id, form: this.form}).then(() => {
        this.$v.$reset();
        this.$router.push({name: "customers"})
      }).catch(() => this.isLoading = false);
    },
    loadOneData() {
      this.isLoading = true;
      this.$store.dispatch('customers/GET_ONE', this.$route.params.id).then((response) => {
        this.form                 = response.data;
        this.customerAttachments  = response.data.attachments;
        this.isLoading            = false;
      }).catch(() => this.isLoading = false)
    }
  }
}
</script>
