<template>
  <div>
    <content-header>{{ $t('createMostSale') }}</content-header>
    <v-content>
      <b-card>
        <b-card-body>
          <form class="forms-sample" @submit.prevent="onSubmit">
            <text-input id="name" :name="$t('name')" :validate="$v" :required="true" :max-length="true" :is-loading="isLoading" />
            <text-input id="kuName" :name="$t('kuName')" :validate="$v" :required="true" :max-length="true" :is-loading="isLoading" />
            <text-input id="arName" :name="$t('arName')" :validate="$v" :required="true" :max-length="true" :is-loading="isLoading" />
            <boolean-input
                id="useSeparateAttachment"
                :name="$t('useSeparateAttachment')"
                :load-on-mount="false"
                :default-value="true"
                :checked-label="$t('yes')"
                :un-checked-label="$t('no')"
                label-class="col-md-4"
                input-class="col-md-8"
                @switched="(e) => switched(e)"
            />
            <file-input
                id="attachment"
                :required="true"
                :name="$t('attachment')"
                @fileAdded="base64File => form.attachment = base64File"
            />
            <file-input
                v-if="separateAttachment"
                id="kuAttachment"
                :required="true"
                :name="$t('kuAttachment')"
                @fileAdded="base64File => form.kuAttachment = base64File"
            />
            <file-input
                v-if="separateAttachment"
                id="arAttachment"
                :required="true"
                :name="$t('arAttachment')"
                @fileAdded="base64File => form.arAttachment = base64File"
            />
            <submit-input :name="$t('create')" :disabled="isLoading"/>
            <is-loading v-if="isLoading" />
          </form>
        </b-card-body>
      </b-card>
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import { required, maxLength } from 'vuelidate/lib/validators'
import IsLoading from "@/components/IsLoading";
import SubmitInput from "@/components/Inputs/SubmitInput";
import TextInput from "@/components/Inputs/TextInput";
import {MostSale} from "@/models/MostSale";
import FileInput from "@/components/Inputs/FileInput";
import GroupTypes from "@/enums/GroupTypes";
import BooleanInput from "@/components/Inputs/BooleanInput";

export default {
  name: "MostSalesCreate",
  components: {BooleanInput, FileInput, TextInput, SubmitInput, ContentHeader, 'v-content': Content, IsLoading},
  data() {
    return {
      form: this.$helpers.convertArrayToObject(new MostSale().fillAbles),
      isLoading: false,
      separateAttachment: true,
      selectedItem: {},
    }
  },
  validations: {
    form: {
      name: {required, maxLength: maxLength(255)},
      kuName: {required, maxLength: maxLength(255)},
      arName: {required, maxLength: maxLength(255)},
      useSeparateAttachment: {},
      attachment: {},
      kuAttachment: {},
      arAttachment: {},
    }
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return false;
      this.isLoading = true;
      this.form.groupProductType = GroupTypes.MostSale;
      this.$store.dispatch('mostSales/CREATE', this.form).then(() => {
        this.$v.$reset();
        this.$router.push({name: "most.sale"})
      }).catch(() => this.isLoading = false);
    },
    switched(e) {
      this.form.useSeparateAttachment = e
      this.separateAttachment = e
    }
  }
}
</script>
