<template>
  <div class="card p-3" style="position: relative">
    <h3>{{ data.name }} ({{ data.sort }})</h3>
    <img style="position: absolute;top: 5px;right: 5px;" :src="getImageUrl(data.attachment)" :alt="data.name" width="64" height="64" />
  </div>
</template>

<script>
export default {
  name: 'OneItem',
  props: ['data']
}
</script>