import Vue from 'vue'
import VueRouter from 'vue-router'
// import store from './../store'
import { i18n } from '@/plugins/i18n';
import Login from "../views/Login";
import Main from "../views/Main";
import NotFound from "../components/errors/NotFound";
import admin from "./admin";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Main,
    children: admin
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: i18n.t('login')
    }
  },
  { path: "*", name: "notfound", component: NotFound }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

// router.beforeEach((to, from, next) => {
//   window.document.title = to.meta && to.meta.title ? to.meta.title : process.env.VUE_APP_PROJECT_NAME;
//   if(to.matched.some(record => record.meta.requiresAuth)) {
//     if (store.getters["auth/isLoggedIn"] || localStorage.getItem(process.env.VUE_APP_TOKEN_NAME)) {
//       next()
//       return
//     }
//     next('/login')
//   } else {
//     if (store.getters["auth/isLoggedIn"] || localStorage.getItem(process.env.VUE_APP_TOKEN_NAME)) {
//       if (to.name === 'login') {
//         next('/');
//       }
//       next()
//       return
//     }
//     next()
//   }
//   if (store.state.language.language && store.state.language.language !== i18n.locale) {
//     i18n.locale = store.state.language.language;
//     next();
//   } else if (!store.state.language.language) {
//     store.dispatch('language/CHANGE_LANGUAGE', navigator.languages)
//         .then(() => {
//           next();
//         });
//   } else {
//     next();
//   }
// });

export default router
