<template>
  <div>
    <content-header>{{ $t('editAds') }}</content-header>
    <v-content>
      <b-card no-body>
        <b-tabs card>
          <b-tab :title="$t('edit')" active>
            <form class="forms-sample" @submit.prevent="onSubmit">
              <text-input id="name" :name="$t('name')" :validate="$v" :required="true" :max-length="true" :is-loading="isLoading" />
              <text-input id="kuName" :name="$t('kuName')" :validate="$v" :required="true" :max-length="true" :is-loading="isLoading" />
              <text-input id="arName" :name="$t('arName')" :validate="$v" :required="true" :max-length="true" :is-loading="isLoading" />
              <boolean-input
                  id="useSeparateAttachment"
                  :name="$t('useSeparateAttachment')"
                  :load-on-mount="false"
                  :default-value="form.useSeparateAttachment"
                  :checked-label="$t('yes')"
                  :un-checked-label="$t('no')"
                  label-class="col-md-4"
                  input-class="col-md-8"
                  @switched="(e) => switched(e)"
              />
              <file-input
                  id="attachment"
                  :required="true"
                  :name="$t('attachment')"
                  @fileAdded="base64File => form.attachment = base64File"
              />
              <file-input
                  v-if="separateAttachment"
                  id="kuAttachment"
                  :required="true"
                  :name="$t('kuAttachment')"
                  @fileAdded="base64File => form.kuAttachment = base64File"
              />
              <file-input
                  v-if="separateAttachment"
                  id="arAttachment"
                  :required="true"
                  :name="$t('arAttachment')"
                  @fileAdded="base64File => form.arAttachment = base64File"
              />
              <submit-input icon="fa-save" :name="$t('save')" :disabled="isLoading" />
              <is-loading v-if="isLoading" />
            </form>
          </b-tab>
        </b-tabs>
      </b-card>
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import {required, maxLength} from 'vuelidate/lib/validators';
import IsLoading from "@/components/IsLoading";
import {Ads} from "@/models/Ads";
import SubmitInput from "@/components/Inputs/SubmitInput";
import TextInput from "@/components/Inputs/TextInput";
import FileInput from "@/components/Inputs/FileInput";
import GroupTypes from "@/enums/GroupTypes";
import BooleanInput from "@/components/Inputs/BooleanInput";

export default {
  name: "AdsEdit",
  components: {BooleanInput, FileInput, SubmitInput, ContentHeader, 'v-content': Content, IsLoading, TextInput},
  data() {
    return {
      form: this.$helpers.convertArrayToObject(new Ads().fillAbles),
      products: [],
      separateAttachment: true,
      isLoading: false,
    };
  },
  mounted() {
    this.isLoading = true;
    this.$store.dispatch('ads/GET_ONE', this.$route.params.id).then((response) => {
      this.form      = response.data;
      this.products  = response.data.products;
      this.isLoading = false;
    }).catch(() => this.isLoading = false)
  },
  validations: {
    form: {
      name: {required, maxLength: maxLength(255)},
      kuName: {required, maxLength: maxLength(255)},
      arName: {required, maxLength: maxLength(255)},
      useSeparateAttachment: {},
      attachment: {},
      kuAttachment: {},
      arAttachment: {},
    }
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return false;
      this.isLoading = true;
      this.form.groupProductType = GroupTypes.Ads;
      this.form.useSeparateAttachment = true;
      this.$store.dispatch('ads/UPDATE', {id: this.$route.params.id, form: this.form}).then(() => {
        this.$v.$reset();
        this.isLoading = false;
        this.$router.push({name: 'ads'});
      }).catch(() => this.isLoading = false);
    },
    loadOneData() {
      this.isLoading = true;
      this.$store.dispatch('ads/GET_ONE', this.$route.params.id).then((response) => {
        this.form       = response.data;
        this.products   = response.data.products;
        this.isLoading  = false;
      }).catch(() => this.isLoading = false)
    },
    switched(e) {
      this.form.useSeparateAttachment = e
      this.separateAttachment = e
    }
  }
}
</script>
