<template>
  <div class="form-group row">
    <label class="col-sm-2 col-form-label text-right">{{ name }}</label>
    <div class="col-sm-10">
      <p class="lead pt-1 text-left">{{ type === 'number' ? value.toLocaleString() : value }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TextView",
  props: {
    name: {
      type: String
    },
    value: {},
    type: {
      type: String,
      default: 'number'
    }
  }
}
</script>
