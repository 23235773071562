import { i18n } from "@/plugins/i18n";

export class MadeIn {
  columns = [];
  id = "id";

  fillAbles = [
    ["name", i18n.t("name"), true],
    ["kuName", i18n.t("kuName"), true],
    ["arName", i18n.t("arName"), true],
    ["attachment", i18n.t("attachment"), true],
    ["controls", i18n.t("controls"), false],
  ];

  getColumns() {
    this.columns = [
      {
        label: "Id",
        name: "id",
        sort: false,
        visibility: false,
        uniqueId: true,
      },
    ];
    this.fillAbles.map((e) => {
      this.columns.push({
        name: e[0],
        label: e[1],
        sort: e[2],
        visibility: e[3] ?? true,
      });
    });
    return this.columns;
  }
}
