import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import ProductAttributes from "@/enums/ProductAttributes";
import mixin from "@/mixins";

const productRamSizes = {
    namespaced: true,
    state: {
        productRamSizes: {},
        createdProductRamSize: {},
        getOneProductRamSize: {},
        updatedProductRamSize: {},
    },
    mutations: {
        SET_PRODUCT_RAM_SIZES (state, value) { state.productRamSizes = value },
        SET_CREATED_PRODUCT_RAM_SIZE (state, value) {state.createdProductRamSize = value},
        SET_GET_ONE_PRODUCT_RAM_SIZE (state, value) {state.getOneProductRamSize = value},
        SET_UPDATED_PRODUCT_RAM_SIZE (state, value) {state.updatedProductRamSize = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/Main` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}` +
                        `&objectName=` + ProductAttributes.AvailableRamSizes)
                    .then((response) => {
                        commit('SET_PRODUCT_RAM_SIZES', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_PRODUCT_RAM_SIZES', {})
                        reject(error)
                    });
            })
        },
        GET_ONE({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/Main/${id}?objectName=` + ProductAttributes.AvailableRamSizes)
                    .then((response) => {
                        commit('SET_GET_ONE_PRODUCT_RAM_SIZE', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GET_ONE_PRODUCT_RAM_SIZE', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.post(`/Main?objectName=` + ProductAttributes.AvailableRamSizes, form)
                    .then((response) => {
                        commit('SET_CREATED_PRODUCT_RAM_SIZE', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_PRODUCT_RAM_SIZE', {})
                        reject(error)
                    });
            })
        },
        UPDATE({commit}, {id, form}) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.put(`/Main/${id}?objectName=` + ProductAttributes.AvailableRamSizes, form)
                    .then((response) => {
                        commit('SET_UPDATED_PRODUCT_RAM_SIZE', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_UPDATED_PRODUCT_RAM_SIZE', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/Main/${ids}?objectName=` + ProductAttributes.AvailableRamSizes)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        productRamSizes: state => state.productRamSizes,
        createdProductRamSize: state => state.createdProductRamSize,
        getOneProductRamSize: state => state.getOneProductRamSize,
        updatedProductRamSize: state => state.updatedProductRamSize,
    }
}

export default productRamSizes;
