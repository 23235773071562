import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import mixin from "@/mixins";
import GroupTypes from "@/enums/GroupTypes";
import ProductAttributes from "@/enums/ProductAttributes";

const newSeason = {
    namespaced: true,
    state: {
        newSeason: {},
        createdNewSeason: {},
        getOneNewSeason: {},
        updatedNewSeason: {},
    },
    mutations: {
        SET_NEW_SEASONS (state, value) { state.newSeason = value },
        SET_CREATED_NEW_SEASON (state, value) {state.createdNewSeason = value},
        SET_GET_ONE_NEW_SEASON (state, value) {state.getOneNewSeason = value},
        SET_UPDATED_NEW_SEASON (state, value) {state.updatedNewSeason = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/Main` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}` +
                        `&objectName=${ProductAttributes.Group}&groupType=${GroupTypes.NewSeason}`)
                    .then((response) => {
                        commit('SET_NEW_SEASONS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_NEW_SEASONS', {})
                        reject(error)
                    });
            })
        },
        GET_ONE({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/Main/${id}?objectName=${ProductAttributes.Group}`)
                    .then((response) => {
                        commit('SET_GET_ONE_NEW_SEASON', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GET_ONE_NEW_SEASON', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.post(`/Main?objectName=${ProductAttributes.Group}`, form)
                    .then((response) => {
                        commit('SET_CREATED_NEW_SEASON', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_NEW_SEASON', {})
                        reject(error)
                    });
            })
        },
        UPDATE({commit}, {id, form}) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.put(`/Main/${id}?objectName=${ProductAttributes.Group}`, form)
                    .then((response) => {
                        commit('SET_UPDATED_NEW_SEASON', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_UPDATED_NEW_SEASON', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/Main/${ids}?objectName=${ProductAttributes.Group}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        newSeason: state => state.newSeason,
        createdNewSeason: state => state.createdNewSeason,
        getOneNewSeason: state => state.getOneNewSeason,
        updatedNewSeason: state => state.updatedNewSeason,
    }
}

export default newSeason;
