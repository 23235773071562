import {HTTP} from "@/plugins/axios";

const dashboard = {
    namespaced: true,
    state: {
        widgetNumbers: {}
    },
    mutations: {
        SET_WIDGET_NUMBERS (state, value) { state.widgetNumbers = value }
    },
    actions: {
        GET_ALL_WIDGET_NUMBERS({commit}) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/Dashboard`)
                    .then((response) => {
                        commit('SET_WIDGET_NUMBERS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_WIDGET_NUMBERS', {})
                        reject(error)
                    });
            })
        }
    },
    getters : {
        widgetNumbers: state => state.widgetNumbers
    }
}

export default dashboard;
