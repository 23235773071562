<template>
  <b-link v-if="attachment" :href="getImageUrl(attachment)" target="_blank">
    <b-img
        :src="getImageUrl(attachment)"
        :width="width"
        :height="height"
        thumbnail
        rounded
        fluid
        :alt="title"
    ></b-img>
  </b-link>
  <p v-else>-</p>
</template>

<script>
export default {
  name: "ShowAttachment",
  props: {
    attachment: {
      type: String,
    },
    title: {
      type: String,
      default: '-'
    },
    width: {
      type: Number,
      default: 800
    },
    height: {
      type: Number,
      default: 400
    }
  }
}
</script>
