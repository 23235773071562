<template>
  <div class="form-group row">
    <label v-if="showLabel" :for="id" class="col-sm-2 col-form-label text-right" :class="{'required-field': required}">{{ name }}</label>
    <div :class="{'col-sm-12': !showLabel, 'col-sm-10': showLabel}">
      <input
        :id="id"
        v-model.trim="validate[form][id].$model"
        :class="{'is-invalid': validationStatus(validate[form][id])}"
        :disabled="isLoading || readonly"
        :placeholder="name"
        type="number"
        autocomplete="off"
        class="form-control"
        @focus="$event.target.select()"
        v-on:keypress="e => $helpers.isNumeric(e)"
        :tabindex="tabIndex"
        step=".001"
      />
      <span v-if="showLocaleString" class="ml-2">{{ parseFloat(validate[form][id].$model).toLocaleString() }}</span>
      <div v-if="!validate[form][id].required && required" class="invalid-feedback">{{ name }} {{ $t('isRequired') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NumberInput",
  props: {
    form: {
      type: String,
      default: 'form'
    },
    id: {
      type: String
    },
    name: {},
    showLabel: {
      type: Boolean,
      default: true
    },
    defaultValue: {
      default: 0
    },
    readonly: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean
    },
    validate: {
      type: Object
    },
    required: {
      type: Boolean
    },
    minLength: {
      type: Boolean
    },
    maxLength: {
      type: Boolean
    },
    showLocaleString: {
      type: Boolean,
      default: false
    },
    tabIndex: {
      default: null
    }
  },
  mounted() {
    if (this.defaultValue !== '') {
      this.validate[this.form][this.id].$model = this.defaultValue
    }
  },
  methods: {
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    }
  }
}
</script>
