import {i18n} from "@/plugins/i18n";

export class Product {
    columns = [];
    id      = 'id';

    fillAbles = [
        [
            'code',
            i18n.t('code'),
            true
        ],
        [
            'barcode',
            i18n.t('barcode'),
            true,
            false
        ],
        [
            'name',
            i18n.t('name'),
            true
        ],
        [
            'kuName',
            i18n.t('kuName'),
            true
        ],
        [
            'arName',
            i18n.t('arName'),
            true
        ],
        [
            'description',
            i18n.t('description'),
            true,
            false
        ],
        [
            'kuDescription',
            i18n.t('kuDescription'),
            true,
            false
        ],
        [
            'arDescription',
            i18n.t('arDescription'),
            true,
            false
        ],
        [
            'totalAvailableQty',
            i18n.t('totalAvailableQty'),
            true,
            false
        ],
        [
            'soldQty',
            i18n.t('soldQty'),
            true,
            false
        ],
        [
            'increasePercentage',
            i18n.t('increasePercentage'),
            true,
            false
        ],
        [
            'contains',
            i18n.t('contains'),
            true,
            false
        ],
        [
            'minOrder',
            i18n.t('minOrder'),
            true,
            false
        ],
        [
            'shippingPrice',
            i18n.t('shippingPrice'),
            true,
            false
        ],
        [
            'supplierId',
            i18n.t('supplierId'),
            true,
            false
        ],
        [
            'cost',
            i18n.t('cost'),
            true
        ],
        [
            'price',
            i18n.t('price'),
            true
        ],
        [
            'discount',
            i18n.t('discount'),
            true
        ],
        [
            'brandName',
            i18n.t('brandName'),
            true,
            false
        ],
        [
            'productTypeName',
            i18n.t('productTypeName'),
            true
        ],
        [
            'note',
            i18n.t('note'),
            true,
            false
        ],
        [
            'shippingTime',
            i18n.t('shippingTime'),
            true,
            false
        ],
        [
            'width',
            i18n.t('width'),
            true,
            false
        ],
        [
            'length',
            i18n.t('length'),
            true,
            false
        ],
        [
            'height',
            i18n.t('height'),
            true,
            false
        ],
        [
            'weight',
            i18n.t('weight'),
            true,
            false
        ],
        [
            'cbm',
            i18n.t('cbm'),
            true,
            false
        ],
        [
            'season',
            i18n.t('season'),
            true,
            false
        ],
        [
            'madeIn',
            i18n.t('madeIn'),
            true,
            false
        ],
        [
            'productStatus',
            i18n.t('productStatus'),
            true,
            false
        ],
        [
            'controls',
            i18n.t('controls'),
            false
        ]
    ];

    getColumns() {
        this.columns = [
            {
                label: 'Id',
                name: 'id',
                sort: false,
                visibility: false,
                uniqueId: true
            },
        ];
        this.fillAbles.map(e => {
            this.columns.push({
                name: e[0],
                label: e[1],
                sort: e[2],
                visibility: e[3] ?? true
            })
        })
        return this.columns;
    }
}
