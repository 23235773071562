import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import mixin from "@/mixins";

const contracts = {
    namespaced: true,
    state: {
        contracts: {},
        createdContract: {},
        getOneContract: {},
        updatedContract: {},
        createdPaymentContract: {},
    },
    mutations: {
        SET_CONTRACTS (state, value) { state.contracts = value },
        SET_CREATED_CONTRACT (state, value) {state.createdContract = value},
        SET_GET_ONE_CONTRACT (state, value) {state.getOneContract = value},
        SET_UPDATED_CONTRACT (state, value) {state.updatedContract = value},
        SET_CREATED_PAYMENT_CONTRACT (state, value) {state.createdPaymentContract = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/Contracts` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}`)
                    .then((response) => {
                        commit('SET_CONTRACTS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CONTRACTS', {})
                        reject(error)
                    });
            })
        },
        GET_ONE({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/Contracts/${id}`)
                    .then((response) => {
                        commit('SET_GET_ONE_CONTRACT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GET_ONE_CONTRACT', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.post(`/Contracts`, form)
                    .then((response) => {
                        commit('SET_CREATED_CONTRACT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_CONTRACT', {})
                        reject(error)
                    });
            })
        },
        UPDATE({commit}, {id, form}) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.put(`/Contracts/${id}`, form)
                    .then((response) => {
                        commit('SET_UPDATED_CONTRACT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_UPDATED_CONTRACT', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/Contracts/${ids}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
        PAY({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.post(`/Contracts/PayInstallment/${form.id}?note=${form.note}`, form)
                    .then((response) => {
                        commit('SET_CREATED_PAYMENT_CONTRACT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_PAYMENT_CONTRACT', {})
                        reject(error)
                    });
            })
        },
        CREATE_CONTRACT_INSURANCES({commit}, {id, form}) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.post(`/Contracts/AddInsurance/${id}`, form)
                    .then((response) => {
                        commit('SET_CREATED_CONTRACT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_CONTRACT', {})
                        reject(error)
                    });
            })
        },
        DELETE_CONTRACT_INSURANCES(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/Contracts/DeleteInsurance/${ids}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        contracts: state => state.contracts,
        createdContract: state => state.createdContract,
        getOneContract: state => state.getOneContract,
        updatedContract: state => state.updatedContract,
        createdPaymentContract: state => state.createdPaymentContract,
    }
}

export default contracts;
