import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import ProductAttributes from "@/enums/ProductAttributes";
import mixin from "@/mixins";

const collections = {
    namespaced: true,
    state: {
        collections: {},
        createdCollection: {},
        getOneCollection: {},
        updatedCollection: {},
    },
    mutations: {
        SET_COLLECTIONS (state, value) { state.collections = value },
        SET_CREATED_COLLECTION (state, value) {state.createdCollection = value},
        SET_GET_ONE_COLLECTION (state, value) {state.getOneCollection = value},
        SET_UPDATED_COLLECTION (state, value) {state.updatedCollection = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/Main` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}` +
                        `&objectName=` + ProductAttributes.Collection)
                    .then((response) => {
                        commit('SET_COLLECTIONS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_COLLECTIONS', {})
                        reject(error)
                    });
            })
        },
        GET_ONE({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/Main/${id}?objectName=` + ProductAttributes.Collection)
                    .then((response) => {
                        commit('SET_GET_ONE_COLLECTION', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GET_ONE_COLLECTION', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.post(`/Main?objectName=` + ProductAttributes.Collection, form)
                    .then((response) => {
                        commit('SET_CREATED_COLLECTION', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_COLLECTION', {})
                        reject(error)
                    });
            })
        },
        UPDATE({commit}, {id, form}) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.put(`/Main/${id}?objectName=` + ProductAttributes.Collection, form)
                    .then((response) => {
                        commit('SET_UPDATED_COLLECTION', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_UPDATED_COLLECTION', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/Main/${ids}?objectName=` + ProductAttributes.Collection)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        collections: state => state.collections,
        createdCollection: state => state.createdCollection,
        getOneCollection: state => state.getOneCollection,
        updatedCollection: state => state.updatedCollection,
    }
}

export default collections;
