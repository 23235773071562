import {i18n} from "@/plugins/i18n";

export class CustomerAccount {
    columns = [];
    id      = 'id';

    fillAbles = [
        [
            'firstName',
            i18n.t('firstName'),
            true
        ],
        [
            'middleName',
            i18n.t('middleName'),
            true,
        ],
        [
            'lastName',
            i18n.t('lastName'),
            true,
        ],
        [
            'userName',
            i18n.t('userName'),
            true
        ],
        [
            'password',
            i18n.t('password'),
            true
        ],
        [
            'userType',
            i18n.t('userType'),
            true
        ]
    ];

    getColumns() {
        this.columns = [
            {
                label: 'Id',
                name: 'id',
                sort: false,
                visibility: false,
                uniqueId: true
            },
        ];
        this.fillAbles.map(e => {
            this.columns.push({
                name: e[0],
                label: e[1],
                sort: e[2],
                visibility: e[3] ?? true
            })
        })
        return this.columns;
    }
}
