import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import mixin from "@/mixins";
import mixins from "@/mixins";

const suppliers = {
    namespaced: true,
    state: {
        suppliers: {},
        createdSupplier: {},
        getOneSupplier: {},
        updatedSupplier: {},
    },
    mutations: {
        SET_SUPPLIERS (state, value) { state.suppliers = value },
        SET_CREATED_SUPPLIER (state, value) {state.createdSupplier = value},
        SET_GET_ONE_SUPPLIER (state, value) {state.getOneSupplier = value},
        SET_UPDATED_SUPPLIER (state, value) {state.updatedSupplier = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/Suppliers` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}`)
                    .then((response) => {
                        commit('SET_SUPPLIERS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_SUPPLIERS', {})
                        reject(error)
                    });
            })
        },
        GET_ONE({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/Suppliers/${id}`)
                    .then((response) => {
                        commit('SET_GET_ONE_SUPPLIER', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GET_ONE_SUPPLIER', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.post(`/Suppliers`, form)
                    .then((response) => {
                        commit('SET_CREATED_SUPPLIER', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_SUPPLIER', {})
                        reject(error)
                    });
            })
        },
        UPDATE({commit}, {id, form}) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.put(`/Suppliers/${id}`, form)
                    .then((response) => {
                        commit('SET_UPDATED_SUPPLIER', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_UPDATED_SUPPLIER', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/Suppliers/${ids}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
        CREATE_SUPPLIER_ACCOUNT(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.post(`/Suppliers/CreateSupplierAccount?id=${ids}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
        DISABLE_SUPPLIER_ACCOUNT(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/Suppliers/DisableSupplierAccount?id=${ids}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
        SUPPLIER_BATCH(commit, form) {
            return new Promise((resolve, reject) => {
                HTTP.post(`/Utilities/SupplierBatchWorks?${mixins.serializeToQueryString(form)}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        suppliers: state => state.suppliers,
        createdSupplier: state => state.createdSupplier,
        getOneSupplier: state => state.getOneSupplier,
        updatedSupplier: state => state.updatedSupplier,
    }
}

export default suppliers;
