import {i18n} from "@/plugins/i18n";

export class Customer {
    columns = [];
    id      = 'id';

    fillAbles = [
        [
            'firstName',
            i18n.t('firstName'),
            true
        ],
        [
            'middleName',
            i18n.t('middleName'),
            true,
            false
        ],
        [
            'lastName',
            i18n.t('lastName'),
            true,
            false
        ],
        [
            'dob',
            i18n.t('dob'),
            true
        ],
        [
            'phone',
            i18n.t('phone'),
            true
        ],
        [
            'phone2',
            i18n.t('phone2'),
            true
        ],
        [
            'address',
            i18n.t('address'),
            true
        ],
        [
            'hasAccount',
            i18n.t('hasAccount'),
            true
        ],
        [
            'email',
            i18n.t('email'),
            true
        ],
        [
            'note',
            i18n.t('note'),
            true
        ],
        [
            'controls',
            i18n.t('controls'),
            false
        ]
    ];

    getColumns() {
        this.columns = [
            {
                label: 'Id',
                name: 'id',
                sort: false,
                visibility: false,
                uniqueId: true
            },
        ];
        this.fillAbles.map(e => {
            this.columns.push({
                name: e[0],
                label: e[1],
                sort: e[2],
                visibility: e[3] ?? true
            })
        })
        return this.columns;
    }
}
