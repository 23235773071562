<template>
  <div>
    <div>
      <content-header>{{ $t('createMostSaleProduct') }}</content-header>
      <v-content>
        <b-card>
          <b-card-body>
            <form class="forms-sample" @submit.prevent="onSubmit">
              <select-input
                  id="product"
                  :name="$t('product')"
                  :validate="$v"
                  :required="true"
                  api-link="products/GET_ALL"
                  label="name,+code,+barcode"
                  @itemSelected="e => form.productId = e"
              />
              <submit-input :name="$t('create')" :disabled="isLoading"/>
              <is-loading v-if="isLoading" />
            </form>
          </b-card-body>
        </b-card>
      </v-content>
    </div>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import { required } from 'vuelidate/lib/validators'
import IsLoading from "@/components/IsLoading";
import SubmitInput from "@/components/Inputs/SubmitInput";
import SelectInput from "@/components/Inputs/SelectInput";

export default {
  name: "MostSaleProductCreate",
  components: {SelectInput, SubmitInput, IsLoading, ContentHeader, 'v-content': Content},
  data() {
    return {
      form: {},
      isLoading: false
    }
  },
  validations: {
    form: {
      groupId: {},
      productId: {required},
    }
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return false;
      this.form.groupId  = parseInt(this.$route.params.id);
      this.isLoading = true;
      this.$store.dispatch('groupProducts/ADD_PRODUCT_TO_GROUP', this.form).then(() => {
        this.$v.$reset();
        this.$router.push({name: 'most.sale.edit', params: {id: this.$route.params.id}})
      }).catch(() => this.isLoading = false);
    },
  }
}
</script>
