<template>
  <div>
    <div>
      <content-header>{{ $t('createFeatureToProduct') }}</content-header>
      <v-content>
        <b-card>
          <b-card-body>
            <form class="forms-sample" @submit.prevent="onSubmit">
              <text-input id="name" :name="$t('name')" :validate="$v" :required="true" :max-length="true" :is-loading="isLoading" />
              <text-input id="kuName" :name="$t('kuName')" :validate="$v" :required="true" :max-length="true" :is-loading="isLoading" />
              <text-input id="arName" :name="$t('arName')" :validate="$v" :required="true" :max-length="true" :is-loading="isLoading" />
              <text-area-input id="detail" :name="$t('detail')" :validate="$v" :is-loading="isLoading" />
              <text-area-input id="kuDetail" :name="$t('kuDetail')" :validate="$v" :is-loading="isLoading" />
              <text-area-input id="arDetail" :name="$t('arDetail')" :validate="$v" :is-loading="isLoading" />
              <submit-input :name="$t('create')" :disabled="isLoading"/>
            </form>
          </b-card-body>
        </b-card>
      </v-content>
    </div>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import {maxLength, required} from 'vuelidate/lib/validators';
import {FeatureToProduct} from "@/models/FeatureToProduct";
import TextInput from "@/components/Inputs/TextInput";
import SubmitInput from "@/components/Inputs/SubmitInput";
import TextAreaInput from "@/components/Inputs/TextAreaInput";

export default {
  name: "FeatureToProductCreate",
  components: {TextAreaInput, SubmitInput, TextInput, ContentHeader, 'v-content': Content},
  data() {
    return {
      form: this.$helpers.convertArrayToObject(new FeatureToProduct().fillAbles),
      isLoading: false
    }
  },
  validations: {
    form: {
      name: {required, maxLength: maxLength(255)},
      kuName: {required, maxLength: maxLength(255)},
      arName: {required, maxLength: maxLength(255)},
      detail: {},
      kuDetail: {},
      arDetail: {},
    }
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return false;
      this.form.productId = this.$route.params.id;
      this.isLoading = true;
      this.$store.dispatch('featuresToProduct/CREATE', this.form).then(() => {
        this.$v.$reset();
        this.$router.push({name: 'products.edit', params: {id: this.$route.params.id}})
      }).catch(() => this.isLoading = false);
    },
  }
}
</script>
