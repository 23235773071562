<template>
  <div class="loading-container">
    <radar-spinner
        :animation-duration="1000"
        :size="120"
        :color="'#4E5BF2'"
    />
  </div>
</template>

<script>
import {RadarSpinner} from 'epic-spinners'
export default {
  name: "IsLoading",
  components: {
    RadarSpinner
  }
}
</script>

<style scoped>
.loading-container {
  z-index: 9999;
  position: absolute;
  top: 35%;
  left: 50%;
}
</style>
