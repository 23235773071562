<template>
  <div>
    <content-header>{{ $t('editProductType') }}</content-header>
    <v-content>
      <b-card>
        <b-card-body>
          <form class="forms-sample" @submit.prevent="onSubmit">
            <text-input id="name" :name="$t('name')" :validate="$v" :required="true" :max-length="true" :is-loading="isLoading" />
            <text-input id="kuName" :name="$t('kuName')" :validate="$v" :required="true" :max-length="true" :is-loading="isLoading" />
            <text-input id="arName" :name="$t('arName')" :validate="$v" :required="true" :max-length="true" :is-loading="isLoading" />
            <file-input
                id="attachment"
                :name="$t('attachment')"
                @fileAdded="base64File => form.attachment = base64File"
            />
            <submit-input icon="fa-save" :name="$t('save')" :disabled="isLoading"/>
            <is-loading v-if="isLoading" />
          </form>
        </b-card-body>
      </b-card>
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import { required, maxLength } from 'vuelidate/lib/validators'
import IsLoading from "@/components/IsLoading";
import {ProductType} from "@/models/ProductType";
import SubmitInput from "@/components/Inputs/SubmitInput";
import TextInput from "@/components/Inputs/TextInput";
import FileInput from "@/components/Inputs/FileInput";

export default {
  name: "ProductTypeEdit",
  components: {FileInput, SubmitInput, ContentHeader, 'v-content': Content, IsLoading, TextInput},
  data() {
    return {
      form: this.$helpers.convertArrayToObject(new ProductType().fillAbles),
      isLoading: false,
      selectedItem: {},
    };
  },
  mounted() {
    this.isLoading = true;
    this.$store.dispatch('productTypes/GET_ONE', this.$route.params.id).then((response) => {
      this.form       = response.data;
      this.isLoading  = false;
    }).catch(() => this.isLoading = false)
  },
  validations: {
    form: {
      name: {required, maxLength: maxLength(255)},
      kuName: {required, maxLength: maxLength(255)},
      arName: {required, maxLength: maxLength(255)},
      attachment: {},
    }
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return false;
      this.isLoading = true;
      this.$store.dispatch('productTypes/UPDATE', {id: this.$route.params.id, form: this.form}).then(() => {
        this.$v.$reset();
        this.$router.push({name: "productTypes"})
      }).catch(() => this.isLoading = false);
    }
  }
}
</script>
