import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import mixin from "@/mixins";

const customers = {
    namespaced: true,
    state: {
        customers: {},
        customerAccounts: {},
        createdCustomer: {},
        getOneCustomer: {},
        updatedCustomer: {},
    },
    mutations: {
        SET_CUSTOMERS (state, value) { state.customers = value },
        SET_CUSTOMER_ACCOUNTS (state, value) { state.customerAccounts = value },
        SET_CREATED_CUSTOMER (state, value) {state.createdCustomer = value},
        SET_GET_ONE_CUSTOMER (state, value) {state.getOneCustomer = value},
        SET_UPDATED_CUSTOMER (state, value) {state.updatedCustomer = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/Customers` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}`)
                    .then((response) => {
                        commit('SET_CUSTOMERS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CUSTOMERS', {})
                        reject(error)
                    });
            })
        },
        GET_ALL_ACCOUNTS({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/Customers/GetCustomerAccount` +
                    `${serverParams._start}` +
                    `${serverParams._end}` +
                    `${serverParams._sort}` +
                    `${serverParams._order}` +
                    `${serverParams._search}` +
                    `${serverParams.custom_params}`)
                    .then((response) => {
                        commit('SET_CUSTOMER_ACCOUNTS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CUSTOMER_ACCOUNTS', {})
                        reject(error)
                    });
            })
        },
        GET_ONE({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/Customers/${id}`)
                    .then((response) => {
                        commit('SET_GET_ONE_CUSTOMER', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GET_ONE_CUSTOMER', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.post(`/Customers`, form)
                    .then((response) => {
                        commit('SET_CREATED_CUSTOMER', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_CUSTOMER', {})
                        reject(error)
                    });
            })
        },
        UPDATE({commit}, {id, form}) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.put(`/Customers/${id}`, form)
                    .then((response) => {
                        commit('SET_UPDATED_CUSTOMER', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_UPDATED_CUSTOMER', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/Customers/${ids}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
        CREATE_CUSTOMER_ACCOUNT(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.post(`/Customers/CreateCustomerAccount?id=${ids}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
        DISABLE_CUSTOMER_ACCOUNT(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/Customers/DisableCustomerAccount?id=${ids}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        customers: state => state.customers,
        customerAccounts: state => state.customerAccounts,
        createdCustomer: state => state.createdCustomer,
        getOneCustomer: state => state.getOneCustomer,
        updatedCustomer: state => state.updatedCustomer,
    }
}

export default customers;
