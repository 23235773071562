import {HTTP} from "@/plugins/axios";
import {resolveServerParams} from "@/plugins/constants";
import ProductAttributes from "@/enums/ProductAttributes";
import mixin from "@/mixins";

const brands = {
    namespaced: true,
    state: {
        brands: {},
        createdBrand: {},
        getOneBrand: {},
        updatedBrand: {},
    },
    mutations: {
        SET_BRAND (state, value) { state.brands = value },
        SET_CREATED_BRAND (state, value) {state.createdBrand = value},
        SET_GET_ONE_BRAND (state, value) {state.getOneBrand = value},
        SET_UPDATED_BRAND (state, value) {state.updatedBrand = value},
    },
    actions: {
        GET_ALL({commit}, serverParams) {
            return new Promise((resolve, reject) => {
                serverParams = resolveServerParams(serverParams);
                HTTP.get(`/Main` +
                        `${serverParams._start}` +
                        `${serverParams._end}` +
                        `${serverParams._sort}` +
                        `${serverParams._order}` +
                        `${serverParams._search}` +
                        `${serverParams.custom_params}` +
                        `&objectName=` + ProductAttributes.Brand)
                    .then((response) => {
                        commit('SET_BRAND', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_BRAND', {})
                        reject(error)
                    });
            })
        },
        GET_ONE({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/Main/${id}?objectName=` + ProductAttributes.Brand)
                    .then((response) => {
                        commit('SET_GET_ONE_BRAND', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GET_ONE_BRAND', {})
                        reject(error)
                    });
            })
        },
        CREATE({commit}, form) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.post(`/Main?objectName=` + ProductAttributes.Brand, form)
                    .then((response) => {
                        commit('SET_CREATED_BRAND', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_CREATED_BRAND', {})
                        reject(error)
                    });
            })
        },
        UPDATE({commit}, {id, form}) {
            mixin.removeEmptyAndNull(form)
            return new Promise((resolve, reject) => {
                HTTP.put(`/Main/${id}?objectName=` + ProductAttributes.Brand, form)
                    .then((response) => {
                        commit('SET_UPDATED_BRAND', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_UPDATED_BRAND', {})
                        reject(error)
                    });
            })
        },
        DELETE(commit, ids) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/Main/${ids}?objectName=` + ProductAttributes.Brand)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
    },
    getters : {
        brands: state => state.brands,
        createdBrand: state => state.createdBrand,
        getOneBrand: state => state.getOneBrand,
        updatedBrand: state => state.updatedBrand,
    }
}

export default brands;
