export default {
  // Start Generals
  kurdish: "کوردی",
  arabic: "عربی",
  english: "English",
  language: "Language",
  controls: "Controls",
  dashboard: "Dashboard",
  kurdishName: "Kurdish name",
  arabicName: "Arabic name",
  englishName: "English name",
  isoCode: "ISO code",
  postalCode: "Postal code",
  city: "City",
  noResultsFound: "No Results Found",
  totalRecords: "Total Records",
  save: "Save",
  yes: "Yes",
  no: "No",
  delete: "Delete",
  edit: "Edit",
  someThingWentWrong: "Some Thing Went Wrong",
  settings: "Settings",
  listIsEmpty: "List Is Empty",
  // End Generals

  // Start Custom Components
  isRequired: "Is Required",
  remove: "Remove",
  fileIsTooBig: "File is too big",
  typeNotSupported: "Type not supported",
  maxFileUploadReach: "Max file upload reach",
  pleaseSelect: "Please select",
  mustHaveAtLeast: "Must Have At Least",
  letters: "Letters",
  mustNotHaveGreaterThen: "Must Not Have Greater Then",
  attachments: "Attachments",
  close: "Close",
  search: "Search",
  multipleDelete: "Multiple Delete",
  multipleRestore: "Multiple Restore",
  multipleForceDelete: "Multiple Force Delete",
  export: "Export",
  noRowsSelected: "No rows selected",
  youWantToDeletePermanentlySelectedRows:
    "You Want To Delete Permanently Selected Rows",
  youWantToRestorePermanentlySelectedRows:
    "You Want To Restore Permanently Selected Rows",
  yesDelete: "Yes, Delete",
  yesRestore: "Yes, Restore",
  yourDataHasBeenDeletedPermanently: "Your Data Has Been Deleted Permanently",
  yourDataHasBeenRestoredPermanently: "Your Data Has Been Restored Permanently",
  noCancel: "No Cancel!!",
  dragAndDropToUploadContent: "Drag And Drop To Upload Content",
  orClickToSelectAFileFromYourComputer:
    "...Or Click To Select A File From Your Computer",
  areYouSure: "Are You Sure?",
  sl: "Sl",
  addedSuccessfully: "Added Successfully",
  updatedSuccessfully: "Updated Successfully",
  deletedSuccessfully: "Deleted Successfully",
  // End Custom Components

  // Start Dashboard
  numberOfProducts: "Number Of Products",
  numberOfBrands: "Number Of Brands",
  numberOfCollections: "Number Of Collections",
  numberOfTags: "Number Of Tags",
  latestProducts: "Latest Products",
  latestCollections: "Latest Collections",
  productsInFrontSlider: "Products In Front Slider",
  productsInAllPageTopSlider: "Products In All Page Top Slider",
  // End Dashboard

  // Start Sidebar
  products: "Products",
  list: "List",
  productSizes: "Product Sizes",
  productRamSizes: "Product Ram Sizes",
  productColors: "Product Colors",
  brands: "Brands",
  tags: "Tags",
  collections: "Collections",
  productTypes: "Product Types",
  installmentMethods: "Installment Methods",
  utilities: "Utilities",
  sliders: "Sliders",
  allPageTopSlider: "All Page Top Slider",
  customers: "Customers",
  create: "Create",
  contracts: "Contracts",
  logout: "Logout",
  // End Sidebar

  // Start Contracts
  createContract: "Create Contract",
  customer: "Customer",
  color: "Color",
  diskSize: "Disk Size",
  ramSize: "Ram Size",
  price: "Price",
  extraPrice: "Extra Price",
  discount: "Discount %",
  totalPrice: "Total Price",
  editPriceToProduct: "Edit Price Product",
  editProductPrice: "Edit Product Price",
  installmentMethod: "Installment Method",
  index: "Index",
  payDate: "Pay Date",
  deadline: "Deadline",
  prePayment: "Pre Payment",
  contractNumber: "Contract Number",
  isAllPayed: "Is All Payed",
  customerName: "Customer Name",
  productName: "Product Name",
  colorHex: "Color Hex",
  diskSizeName: "Disk Size Name",
  ramSizeName: "Ram Size Name",
  pleaseCheckPayments: "Please Check Payments!!!",
  showContract: "Show Contract",
  contractsList: "Contracts List",
  contract: "Contract",
  customerPhone: "Customer Phone",
  productColor: "Product Color",
  productDiskSize: "Product Disk Size",
  productRamSize: "Product Ram Size",
  note: "Note",
  print: "Print",
  pay: "Pay",
  // End Contracts

  // Start Customers
  createCustomer: "Create Customer",
  firstName: "First Name",
  MiddleName: "Middle Name",
  LastName: "Last Name",
  dob: "Dob",
  phone: "Phone",
  phone2: "Phone 2",
  address: "Address",
  email: "Email",
  hasAccount: "Has Account",
  editCustomer: "Edit Customer",
  customersList: "Customers List",
  createCustomerAttachment: "Create Customer Attachment",
  sharable: "Sharable",
  share: "Share",
  // End Customers

  // Start All Top Page Slider
  createAllPageTopSlider: "Create All Page Top Slider",
  title: "Title",
  kuTitle: "Kurdish Title",
  arTitle: "Arabic Title",
  product: "Product",
  attachment: "Attachment",
  code: "Code",
  barcode: "Barcode",
  name: "Name",
  kuName: "Kurdish Name",
  arName: "Arabic Name",
  groupProductType: "Group Product Type",
  allPageTopSlidersList: "All Page Top Sliders List",
  allPageTopSliders: "All Page Top Sliders",
  topSlidersList: "Top Sliders List",
  // End All Top Page Slider

  // Start Front Page Slider
  createFrontPageSlider: "Create Front Page Slider",
  frontPageSliders: "Front Page Sliders",
  frontPageSlidersList: "Front Page Sliders List",
  editFrontPageSlider: "Edit Front Page Slider",
  createFrontPageSliderProduct: "Create Front Page Slider Product",
  // End Front Page Slider

  // Start Most Sale
  createMostSale: "Create Most Sale",
  mostSale: "Most Sale",
  mostSales: "Most Sales",
  mostSalesList: "Most Sales List",
  editMostSale: "Edit Most Sale",
  createMostSaleProduct: "Create Most Sale Product",
  // End Most Sale

  // Start New Season
  createNewSeason: "Create New Season",
  newSeason: "New Season",
  newSeasons: "New Seasons",
  newSeasonList: "New Seasons List",
  editNewSeason: "Edit New Season",
  createNewSeasonProduct: "Create New Season Product",
  // End New Season

  // Start Featured Collection
  createFeaturedCollection: "Create Featured Collection",
  featuredCollection: "Featured Collection",
  featuredCollections: "Featured Collections",
  featuredCollectionsList: "Featured Collections List",
  editFeaturedCollection: "Edit Featured Collection",
  createFeaturedCollectionProduct: "Create Featured Collection Product",
  // End Featured Collection

  // Start Take Pay
  createTake3Pay2: "Create Take 3 Pay 2",
  take3Pay2: "Take 3 Pay 2",
  take3Pay2s: "Take 3 Pay 2",
  take3Pay2List: "Take 3 Pay 2 List",
  takePayList: "Take 3 Pay 2 List",
  editTakePay: "Edit Take 3 Pay 2",
  createTake3Pay2Product: "Create Take 3 Pay 2 Product",
  // End Take Pay

  // Start Installment Methods
  createInstallmentMethod: "Create Installment Method",
  prePaymentRate: "Pre Payment Rate",
  paymentCount: "Payment Count",
  paymentPeriodDays: "Payment Period Days",
  editInstallmentMethod: "Edit Installment Method",
  installmentMethodsList: "Installment Methods List",
  // End Installment Methods

  // Start Products
  createBrand: "Create Brand",
  editBrand: "Edit Brand",
  brandList: "Brand List",
  createCollection: "Create Collection",
  sort: "Sort",
  editCollection: "Edit Collection",
  collectionList: "Collection List",
  createProductColor: "Create Product Color",
  editProductColor: "Edit Product Color",
  hexCode: "Hex Code",
  sizeInGB: "Size In GB",
  createProductRamSize: "Create Product Ram Size",
  editProductRamSize: "Edit Product Ram Size",
  productRamSizeList: "Product Ram Size List",
  createProductSize: "Create Product Size",
  editProductSize: "Edit Product Size",
  productSizeList: "Product Size List",
  createTag: "Create Tag",
  editTag: "Edit Tag",
  tagList: "Tag List",
  createProductType: "Create Product Type",
  editProductType: "Edit Product Type",
  productTypeList: "Product Type List",
  productList: "Product List",
  cost: "Cost",
  createPriceToProduct: "Create Price To Product",
  brandName: "Brand Name",
  productTypeName: "Product Type Name",
  description: "Description",
  kuDescription: "Kurdish Description",
  arDescription: "Arabic Description",
  createProduct: "Create Product",
  productType: "Product Type",
  thumbnail: "Thumbnail",
  editProduct: "Edit Product",
  prices: "Prices",
  brand: "Brand",
  prefix: "Prefix",
  suffix: "Suffix",
  branchName: "Branch Name",
  branch: "Branch",
  createProductAttachment: "Create Product Attachment",
  addAttachments: "Add Attachments",
  showAttachments: "Show Attachments",
  colors: "Colors",
  createColorsToProduct: "Create Colors To Product",
  chest: "Chest",
  waist: "Waist",
  hips: "Hips",
  thigh: "Thigh",
  length: "Length",
  createDiskSizeToProduct: "Create Disk Size To Product",
  createRamSizeToProduct: "Create Ram Size To Product",
  features: "Features",
  detail: "Detail",
  createFeatureToProduct: "Create Feature To Product",
  editFeatureToProduct: "Edit Feature To Product",
  createInstallmentMethodsToProduct: "Create Installment Methods To Product",
  available: "Available",
  productTypesListView: "Product Types List View",
  productTypesView: "Product Types View",
  departments: "Departments",
  sections: "Sections",
  categories: "Categories",
  families: "Families",
  update: "Update",
  attachmentChanged: "Attachment Changed",
  pleaseSelectAttachment: "Please Select Attachment",
  // End Products

  // Start Customer Feedbacks
  customerFeedbacks: "Customer Feedbacks",
  customerFeedbackList: "Customer Feedbacks List",
  date: "Date",
  reviewed: "Reviewed",
  accepted: "Accepted",
  shareable: "Shareable",
  kuDetail: "Kurdish Detail",
  arDetail: "Arabic Detail",
  createCustomerFeedback: "Create Customer Feedback",
  editCustomerFeedback: "Edit Customer Feedback",
  kuAttachment: "Kurdish Attachment",
  arAttachment: "Arabic Attachment",
  // End Customer Feedbacks

  // Start Top Slider
  topSlider: "Top Slider",
  topSliderList: "Top Slider List",
  createTopSlider: "Create Top Slider",
  editTopSlider: "Edit Top Slider",
  // End Top Slider

  // Start Settings
  pages: "Pages",
  iraqFacebookPage: "Iraq Facebook Page",
  arabFacebookPage: "Arab Facebook Page",
  euroFacebookPage: "Euro Facebook Page",
  updateSettings: "Update Settings",
  settingsUpdated: "Settings Updated",
  // End Settings

  // Start Currency
  currency: "Currency",
  currencyList: "Currency List",
  createCurrency: "Create Currency",
  editCurrency: "Edit Currency",
  // End Currency

  // Start Ads
  ads: "Ads",
  adsList: "Ads List",
  createAds: "Create Ads",
  editAds: "Edit Ads",
  useSeparateImage: "Use Separate Image",
  useSeparateAttachment: "Use Separate Attachment",
  // End Ads

  // Start Made In
  madeIn: "Made In",
  madeInList: "Made In List",
  createMadeIn: "Create Made In",
  editMadeIn: "Edit Made In",
  url: "URL",
  // End Made In

  // Start Suppliers
  suppliers: "Suppliers",
  suppliersList: "Suppliers List",
  createSupplier: "Create Supplier",
  editSupplier: "Edit Supplier",
  country: "Country",
  currentLoan: "Current Loan",
  createSupplierAttachment: "Create Supplier Attachment",
  supplier: "Supplier",
  // End Suppliers

  // Start Product Screen Sizes
  productScreenSizes: "Product Screen Sizes",
  screenSize: "Screen Size",
  createScreenSizeToProduct: "Create Screen Size To Product",
  feature1: "Feature 1",
  feature2: "Feature 2",
  feature3: "Feature 3",
  feature4: "Feature 4",
  productScreenSizeList: "Product Screen Sizes List",
  productScreenSizesList: "Product Screen Sizes List",
  createProductScreenSize: "Create Product Screen Size",
  editProductScreenSize: "Edit Product Screen Size",
  size: "Size",
  installments: "Installments",
  // End Product Screen Sizes

  // Start Multiple Create Product
  multipleCreateProduct: "Multiple Create Product",
  increasePercentage: "Increase Percentage",
  contains: "Contains",
  shippingPrice: "Shipping Price",
  shippingTime: "Shipping Time",
  width: "Width",
  height: "Height",
  cbm: "CBM",
  minOrder: "Min Order",
  season: "Season",
  spring: "Spring",
  summer: "Summer",
  autumn: "Autumn",
  winter: "Winter",
  weight: "Weight",
  diskSizes: "Disk Sizes",
  ramSizes: "Ram Sizes",
  discountPer: "Discount Percentage",
  numberOfTypes: "Number Of Types",
  pleaseEnterAtLeastOneType: "Please Enter At Least One Type",
  generate: "Generate",
  csvFile: "CSV File",
  batch: "Batch",
  createProductTypeBatch: "Create Product Type Batch",
  removeDuplicates: "Remove Duplicates",
  unavailable: "Unavailable",
  temporaryUnAvailable: "Temporary UnAvailable",
  iraqStock: "Iraq Stock",
  chinaStock: "China Stock",
  iranStock: "Iran Stock",
  turkeyStock: "Turkey Stock",
  uaeStock: "UAE Stock",
  europeStock: "Europe Stock",
  required: "Required",
  departmentRequired: "Department Required",
  sectionRequired: "Section Required",
  categoryRequired: "Category Required",
  optionalComma: "Optional Comma",
  rearrange: "Rearrange",
  dragAndDrop: "Drag And Drop",
  salesManager: "Sales Manager",
  payType: "Pay Type",
  innerShippingTime: "Inner Shipping Time",
  profit: "Profit",
  roofLoan: "Roof Loan",
  cash: "Cash",
  installment: "Installment",
  ratio: "Ratio",
  types: "Types",
  supplierBatch: "Supplier Batch",
  supplierId: "Supplier",
  sellPrice: "Sell Price",
  isAvailable: "Is Available",
  methods: "Methods",
  sudden: "Sudden",
  periodic: "Periodic",
  continuous: "Continuous",
  invoiceTypes: "Invoice Types",
  // End Multiple Create Product
  discountList: "Discount List"
};
